import React, { useState } from "react";
import { Grid, Avatar, Typography, Popover } from "@material-ui/core";
import {
  commentIcon,
  dot,
  down,
  downreIcon,
  likedUpvote,
  minus,
  more,
  plus,
  saveBlue,
  up,
} from "../assets";
import { makeStyles } from "@material-ui/core/styles";
import PostCommentTextField from "./PostCommentTextField.web";
import { Post, State, UserDetails } from "../CommentsController";
import { Comment } from "../CommentsController";
import SliderComponent from "./SliderComponent.web";

// Define styles
const useStyles = makeStyles((theme) => ({
  subNestedContainer: {
    "&:before": {
      content: "''",
      borderLeft: "1px solid #D9D9D9",
      position: "absolute",
      left: "52px",
      top: "0",
      bottom: "3px",
    },
  },
  nestedCommentContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "52px",
    marginTop: "15px",
    width: "100%",
    flexDirection: "column",
    "&:last-child": {
      background: "#ffffff",
      marginBottom: "-15px"
    },
    "&:after": {
      left: "0",
      content: "''",
      position: "absolute",
      width: "43px",
      height: "1px",
      background: "#D9D9D9",
    },
  },
  commentContainer: {
    width: "100%",
    marginBottom: "20px",
    marginTop: "10px",
    position: "relative",
    "&.subNestedContainer": {
      "&:before": {
        content: "''",
        borderLeft: "1px solid #D9D9D9",
        position: "absolute",
        left: "0",
        top: "0",
        bottom: "3px",
      },
    }
  },
  commentItem: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    borderLeft: ({ comment }: { comment: any }) =>
      comment?.replies?.length > 0 ? "1px solid #D9D9D9" : "0px",
    paddingBottom: "0px",
    top: "-12px",
    width: "100%",
  },
  userInfoContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  username: {
    paddingLeft: "26px",
    position: "relative",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1F497D",
  },
  commentTimeContainer: {
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "lighter",
    color: "#1F497D",
  },
  commentContentContainer: {
    paddingLeft: "38px",
    paddingTop: "3px",
    width: "100%",
  },
  commentText: {
    color: "#17365D",
    fontSize: "14px",
    fontFamily: "Arial",
    width: "100%",
    paddingRight: "37px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-all"
  },
  footerText: {
    color: "#17365D",
    fontSize: "14px",
    fontFamily: "Arial",
  },
  loadMore: {
    color: "#1F497D",
    fontSize: "14px",
    fontFamily: "Arial",
    display: "flex",
    alignItems: "center",
  },
  itemText: {
    cursor: "pointer",
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: "lighter",
    color: "#1F497D",
    padding: "9px 16px",
    margin: "3px",
    "&:hover": {
      background: "#6D97C133",
      borderRadius: "8px",
    }
  },
  deleteItemText: {
    cursor: "pointer",
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#DC2626",
    padding: "9px 16px",
    margin: "3px",
    "&:hover": {
      background: "#6D97C133",
      borderRadius: "8px",
    }
  },
  rootPopover: {
    "& .MuiPopover-paper": {
      border: "2px solid #CBD5E1",
      boxShadow: "none",
      marginTop: "8px",
      borderRadius: "8px"
    }
  },
  blueDot: {
    height: "20px",
    borderRadius: "100px",
    padding: "0px",
    width: "20px",
    background: "#1F497D"
  },
  darkBlueDot: {
    height: "20px",
    borderRadius: "100px",
    padding: "0px",
    width: "20px",
    background: "#6D97C1"
  },
  gridStyles1: {
    borderLeft: ({ comment }: { comment: any }) =>
      comment?.replies?.length >0? " ": '0px',
  }
}));

const useStyles1 = makeStyles((theme) => ({
  diStyles1: {
    top: ({ isNested }: { isNested: any }) =>
      isNested ? "-1px" : "-6px",
    left: ({ isNested }: { isNested: any }) =>
    isNested ? "-9px" : "-17px"
  }
}));

type Props = {
  comment: Comment;
  isNested?: boolean;
  lastChild: boolean;
  parentlength: number;
  index: number;
  createComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string) => void;
  state: State;
  post: Post;
  downvotePostComment: (id: number | string, type: string, postId: number | string) => void;
  upvotePostComment: (id: number | string, type: string, postId: number | string) => void;
  goToLoginPage: () => void;
  userDetails: UserDetails;
  handleDeleteComment: (id: string | number) => void;
  updateComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string) => void;
  loader: boolean;
  progress: number;
  remainingTime: number;
};

const CommentComponent = ({
  comment,
  isNested = false,
  lastChild,
  parentlength,
  index,
  createComment,
  post,
  state,
  downvotePostComment,
  upvotePostComment,
  goToLoginPage,
  userDetails,
  handleDeleteComment,
  updateComment,
  loader,
  progress,
  remainingTime
}: Props) => {
  const classes = useStyles({ comment });
  const classes1 = useStyles1({ isNested});
  const [showNestedComments, setShowNestedComments] = useState<boolean>(false);
  const [showtextField, setShowTextField] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [expandedComment, setExpandedComment] = useState<boolean>(false);

  const toggleNestedComments = () => {
    setShowNestedComments(!showNestedComments);
  };
  const openNestedCommetns = () => {
    setShowNestedComments(true);
  };
  let commentsLength = comment?.replies?.length;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditMode = (value: boolean) => setEditMode(value);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const truncateText = (comment: string, maxLength: number) => {
    if (comment.length <= maxLength) return comment;
    return comment.slice(0, maxLength);
  }

  const renderComment = () => {
    return expandedComment ? comment.comment : truncateText(comment.comment, 250)
  }

  const toggleReadMore = () => {
    setExpandedComment(!expandedComment)
  }

  return (
    <Grid
      item
      className={getClassName(isNested, commentsLength, classes.nestedCommentContainer, classes.subNestedContainer, classes.commentContainer)}>
      <Grid
        className={`${getClassName0(isNested,lastChild,showNestedComments,commentsLength)} ${classes.gridStyles1}`}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "0px",
          top: "-12px",
          width: "100%",
        }}
      >
        <div
          style={{ position: "absolute"}}
          className={`${getClassName1(isNested)} ${classes1.diStyles1}`}
        >
          {
            !isNested ? <Avatar
              style={{ height: "32px", width: "32px", border: "2px solid #1F497D" }}
              src={comment.account.account_profile}
            >
            </Avatar> : <div className={getClassName2(index, classes.blueDot, classes.darkBlueDot)}></div>
          }
        </div>
        <div
          className={ getClassName3(showNestedComments,isNested,lastChild,index)}
          style={{
            cursor: "pointer",
            position: "absolute",
            bottom: "-5px",
            left: "-6px",
          }}
        >
          {commentsLength > 0 && showNestedComments && (
            <img
              src={minus}
              onClick={() => toggleNestedComments()}
              id="minus-btn"
              style={{ background: "#ffffff" }}
            />
          )}
        </div>
        {commentsLength > 0 && !showNestedComments && (
          <div
            style={{
              position: "absolute",
              bottom: "-18px",
              left: "67px",
              cursor: "pointer",
            }}
            className="fhryvjierwe45"
          >
            {
              <Typography className={classes.loadMore} onClick={() => toggleNestedComments()}>
                <img
                  src={plus}
                  id="plus-btn"
                />
                &nbsp; {commentsLength} more replies
              </Typography>
            }
          </div>
        )}
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container item className={classes.userInfoContainer}>
            <Grid item style={{ position: "relative" }}>
              <span className={classes.username} style={{ paddingLeft: !isNested ? "34px" : "26px" }}>
                {comment.account.account_name}
              </span>
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <img src={dot} />
              &nbsp;
              <span className={classes.commentTimeContainer}>
                {comment.created_at}
              </span>
              &nbsp; &nbsp;
              {
                (userDetails.attributes.account_details.id === comment.account_id) && <span onClick={handleClick} style={{ cursor: "pointer", padding: "5px" }}>
                  <img src={more} />
                </span>
              }
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                className={classes.rootPopover}
              >
                <Typography data-test-id='openEditMode' className={classes.itemText} onClick={() => {
                  setEditMode(!editMode)
                  handleClose()
                }}>Edit</Typography>
                <Typography className={classes.deleteItemText} onClick={() => {
                  handleDeleteComment(comment.id)
                  handleClose()
                }}>Delete</Typography>
              </Popover>
            </Grid>
          </Grid>
          <Grid container item className={classes.commentContentContainer}>
            <Typography className={classes.commentText}>
              {!editMode ? renderComment() : <PostCommentTextField setShowTextField={setShowTextField} userDetails={userDetails} loader={loader} progress={progress} remainingTime={remainingTime} handleEditMode={handleEditMode} updateComment={updateComment} goToLoginPage={goToLoginPage} createComment={createComment} state={state} post={post} cmt={comment} isNested={true} openNestedCommetns={openNestedCommetns} isEditMode={true} />}
              <span data-test-id="moreText" className="moreText" style={{cursor: "pointer", color: "rgba(0,0,0,.6)"}} onClick={() => toggleReadMore()}>{!expandedComment && comment.comment.length > 250 && `...more` }</span>
            </Typography>
          </Grid>
          {comment.comment_images.length > 0 && !editMode && <Grid container item className={classes.commentContentContainer}>
            {
              <div style={{ height: "90px", padding: "10px",  }}>
                <SliderComponent items={comment.comment_images} needBorderRadius={true} />
              </div>
            }
          </Grid>}
          <Grid item container style={{ padding: "2px 37px" }}>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              id="upvote"
              onClick={() => upvotePostComment(comment.id, "BxBlockComments::Comment", post.id)}
            >
              <img src={comment.isliked ? likedUpvote : up} style={{height: "16px"}} />
              &nbsp;
              <Typography className={classes.footerText}>
                {comment.likes_count}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "5px",
                cursor: "pointer",
              }}
              id="downvote"
              onClick={() => downvotePostComment(comment.id, "BxBlockComments::Comment", post.id)}
            >
              <img src={comment.isdisliked ? downreIcon : down} style={{height: "16px"}} />
              &nbsp;
              <Typography className={classes.footerText}>{comment.dislikes_count}</Typography>
            </Grid>
            <Grid
              id="replyIcon"
              item
              style={{
                paddingLeft: "33px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setShowTextField(!showtextField)}
              >
              <img src={commentIcon} />
              &nbsp;
              <Typography
                className={classes.footerText}
              >
                Reply
              </Typography>
            </Grid>
          </Grid>
          {showtextField && (
            <Grid item container style={{ padding: "2px 37px" }}>
              <PostCommentTextField setShowTextField={setShowTextField} userDetails={userDetails}  loader={loader} progress={progress} remainingTime={remainingTime} updateComment={updateComment} goToLoginPage={goToLoginPage} createComment={createComment} state={state} post={post} cmt={comment} isNested={true} openNestedCommetns={openNestedCommetns} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <div style={{ position: "relative" }}>
        {showNestedComments &&
          commentsLength > 0 &&
          comment.replies.map((nestedComment: any, index: number) => (
            <CommentComponent
              handleDeleteComment={handleDeleteComment}
              index={index}
              comment={nestedComment}
              isNested={true}
              lastChild={comment.replies.length - 1 === index}
              parentlength={comment.replies.length}
              state={state}
              post={post}
              createComment={createComment}
              upvotePostComment={upvotePostComment}
              downvotePostComment={downvotePostComment}
              goToLoginPage={goToLoginPage}
              userDetails={userDetails}
              updateComment={updateComment} loader={false} progress={0} remainingTime={0}/>
          ))}
      </div>
    </Grid>
  );
};

const getClassName = (isNested: boolean, commentsLength: number, nestedCommentContainer: string, subNestedContainer: string, commentContainer: string) => {
  if (isNested) {
    return commentsLength > 0 ? `${nestedCommentContainer} ${subNestedContainer}` : nestedCommentContainer;
  }
  return commentsLength > 0 ? `${commentContainer} subNestedContainer` : commentContainer;
};

const getClassName0 = (isNested:boolean,lastChild:boolean,showNestedComments:boolean,commentsLength:number) => {
  if (isNested && !lastChild && !showNestedComments && commentsLength > 0) {
    return "moredetails";
  }
  return "";
};

const getClassName1 = (isNested: boolean) => {
  return isNested ? "hryvi0fhg " : "";
};

const getClassName2 = (index: number, blueDot: string, darkBlueDot: string) => {
  return ((index % 2) === 0) ? blueDot : darkBlueDot;
};

const getClassName3 = (showNestedComments: boolean, isNested: boolean, lastChild: boolean, index: number) => {
  if (isNested) {
    if (!showNestedComments && !lastChild) {
      return "hfyrufnv14";
    } else if (index === 1 && showNestedComments) {
      return "hfyrufnv145";
    }
  }
  return "";  
};

export default CommentComponent;
