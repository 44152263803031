import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { Input, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "8px",
            border: "1px solid #9C9C9C",
            background: "#FFF",
            color: "#0F172A",
            fontFamily: "Arial",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "lighter",
            lineHeight: "22px",

            '&:hover': {
                border: "1px solid #9C9C9C",
            },
            '&:focus': {
                border: "1px solid #9C9C9C",
            },
            width: "100%",
            "& .MuiSelect-select": {
                "width": "100%",
                "height": "50.6333px",
                "borderRadius": "8px",
                "padding": "0px 12px",
                "background": "white",
                "border": "0px",
                "display": "flex",
                "justifyContent": "flex-start",
                "alignItems": "center",
            },
            "& .MuiInput-input": {
                "border": "0px"
            },
            "& .MuiInput-input:focus": {
                "border": "0px solid #9C9C9C"
            },
            "& .MuiInput-root": {
                border: "1px solid #9C9C9C",
            }
        },
        rootError: {
            borderRadius: "8px",
            border: "1px solid #DC2626",
            background: "#FFF",
            color: "#6D97C1",
            fontFamily: "Arial",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "lighter",
            lineHeight: "22px",

            '&:hover': {
                border: "1px solid #9C9C9C",
            },
            '&:focus': {
                border: "1px solid #9C9C9C",
            },
            width: "100%",
            "& .MuiSelect-select": {
                "width": "100%",
                "height": "50.6333px",
                "borderRadius": "8px",
                "padding": "0px 12px",
                "background": "white",
                "border": "0px",
                "display": "flex",
                "justifyContent": "flex-start",
                "alignItems": "center",
            },
            "& .MuiInput-input": {
                "border": "0px"
            },
            "& .MuiInput-input:focus": {
                "border": "0px solid #9C9C9C"
            },
            "& .MuiInput-root": {
                border: "1px solid #9C9C9C",
            }
        },
        inputLabel: {
            color: '#1F497D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        selectEmpty: {
            border: "1px solid #EEECE1",
            borderRadius: "8px",
            margin: "0px !important",
            padding: "2px 5px",
            background: "white",
            "& .MuiInput-input": {
                background: "white",
            },
            "& .MuiInput-input:focus": {
                backgroundColor: "white",
            },
            "& .MuiInput-underline": {
                display: "none"
            },
            "& .MuiInput-formControl": {
                marginTop: "2px"
            }
        },
        radioTitle: {},
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginLeft": "0px",
        },
        selectMenu: {
            borderRadius: "8px",
            background: "red"
        },
        menuItem: {
            "display": "flex",
            "height": "36px",
            "padding": "9px 20px 9px 16px",
            "alignItems": "center",
            "alignSelf": "stretch",
            "background": "white",
            "color": "#17365D",
            "fontFamily": "Arial !important",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "& :hover": {
                "background": "white",
            }
        },
        menuLabel: {
            fontSize: "14px"
        },
        menuDropdown: {
            "borderRadius": "16px",
            "border": "1px solid  #CBD5E1",
            "background": "#FFF",
            "boxShadow": "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
            "marginTop": "5px"
        },
        placeholder: {
            "color": "#64748B;",
            "fontFamily": "Arial",
            "fontSize": "16px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
        },
        "& .MuiMenu-paper": {
            height: "220px !important"

        }
    })
);

const CustomDropdownIcon = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{paddingRight: "5px"}}>
        <path d="M8.12499 8.99953L12.005 12.8795L15.885 8.99953C16.275 8.60953 16.905 8.60953 17.295 8.99953C17.685 9.38953 17.685 10.0195 17.295 10.4095L12.705 14.9995C12.315 15.3895 11.685 15.3895 11.295 14.9995L6.70499 10.4095C6.51774 10.2227 6.41251 9.96905 6.41251 9.70453C6.41251 9.44001 6.51774 9.18636 6.70499 8.99953C7.09499 8.61953 7.73499 8.60953 8.12499 8.99953Z" fill="#1F497D" />
    </svg>;
};

type Props = {
    placeHolder: string;
    title: string;
    disabled?: boolean,
    value: string,
    selectTopics: {
        [category: string]: {
            icon: string;
            topics: string[];
        };
    },
    onChange: ((event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => void)
};

const SelectTopicComponent: React.FC<Props> = ({
    placeHolder,
    title,
    disabled,
    value,
    selectTopics,
    onChange
}) => {
    const classes = useStyles();

    return (
        <div>
            <style>
                {
                    `
                    .MuiMenu-paper {
                        height: 200px !important;
                      }
                    .MuiSelect-icon {
                        padding-right: 5px !important;
                      }
                    `
                }
            </style>
            <InputLabel
                className={classes.inputLabel}
            >
                {title}
            </InputLabel>
            <FormControl error style={{ width: "100%" }} fullWidth>
                <Select
                    disabled={disabled}
                    displayEmpty
                    input={<Input />}
                    value={value}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            className: classes.menuDropdown
                        }
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    className={classes.root}
                    IconComponent={CustomDropdownIcon}
                    disableUnderline
                    onChange={onChange}
                >
                    <MenuItem value=""
                        style={{ display: 'none', fontSize: '16px', fontFamily: 'Arial', color: "#64748B", fontWeight: '400' }}>
                        Select
                    </MenuItem>
                    {Object.values(selectTopics).reduce<string[]>((acc, section) => { return acc.concat(section.topics) }, []).map((name) => (

                        <MenuItem className={classes.menuItem} key={name} value={name} style={{ background: "white" }}>
                            <Typography className={classes.menuLabel}>{name}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default SelectTopicComponent;
