
import React, { CSSProperties } from "react";
// Customizable Area Start
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Avatar, IconButton, Button, Typography, styled, TextField, Box, ClickAwayListener, InputAdornment, MenuItem, FormControl, OutlinedInput, withStyles, CircularProgress } from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { City, ICountry, ICity, Country } from 'country-state-city'
import Uploadmedia3 from '../../uploadmedia3/src/Uploadmedia3.web';
import { cross } from "./assets";
import OTPInput from "react-otp-input";
import OtpTimer from "../../../../packages/components/src/OtpTimer";
import { handleCondition } from "../../../../packages/components/src/Utils.web";
// Customizable Area End

import UserProfileEditController, {
    Props,
    configJSON,
} from "./UserProfileEditController.web";
import CustomTextField from "./components/CustomTextField";
import AutoCompleteInputText from "./components/AutoCompleteInputText";
import { arrowDown, bottomLeft, imageImg, topRight, userProfileImg } from "./assets";
import CurrencyField from "./components/CurrencyField";
import { Autocomplete } from "@material-ui/lab";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import CustomSelectField from "./components/CustomSelectField";

export class UserProfileEdit extends UserProfileEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const validationSchema = Yup.object().shape({
            full_name: Yup.string().required('Full name is a required field').max(30, "Full name must not be more than 30 characters"),
            country_field: Yup.string().required('Country is a required field'),
            city_field: Yup.string().required('City is a required field'),
            current_title: Yup.string().required('Current title is a required field').matches(/^[a-zA-Z0-9\s]+$/, 'Current title must not contain special characters'),
            full_phone_number: Yup.string(),
            email: Yup.string().email('Please enter correct mail id')
        })
        return (
            // Customizable Area Start
            <>
            <style>
                {
                    `
                    .MuiFormControl-fullWidth > .MuiInput-root {
                        border: 1px solid #CACACA
                    }
                    .MuiFormControl-fullWidth > .MuiInput-root > .MuiSelect-select{
                        height: 42px !important
                    }
                    
                    .MuiIconButton-colorPrimary:hover {
                        background-color: transparent !important;
                    }
                    `
                }
            </style>
                <Grid container style={webStyle.page}>
                    <Formik
                        // id="formik"
                        initialValues={this.state.initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={(values) => {
                            this.handleSubmitForm(values)
                        }}
                    >
                        {({ values, errors, setFieldValue, setFieldError, touched, handleBlur, setTouched, handleChange }) => (
                            <Form style={{ width: "inherit" }}>
                                <Grid style={webStyle.form as CSSProperties}>
                                    <img src={topRight} alt="icons" style={webStyle.topRight as CSSProperties} />
                                    <img src={bottomLeft} alt="icons" style={webStyle.bottomLeft as CSSProperties} />
                                    <Grid container style={{ paddingLeft: "12px", paddingBottom: "20px" }}>
                                        <Grid item sm={12}>
                                            <Typography style={webStyle.cardTitle}>
                                                {this.handleConditionRender(this.state.isFirstTimeLogin, "Update/Edit Profile", "Complete Profile")}
                                            </Typography>
                                        </Grid>
                                        <Grid item container sm={12}>
                                            <Grid item sm={12}>
                                                <IconButton
                                                    id='iconButton'
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    style={{ paddingBottom: "0px" }}
                                                >
                                                    <input
                                                        type="file"
                                                        hidden
                                                        name="photo"
                                                        accept="image/*"
                                                        id="group_image"
                                                        onClick={this.handleProfileState}
                                                        onChange={(event) => {
                                                            this.handleProfileImageChange(event, setFieldValue, setFieldError, setTouched, touched)}
                                                        }
                                                    />
                                                    {values.photo instanceof File ?
                                                        <Avatar
                                                            style={{ width: "90px", height: "90px", }}
                                                            src={URL.createObjectURL(values.photo) || userProfileImg}
                                                        ></Avatar>
                                                        :
                                                        <Avatar
                                                            style={{ width: "90px", height: "90px", }}
                                                            src={values.photo || userProfileImg}
                                                        ></Avatar>
                                                    }
                                                    {this.state.loader ?
                                                        <div style={{ position: 'absolute', top: '10px', left: '135px', bottom: '0px', right: '0px' }}>
                                                            <Uploadmedia3 progress={this.state.progress} time={this.state.remainingTime} {...this.props} />
                                                        </div> : <></>
                                                    }
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <Typography style={webStyle.uploadTxt}><img src={imageImg} alt="icon" />Upload/Edit Image &nbsp;</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={12}>
                                            {
                                                errors.photo && touched.photo && <Typography style={webStyle.errorText}>
                                                    {errors.photo}
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container style={webStyle.formfieldCont as CSSProperties} spacing={3} >
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Field
                                                name="full_name"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Full Name"
                                                placeHolder="Mark Antony"
                                                rootStyle={{ height: "10px !important" }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={Country.getAllCountries().map((country: ICountry) => ({
                                                    value: country.name,
                                                    label: country.name,
                                                    cId: country.isoCode
                                                }))}
                                                value={{ value: values.country_field, label: values.country_field, cId: "0" }}
                                                getOptionLabel={(option) => option.label || ''}
                                                onChange={(event, value) => {
                                                    setFieldValue("country_field", (event.target as HTMLElement).textContent, true);
                                                    if (value?.cId) {
                                                        let cities = ((City.getCitiesOfCountry(value.cId) as []).map((city: ICity) => ({
                                                            value: city.name,
                                                            label: city.name,
                                                        })) as { value: string, label: string }[]);
                                                        this.handleCountryChange(cities);
                                                        setTimeout(() => {
                                                            if (!(cities.find((city) => city.value === values.city_field))) {
                                                                setFieldValue("city_field", "")
                                                            }
                                                        })
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                renderInput={(params) => <AutoCompleteInputText errors={errors}
                                                    params={params}
                                                    touched={touched.country_field}
                                                    title="Country" name="country_field" placeHolder="Country"
                                                />}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.state.cities}
                                                getOptionLabel={(option) => option.label}
                                                value={{ value: values.city_field, label: values.city_field }}
                                                onChange={(event) => {
                                                    setFieldValue("city_field", (event.target as HTMLElement).textContent);
                                                }}
                                                onBlur={handleBlur}
                                                renderInput={(params) => <AutoCompleteInputText
                                                    errors={errors}
                                                    title="City"
                                                    name="city_field"
                                                    placeHolder="City"
                                                    params={params}
                                                    touched={touched.city_field}
                                                />
                                                }
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Typography
                                                style={{
                                                    color: '#1F497D',
                                                    fontFamily: 'Arial',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 'bold',
                                                    lineHeight: '22px',
                                                    paddingBottom: '4px',
                                                }} >Contact Number</Typography>
                                            <PhoneInputStyled
                                                containerStyle={{
                                                    height: "44px"
                                                }}
                                                inputStyle={{
                                                    height: "44px",
                                                    width: "100%",
                                                    color: "#6D97C1",
                                                    borderRadius: "8px"
                                                }}
                                                dropdownStyle={{
                                                    borderTopLeftRadius: "8px",
                                                    borderBottomLeftRadius: "8px",
                                                    background: "white"
                                                }}
                                                autoFormat
                                                enableSearch
                                                specialLabel="Contact Number"
                                                searchNotFound="Country Not Found"
                                                placeholder={"Enter your contact number here"}
                                                data-test-id="PhoneInputStyled"
                                                value={values.full_phone_number}
                                                onChange={(value, countryData) => {
                                                    setFieldValue("full_phone_number", value);
                                                    setTouched({...touched, "full_phone_number": true})
                                                    if (!this.isPhoneValid({ phone: value, country: countryData })) {
                                                        setTimeout(() => {
                                                            setFieldError("full_phone_number", "Invalid mobile number");
                                                        });
                                                    }
                                                }}
                                            />
                                            {errors.full_phone_number && touched.full_phone_number && <p style={{...webStyle.errorText, marginTop: '6px'}}>{errors.full_phone_number}</p>}
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Field
                                                name="experience"
                                                component={CustomSelectField}
                                                type="string"
                                                variant="outlined"
                                                title="Experience"
                                                placeHolder="0-2 years"
                                                options={this.state.experienceArr}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Field
                                                name="current_title"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Current Title"
                                                placeHolder="Investment Professor"
                                                rootStyle={{ height: "10px !important" }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Field
                                                name="future_self"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Future Self"
                                                placeHolder="I see myself as Investment and Finance Project Manager"
                                                rootStyle={{ height: "10px !important" }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Typography style={webStyle.emailTitle}>Email</Typography>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                style={{ background: '#FFF', borderRadius: "8px", border: "0px solid  #CBD5E1" }}>
                                                <OutlinedInputCustomOne
                                                    placeholder="abc@email.com"
                                                    type="email"
                                                    id="email-outlined"
                                                    name="email"
                                                    error={(this.state.initialEmail !== values.email) || !!errors.email}
                                                    value={values.email}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        style: {
                                                            fontSize: '14px',
                                                            paddingRight: "4px"
                                                        }
                                                    }}
                                                    endAdornment={<InputAdornment position="end">
                                                        {
                                                            handleCondition(this.state.initialEmail === values.email,
                                                                <Grid
                                                                    style={webStyle.verifiedString}
                                                                >
                                                                    Verified
                                                                </Grid>,
                                                                <Grid
                                                                    style={{
                                                                        ...webStyle.verifyButton,
                                                                    }}
                                                                    data-test-id={"btnEmailVerificationWork"}
                                                                    onClick={() => this.checkForEmailValueOne(values.email, errors.email)}
                                                                >
                                                                    Verify
                                                                </Grid>)
                                                        }
                                                    </InputAdornment>}
                                                />
                                            </FormControl>
                                            {this.handleConditionRender(errors.email, <p style={{ ...webStyle.errorText, marginTop: '6px' }}>{errors.email}</p>, "")}
                                            {(this.state.initialEmail !== values.email) && !errors.email && <p style={{ ...webStyle.errorText, marginTop: '6px' }}>Email id is not verified</p>}
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Field
                                                name="company_indusry"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Current Industry"
                                                placeHolder="Investment & Finance"
                                                rootStyle={{ height: "10px !important" }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Field
                                                data-test-id="salary"
                                                name="salary"
                                                component={CurrencyField}
                                                type="string"
                                                variant="outlined"
                                                title="Salary"
                                                placeHolder="25,00,000"
                                                rootStyle={{ height: "10px !important" }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Field
                                                name="bio"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Bio"
                                                placeHolder="Bio"
                                                rootStyle={{ height: "10px !important" }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                        <Typography style={{
                                                color: '#1F497D',
                                                fontFamily: 'Arial',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                padding: "2px"
                                            }}>
                                                Current Company
                                            </Typography>
                                        <InputField
                                                style={webStyle.inputText}
                                                name="current_company"
                                                id="company_name"
                                                placeholder="Select Company"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                data-test-id="companyName"
                                                onChange={(e) => {
                                                    setFieldValue('current_company', e.target.value)
                                                    this.setState({ selectedCompanyName: e.target.value })
                                                    e.target.value && this.handleInputChangeDebounced(e.target.value)
                                                }}
                                                value={this.handleConditionRender(this.state.selectedCompanyName, this.state.selectedCompanyName,"")}
                                                fullWidth
                                            />
                                            {this.handleConditionRender(this.state.open, <Grid style={{
                                                    marginTop: "24px", borderRadius: "8px", position: "absolute",
                                                    border: "1px solid #CBD5E1", background: "#fff", zIndex: 100,
                                                    width: "40%", padding: "14px 20px 9px 16px", display: "flex", flexDirection: "column",
                                                    gap: "20px", alignItems: "flex-start"
                                                }}>
                                                    {this.state.companyList?.map((item) => (
                                                        <Box
                                                            style={{
                                                                color: "#17365D", fontSize: "14px", fontWeight: 400,
                                                                fontFamily: "Arial", cursor: "pointer"
                                                            }}
                                                            data-test-id="handleSelectCompany"
                                                            onClick={() => this.handleSelectCompany(item.value)}
                                                        >{item.value}</Box>
                                                    ))}
                                                </Grid>, "")}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={webStyle.btncontainerMain}>
                                        <Grid item sm={12} style={webStyle.btnContainer}>
                                            <Button data-test-id="formCancelBtn" style={webStyle.noBtn as CSSProperties} disabled={!this.state.setProfile} onClick={this.handleCancel}>Cancel</Button>
                                            <Button style={webStyle.yesBtn as CSSProperties} type="submit">Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                {this.state.showVerifiyEmail && (
                    <Box style={{
                        position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(15, 23, 42, 0.4)",
                        zIndex: 101, display: "flex", alignItems: "center", justifyContent: "center",
                    }}>
                        <Box style={{ minWidth: "500px", background: "#fff", border: "0px 0px 1px  0px", position: "absolute", borderTop: "1px solid #E2E8F0", borderRadius: "8px" }}>
                            <Box style={{ padding: "24px 40px 20px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography style={{ fontFamily: "Arial", fontWeight: 700, fontSize: "18px", color: "#17365D" }}
                                >Check your email </Typography>
                                <img src={cross} style={{ cursor: "pointer" }} onClick={this.handleCloseClickOne} />
                            </Box>
                            <Box style={{ padding: "2px 40px 40px 40px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", position: "relative", gap: "4px" }}>
                                <Typography style={{ color: "#1F497D", fontSize: "18px", fontWeight: 700, fontFamily: "Arial" }}>
                                    We have {this.handleConditionRender(this.state.resend, 'resend', 'sent')} an OTP to {this.state.emailToDisplay}</Typography>
                                {this.handleConditionRender(this.state.otpError,
                                    <Box sx={webStyle.invalidLink}>
                                        Invalid OTP entered
                                    </Box>,
                                    "")}
                                <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingBottom: "20px" }}>
                                    <OTPInput
                                        value={this.state.otpValue}
                                        data-test-id={"handleChangeOTP"}
                                        onChange={(event) => this.handleChangeOTPOne(event)}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        inputStyle={{
                                            color: "#000",
                                            height: "44px",
                                            width: "44px",
                                            fontSize: '0.85rem',
                                            border: `1px solid ${this.handleConditionRender(this.state.otpError, '#DC2626', '#9C9C9C')}`,
                                            borderRadius: "5px",
                                            gap: "8px"
                                        }}
                                        containerStyle={webStyle.otpContainerStyle}
                                        renderInput={(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>) => <Grid item xs={1}><input {...props} /></Grid>}
                                    />
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <OtpTimer
                                        handleOTPTimer={(val: boolean) => this.handleOTPTimerCallOne(val)}
                                        handleShowVerifyEmail={() => this.sendOtpCallOne(this.state.emailToDisplay, true)}
                                    />
                                </Box>
                            </Box>
                            <Box style={{
                                padding: "24px 40px", borderTop: "1px solid #F4F4F4",
                                display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "16px"
                            }}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item md={4}>
                                        <Button
                                            id={"btnVerifyOTP"}
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                textTransform: 'capitalize',
                                                fontSize: "16px",
                                                marginTop: '10px',
                                                fontFamily: "Arial",
                                                fontWeight: "bold",
                                                background: `${this.handleConditionRender(this.state.otpValue.length < 6, "#CBD5E1", "#6D97C1")}`,
                                            }}
                                            onClick={this.verifyOtpCallOne}
                                            fullWidth
                                            disabled={this.state.otpValue.length < 6}
                                        >
                                            Verify {this.state.verifyLoader && <CircularProgress style={{ color: 'white', marginLeft: '2px' }} size="1rem" />}
                                        </Button>
                                    </Grid>
                                </Grid> </Box> </Box> </Box>
                )}  
                <ToastContainer />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Star
export default UserProfileEdit

const OutlinedInputCustomOne = withStyles({
    root: {
      borderRadius: "8px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9C9C9C !important",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9C9C9C !important",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#9C9C9C !important",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6D97C1 !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6D97C1 !important",
        },
      },
      "& .MuiOutlinedInput-input": {
        borderRadius: "8px",
        background: "#FFF",
        color: "#6D97C1",
        lineHeight: "22px",
        height: "24px",
        "&::placeholder": {
          color: "#64748B",
          fontFamily: "Arial",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "lighter",
          lineHeight: "22px",
        },
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "8px !important",
      },
    },
  })(OutlinedInput);
  

const webStyle = {
    invalidLink: {
        marginTop: "10px",
        marginBottom: '10px',
        bgcolor: '#FEE2E2',
        borderLeft: '4px solid #DC2626',
        padding: '15px 10px 15px 10px',
        width: "100%"
    },
    otpContainerStyle: {
        display: "flex",
        justifyContent: "space-evenly",
        width: '100%',
        marginTop: '30px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    verifiedString: { color: "#059669", fontFamily: "Arial", fontSize: "14px", fontWeight: 700, padding: "2px 0" },
    emailTitle: {
        color: '#1F497D',
        fontFamily: 'Arial',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '22px',
        paddingBottom: '4px',
    },
    verifyButton: {
        width: '67px',alignItems: 'center',
        gap: '8px',
        justifyContent: 'center',
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: 700,
        fontSize: "14px",
        cursor: "pointer",
        padding: "8px",
        fontFamily: "Arial",
        textAlign: "center" as "center",
        background: "#6D97C1"
    },
    inputText: {
        background: "#FFF",
        borderRadius: "8px",
        height: "40%",
        padding: "0",
        color: "#6D97C1",
        cursor: "pointer"
    },
    page: {
        background: "#F3F4F8",
        padding: "50px 100px",
        maxWidth: "1280px"
    },
    form: {
        background: "#FFFFFF",
        width: "auto",
        margin: "0px",
        padding: "24px 40px 10px 40px",
        borderRadius: "8px 8px 32px 8px",
        position: "relative"
    },
    noBtn: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        },
        "borderRadius": "8px",
        "border": "1px solid  #1F497D",
        "background": "#FFF",
        "width": "120px",
        "height": "56px",
        "padding": "10px 16px",
        "textTransform": "none"
    },
    yesBtn: {
        "border": "1px solid #C7D9F0",
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "#C7D9F0"
        },
        "borderRadius": "8px",
        "background": "#C7D9F0",
        "display": "flex",
        "width": "120px",
        "height": "56px",
        "padding": "16px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "textTransform": "none",
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "5px",
        paddingTop: "25px !important"
    },
    btncontainerMain: { margin: "0px", width: "auto", paddingTop: "22px" },
    btnContainer1: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "5px",
        paddingTop: "170px !important"
    },
    cardTitle: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginBottom": "32px"
    },
    uploadTxt: {
        "display": "flex",
        "width": "121px",
        "justifyContent": "flex-end",
        "alignItems": "center",
        "gap": "6px",
        "borderRadius": "4px",
        "background": "#F4F4F4",
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "whiteSpace": "nowrap",
        "marginLeft": "13px"
    },
    errorText: {
        "color": "#DC2626",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "display": "flex"
    },
    bottomLeft: {
        position: "absolute",
        bottom: "0px",
        left: "0px"
    },
    topRight: {
        position: "absolute",
        top: "0px",
        right: "0px"
    },
    formfieldCont: {
        margin: "0px",
        width: "auto",

    }
}
const InputField = styled(TextField)({
    display: 'flex',
    alignItems: 'center',
    borderRadius: "8px",
    position: 'relative',
    "& .MuiOutlinedInput-input": {
        borderRadius: "9px",
        border: "0px solid #9C9C9C",
        background: "#FFF",
        color: "#6D97C1",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontWeight": "lighter",
        cursor: "pointer",
        "fontStyle": "normal",
        "lineHeight": "22px",
        "&:focus": {
            border: "0px solid #1F497D",
        },
        "&:hover": {
            border: "0px solid #6D97C1",
        },
        "&::placeholder": {
            "fontFamily": "Arial",
            "color": "#64748B",
            "fontStyle": "normal",
            "fontSize": "14px",
            "lineHeight": "22px",
            "fontWeight": "lighter",
        },
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "9px",
        height: "46px",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9C9C9C",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9C9C9C",
        },
    },
    "& .MuiFormHelperText-contained": {
        marginLeft: '0px'
    },
})

const PhoneInputStyled = styled(PhoneInput)({
    '& .flag-dropdown': {
        "&:focus" : {
            border: "2px solid #9c9c9c",
            borderRight: "0px !important"
        },
        "&:hover" : {
            border: "2px solid #9c9c9c",
            borderRight: "0px !important"
        },
        background: "white",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
        borderRight: "0px !important"
    },
    "& .selected-flag": {
        background: "white",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
    },
    "& .open": {
        background: "white",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
    },
    '& .form-control': {
        "&:focus" : {
            border: "2px solid #9c9c9c"
        },
        "&:hover" :{
            border: "2px solid #9c9c9c"
        },
        "&::placeholder": {
            "color": "#64748B",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "22px"
        },        
    },
    '& .form-control:hover + div': {
        border: "2px solid #9c9c9c"
    },
    '& .form-control:focus + div': {
        border: "2px solid #9c9c9c"
    }
});
// Customizable Area End
