import { Avatar, Box, Switch, Typography, alpha, styled } from "@material-ui/core";
import React, { Component } from "react";
import { tick, arrow } from "../../src/assets";
import CustomBottonComponent from "../../../../components/src/CustomBottonComponent.web";
import { UserDetails } from "../ProfilePageForRecruiterController.web";

interface ProfileInfoCardProps {
    getInitials: (status?: string | undefined) => string;
    userDetails: UserDetails;
    handleDirectApproachChange: () => void;
    handleUpdateProfile: () => void;
    toggleDrawerRecruiter: (value: boolean) => void;
    handleLeaderBoardNavigation: () => void
}

export default class ProfileInfoCard extends Component<ProfileInfoCardProps> {
    constructor(props: ProfileInfoCardProps) {
        super(props);
    }
    render() {
        return (
            <Box className="profileLeft" style={{borderRadius:"16px", flex: '0 0 22%', maxWidth: '25%', padding: '0 8px', background: '#FFFFFF' }} >
                <Box style={{ ...webstyle.item, padding: 0 }}>
                    <Box style={{ padding: '17px' }}>
                        <Box style={{ display: "flex", alignItems: "center" }} >
                            <Avatar style={{ background: '#6D97C1', color: "#FFFFFF", width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {this.props.getInitials(this.props.userDetails.full_name)?.charAt(0) ?? '-'}
                            </Avatar>
                            <Typography
                                style={{
                                    marginLeft: '15px',
                                    color: "#17365D",
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    fontFamily: 'Arial'
                                }}
                                variant="body2"
                            >
                                {this.props.getInitials(this.props.userDetails.full_name) ?? '-'}
                            </Typography>
                            <img src={tick} style={{ height: "4%", width: "10%", marginLeft: '15px', }} />
                        </Box>
                        <Box style={webstyle.workEmailBox1}>
                            <Box>
                                <Typography style={webstyle.workEmailText2}>Work email id</Typography>
                                <Typography style={webstyle.workEmailText1}>{this.props.userDetails.work_email_id ?? '-'}</Typography>
                            </Box>
                            <Box>
                                <Typography style={webstyle.verifiedText1}>Verified</Typography>
                            </Box>
                        </Box>
                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px", }}>
                            <Box>
                                <Typography style={webstyle.workEmailText2}>Personal email id</Typography>
                                <Typography style={webstyle.workEmailText1}> {this.props.userDetails.personal_email_id ?? '-'}</Typography>
                            </Box>
                            <Box>
                                <Typography style={webstyle.verifiedText1}>Verified</Typography>
                            </Box>
                        </Box>
                        <Box style={webstyle.openBox1}>
                            <Typography
                                variant="caption"
                                style={webstyle.openText1}>
                                Open for Direct Approach
                            </Typography>
                            <CustomSwitch
                                data-test-id="directApproachSwitch"
                                checked={this.props.userDetails.open_for_direct_approach}
                                onChange={this.props.handleDirectApproachChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                // color="default"
                            />
                        </Box>
                        <Box className="profileBtn">
                            <CustomBottonComponent
                                data-test-id="profileBtn"
                                title="Update my profile"
                                type="normal"
                                onClick={() => this.props.toggleDrawerRecruiter(true)}
                                style={webstyle.completeProfile1}
                            />
                        </Box>
                    </Box>
                    <Box style={webstyle.recruiterBox1}>
                        <Typography style={webstyle.recruiterText1}>Recruiter</Typography>
                    </Box>
                    <hr style={webstyle.hr1} />
                    <Box data-test-id="leaderBoardBtn" style={webstyle.LeaderShipBox1} onClick={this.props.handleLeaderBoardNavigation}>
                        <Typography style={webstyle.LeadershipText1}>Go to Leaderboard</Typography>
                        <img src={arrow} style={webstyle.arrowImg1} />
                    </Box>
                    <hr style={webstyle.hr1} />
                </Box>
            </Box>
        );
    }
}

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "rgb(31, 73, 125)",
        '&:hover': {
          backgroundColor: alpha("rgb(31, 73, 125)", theme.palette.action.hoverOpacity),
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "rgb(31, 73, 125)",
      },
    width: 36,
    height: 17,
    padding: 0,
    borderRadius: "20px",
    '& .MuiSwitch-switchBase': {
    padding: 0,
    },
    '& .MuiSwitch-thumb': {
        width: 16,
        height: 17,
    },    
  }));

const webstyle = {
    item: {
        background: "#ffffff",
        borderRadius: '20px',
        padding: '20px',
        minHeight: '160px'
    },
    workEmailBox1: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px"
    },
    workEmailText2: {
        color: "#9C9C9C",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Arial"
    },
    workEmailText1: {
        color: "#17365D",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Arial",
        marginTop: "2px"
    },
    verifiedText1: {
        color: "#6D97C1",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Arial"
    },
    openBox1: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: "10px",
        marginBottom: '10px'
    },
    openText1: {
        fontFamily: "Arial",
        fontSize: '14px',
        fontWeight: '400',
        color: '#17365D',
    },
    completeProfile1: {
        backgroundColor: '#1F497D',
        width: "100%",
        marginTop: '15px',
        height: '34px',
        boxShadow: "0px 0px 17px 0px #11000040",
        fontFamily: "Arial",

    },
    recruiterBox1: {
        background: "#C7D9F0",
        marginTop: "10px",
        borderRadius: "0px 50px 50px 0px",
        padding: "10px 10px 10px 10px",
    },
    recruiterText1: {
        color: "#1F497D",
        fontWeight: 700,
        fontFamily: "Arial",
        fontSize: "14px",
        paddingLeft: "14px"
    },
    LeaderShipBox1: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "4px",
        cursor: "pointer"
    },
    LeadershipText1: {
        color: "#1F497D",
        fontWeight: 700,
        fontFamily: "Arial",
        fontSize: "14px",
        paddingLeft: '14px'
    },
    arrowImg1: {
        width: "15px",
        height: "15px",
        paddingRight: "10px"
    },
    hr1: {
        borderWidth: "1px 0px  1px  0px",
        borderStyle: "solid",
        borderColor: "#C7D9F0"
    },
}