import React from 'react';
import { Grid, Typography, Divider, Avatar, Button, Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles, styled } from '@material-ui/core/styles';
import { leftblueicon } from '../assets';
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import DragAndDrapFile from '../../../../components/src/DragAndDrapFile';
import * as Yup from 'yup';
import TextFieldComponent from './TextFieldComponent';
import MCQType from '../../../../components/src/MCQType';
import CustomSelectDropdownComponent from './CustomSelectDropdownComponent'
import { JobDetailLoader, JobDetails, Question, StateOfCatelogue } from '../CatalogueController1';
import Uploadmedia3 from '../../../uploadmedia3/src/Uploadmedia3.web';
import CustomtextFieldApplyJob from '../../../../components/src/CustomtextFieldApplyJob';
import PhoneInput from 'react-phone-input-2';
import { handleSubmitApplyJobFunction, isPhoneValidOrNot } from '../../../../../packages/components/src/Utils.web';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "borderRadius": "16px",
            "border": "1px solid #F4F4F4",
            "background": "#FFF",
            "padding": "12px 0px 0px 0px",
            "position": "relative",
            "marginTop": "10px",
            "height": 'fit-content'
        },
        header: {
            "display": "flex",
            "justifyContent": "flex-start",
            "alignItems": "center",
            "padding": "16px 8px 36px 24px !important",
            "height": 'fit-content'
        },
        title: {
            "fontFamily": "Arial",
            "fontWeight": "bold",
            "fontSize": "14px",
            "color": "#17365D"
        },
        name: {
            "fontFamily": "Arial",
            "fontWeight": "lighter",
            "color": "#17365D",
            "lineHeight": "unset"
        },
        desc: {
            "fontFamily": "Arial",
            "fontWeight": "lighter",
            "fontSize": "14px",
            "color": "#6D97C1",
            "lineHeight": "unset",
            wordBreak: "break-word"
        },
        country: {
            "fontFamily": "Arial",
            "fontWeight": "bold",
            "fontSize": "14px",
            "color": "#9C9C9C",
            "lineHeight": "unset"
        },
        profilecontainer: {
            "display": "flex",
            "justifyContent": "flex-start",
            "alignItems": "center",
            "paddingBottom": "25px",
            "padding": "0px 48px 0px 48px"
        },
        body: {
            padding: "0px 48px 0px 48px",
            '& .makeStyles-root-153': {
                '& .makeStyles-root-153': {
                    '& .MuiOutlinedInput-input::placeholder': {
                        fontSize: '16px'
                    },
                    '& .MuiOutlinedInput-input': {
                        fontSize: '16px'
                    }
                }
            }

        },
        additionalField: {
            '& >div': {
                '& .MuiFormControl-root': {
                    color: 'red',
                    '& .makeStyles-root-185': {
                        color: 'red',
                        '& .MuiFormControl-root': {
                            '& .makeStyles-root-185': {
                                color: 'red',
                                '& >div': {
                                    color: 'red'
                                },
                                '& .makeStyles-root-185': {
                                    color: 'red',
                                    '& .makeStyles-selected-196': {
                                        fontSize: '16px',
                                        color: 'red'
                                    }
                                }
                            }
                        }
                    }
                }
            },
            padding: "12px 0px",
            '& .makeStyles-root-197': {
                '& .makeStyles-root-197': {
                    '& .MuiOutlinedInput-input::placeholder': {
                        fontSize: '16px'
                    },
                    '& .MuiOutlinedInput-input': {
                        fontSize: '16px',
                        color: '#1F497D',
                        padding: "13px 14px"
                    }
                }
            }
        },
        formcontainer: {

        },
        form: {
            padding: "0px",
            '& .MuiGrid-spacing-xs-3 > .MuiGrid-item': {
                padding: "12px 48px 12px 48px"
            },
            '& .MuiGrid-container': {
                '& .MuiGrid-item': {
                    '& .MuiTextField-root': {
                        '& .makeStyles-root-151': {
                            '& .MuiOutlinedInput-input::placeholder': {
                                fontSize: '16px'
                            },
                            '& .MuiOutlinedInput-input': {
                                fontSize: '16px',
                            }
                        }
                    }
                }
            }
        },
        btnContainer: {
            "padding": "24px 48px 24px 48px",
            "display": "flex",
            "justifyContent": "space-between",
        },
        btnContainerSingle: {
            "padding": "24px 40px 24px 40px",
            "display": "flex",
            "justifyContent": "end",
        },
        noBtn: {
            "padding": "10px 16px 10px 16px",
            "background": "#FFFFFF",
            "border": "1px solid #F4F4F4",
            "borderRadius": "8px",
            "fontSize": "14px",
            "color": "#6D97C1",
            "textTransform": "none",
            "fontWeight": "bold",
            "&:hover": {
                "padding": "10px 16px 10px 16px",
                "background": "#FFFFFF",
                "borderRadius": "8px",
                "color": "#6D97C1",
            }
        },
        yesBtn: {
            "padding": "10px 16px 10px 16px",
            "fontSize": "14px",
            "background": "#6D97C1",
            "borderRadius": "8px",
            "color": "#FFFFFF",
            "textTransform": "none",
            "fontWeight": "bold",
            "alignSelf": "flex-end",
            "&:hover": {
                "padding": "10px 16px 10px 16px",
                "background": "#6D97C1",
                "borderRadius": "8px",
                "color": "#FFFFFF"
            }
        },
        resumeField: {
            padding: "0px 48px 0px 48px",
            '& .MuiFormControl-root': {
                width: '100%'
            }
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "display": "flex",
            "marginTop": "6px"
        },
    }),
);

type FormValues = {
    full_phone_number: string,
    email: string,
    resume: string | File,
    cover_letter: string | File
}

type Props = {
    submitJobDetailHandler: (data: FormValues) => void,
    getJobDetails: JobDetails,
    applyJobHandler?: (status: boolean) => void,
    resumeRadioButtonSelected?: (file: any) => void,
    loader: JobDetailLoader,
    progress: number,
    remainingTime: number,
    mainState: StateOfCatelogue,
    applyJob: boolean,
    saveDraftJobApplyData: (data: {email: string, full_phone_number: string, resume: { url: string | File, name: string, size: number, selectId: string | number }[], cover_letter: { url: string | File, name: string, size: number, selectId: string | number }[]}) => void;
}

const ApplyJobForm = (props: Props) => {
    const { submitJobDetailHandler, resumeRadioButtonSelected, getJobDetails, applyJobHandler, loader, progress, remainingTime, saveDraftJobApplyData, mainState } = props
    const classes = useStyles();
    const [step, setsStep] = React.useState<number>(1);
    const [buttonTitle, setButtonTitle] = React.useState<string>("Next");
    const [selectedResume, setSelectedResume] = React.useState<string | number | undefined>(0);
    const [selectedCoverLater, setSelectedCoverLater] = React.useState<string | number | undefined>(0);
    const [othersAnswer, setOthersAnswer] = React.useState<{ [key: string]: string }>({});
    const [resumeList, setResume] = React.useState<{ url: string | File, name: string, size: number, selectId: string | number }[]>(() => props.mainState.userDetails.attributes?.resume ? [{ ...props.mainState.userDetails.attributes.resume, selectId: 0 }] : [])
    const [coverLetterList, setCoverLetterList] = React.useState<{ url: string | File, name: string, size: number, selectId: string | number }[]>(() => props.mainState.userDetails.attributes?.cover_letter ? [{ ...props.mainState.userDetails.attributes.cover_letter, selectId: 0 }] : [])
    const [email, setEmail] = React.useState<string>("");
    const [phoneNumber, setPhoneNumber] = React.useState<string>("");

    const onSubmit = (data: FormValues) => {
        handleSubmitApplyJobFunction(data, step, submitJobDetailHandler, saveDraftJobApplyData, handleNext, setsStep, setButtonTitle, email, phoneNumber,coverLetterList, resumeList, othersAnswer,props.mainState.applyJobCurrent.attributes.questions.length)
    }

    React.useEffect(() => { 
        setEmail(mainState.draftedData?.email ? mainState.draftedData.email : props.mainState.userDetails.attributes.email);
        setPhoneNumber(mainState.draftedData?.full_phone_number ? mainState.draftedData.full_phone_number : props.mainState.userDetails.attributes.full_phone_number);

        if(mainState.draftedData?.cover_letters?.length > 0) {
            setCoverLetterList([...mainState.draftedData.cover_letters.map((letter, index) => ({selectId: coverLetterList.length + index, ...letter})), ...props.mainState.userDetails.attributes?.cover_letter ? [{ ...props.mainState.userDetails.attributes.cover_letter, selectId: 0 }] : []]);
        }

        if(mainState.draftedData?.resumes?.length > 0) {
            setResume([...mainState.draftedData.resumes.map((resume, index) => ({selectId: resumeList.length + index, ...resume})), ...props.mainState.userDetails.attributes?.resume ? [{ ...props.mainState.userDetails.attributes.resume, selectId: 0 }] : []]);
        }

    }, [mainState.draftedData?.email, mainState.draftedData?.full_phone_number, mainState.draftedData?.resumes, mainState.draftedData?.cover_letters, mainState])

    const latestValues = React.useRef({
        email: email,
        phoneNumber: phoneNumber,
        resumeList: resumeList,
        coverLetterList: coverLetterList
    });
    
    React.useEffect(() => {
        latestValues.current = {
            email: email,
            phoneNumber: phoneNumber,
            resumeList: resumeList,
            coverLetterList: coverLetterList
        };
    }, [email, phoneNumber, resumeList, coverLetterList]);
    
    React.useEffect(() => {
        return () => {
            saveDraftJobApplyData({
                email: latestValues.current.email,
                full_phone_number: latestValues.current.phoneNumber,
                resume: latestValues.current.resumeList,
                cover_letter: latestValues.current.coverLetterList
            });
        };
    }, []); 

    const backHandler = (data?: {email: string, full_phone_number: string, resume: File | string, cover_letter: File | string}) => {
        setButtonTitle("Next")
        if (step === 1) {
            if (applyJobHandler) {
                saveDraftJobApplyData({
                    email: email,
                    full_phone_number: phoneNumber,
                    resume: resumeList,
                    cover_letter: coverLetterList
                })
                applyJobHandler(false)
            }
        }
        else if (step !== 1) {
            if(step === 3 && props.mainState.applyJobCurrent.attributes.questions.length === 0) {
                setsStep(prev => prev - 2)
                return;
            }
            setsStep(prev => prev - 1)
        }
    }

    const validationSchema = step === 1 ? Yup.object().shape({
        full_phone_number: Yup.string().required('Contact Number is a required field')
            .matches(/^\d+$/, 'Phone number must be numeric'),
        resume: Yup.mixed().required('Upload resume is a required field'),
        email: Yup.string().email('Please enter valid mail id').required('Email is required'),
    }) : Yup.object().shape({
        full_phone_number: Yup.string().required('Contact Number is a required field'),
        resume: Yup.mixed().required('Upload resume is a required field'),
        email: Yup.string().email('Please enter valid mail id').required('Email is required'),
        ...props.mainState.applyJobCurrent.attributes.questions.reduce<{ [key: string]: Yup.StringSchema | Yup.ArraySchema<string> }>((schema, question) => {
            const key = `Question${question.id}`;
            if (question.required) {
                if (question.question_type === 'Short Answer') {
                    schema[key] = Yup.string().required(`Question is required`);
                } else if (question.question_type === 'Multiple Choice') {
                    schema[key] = Yup.array()
                        .of(Yup.string())
                        .min(1, `Question is required`)
                        .required(`Question is required`);
                } else if (question.question_type === 'Dropdown') {
                    schema[key] = Yup.string().required(`Question is required`);
                }
            }
            return schema;
        }, {})
    })

    const handleNext = () => {
        if (step === 3) {
            return;
        } else {
            setsStep(prev => prev + 1)
        }
    }

    const fileUploader = (file: File, setFieldError: (field: string, message: string | undefined) => void, setfielsValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
        let type: string = file.type;

        if (!type.includes("image") && !type.includes("msword") && !type.includes("pdf")) {
            setFieldError("resume", "File type is not supported");
            return
        }
        let uniqueID = uuidv4();
        setSelectedResume(uniqueID)
        setResume([{ name: file.name, size: file.size, url: file, selectId: uniqueID }, ...resumeList]);
        setfielsValue("resume", file);
    }

    const uploadCoverLetter = (file: File, setFieldError: (field: string, message: string | undefined) => void, setfielsValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
        let type: string = file.type;

        if (!type.includes("image") && !type.includes("msword") && !type.includes("pdf")) {
            setFieldError("cover_letter", "File type is not supported");
            return
        }
        let uniqueID = uuidv4()
        setSelectedCoverLater(uniqueID);
        setCoverLetterList([{ name: file.name, size: file.size, url: file, selectId: uniqueID }, ...coverLetterList]);
        setfielsValue("cover_letter", file);
    }

    const setOthersAnswerFunction = (value: string, questionId: string) => {
        let temp = { ...othersAnswer };
        temp[questionId] = value;
        setOthersAnswer(temp)
    }

    type FormValues = {
        full_phone_number: string;
        email: string;
        resume: string | File;
        cover_letter: string | File;
        [key: string]: string | [] | File | string[];
    };

    const initialValues: FormValues = {
        full_phone_number: mainState.draftedData?.full_phone_number ? mainState.draftedData.full_phone_number : props.mainState.userDetails.attributes.full_phone_number,
        email:  mainState.draftedData?.email ? mainState.draftedData.email : props.mainState.userDetails.attributes.email,
        resume: resumeList?.[0]?.url || "",
        cover_letter: coverLetterList?.[0]?.url || "",
    };

    props.mainState.applyJobCurrent.attributes.questions.forEach((question) => {
        if (question.question_type === "Multiple Choice") {
            initialValues[`Question${question.id}` as keyof FormValues] = [];
        } else {
            initialValues[`Question${question.id}` as keyof FormValues] = "";
        }
    });

    let compare = (questionTwo: Question, questionOne: Question) => questionOne.question_type.localeCompare(questionTwo.question_type)
    const questions = props.mainState.applyJobCurrent.attributes.questions;
    const sortedrtArray = questions.slice().sort(compare);


    let renderHeader = () => {
        return <>
            {
                step === 1 && <Grid item container className={classes.profilecontainer}>
                    <Grid item>
                        <Avatar alt="Remy Sharp" src={props.mainState.userDetails.attributes.photo} style={{ height: "61px", width: "61px", margin: "0px 12px 0px 0px" }} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.name} style={{ fontSize: '14px' }}>{props.mainState.userDetails.attributes.full_name}</Typography>
                        <Typography className={classes.desc}>{props.mainState.userDetails.attributes.company_indusry}</Typography>
                        <Typography className={classes.country}>{props.mainState.userDetails.attributes.location}</Typography>
                    </Grid>
                </Grid>
            }
            <Grid item className={classes.body} style={{ height: 'fit-content' }}>
                {step === 3 &&
                    <Typography className={classes.name} style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: '700', marginBottom: "10px" }}>Personal Details</Typography>
                }
            </Grid>
        </>
    }

    let isArrayOfStrings = (value: string | string[] | File | []): string[] => {
        if (!Array.isArray(value)) {
            return []
        } else {
            return value;
        }
    }

    let renderQuestions = (errors: FormikErrors<FormValues>, values: FormValues, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, touched: FormikTouched<FormValues>) => {
        return <>

            <Grid className={classes.body} item sm={12}>
                {
                    step === 3 && <Typography className={classes.name} style={{ marginBottom: '10px', fontFamily: 'Arial', fontSize: '14px', fontWeight: "700" }}>Additional Details</Typography>

                }
                {
                    sortedrtArray.map(question => {
                        if (question.question_type === "Short Answer") {
                            return <Grid item sm={12} className={classes.additionalField}>
                                <Field
                                    name={`Question${question.id}`}
                                    component={CustomtextFieldApplyJob}
                                    type="string"
                                    variant="outlined"
                                    title={question.question_text}
                                    placeHolder="Your answer"
                                    required={question.required}
                                />
                            </Grid>
                        } else if (question.question_type === "Multiple Choice") {
                            return <Grid item sm={12} className={classes.additionalField}>
                                <MCQType
                                    name={`Question${question.id}`}
                                    othersAnswer={othersAnswer}
                                    setOthersAnswer={setOthersAnswerFunction}
                                    title={question.question_text}
                                    options={[...new Set(question.options)]}
                                    required={question.required}
                                    error={errors?.[`Question${question.id}` as keyof FormValues] || ""}
                                    handleChange={(value: string) => {
                                        const dynamicProperty = `Question${question.id}` as keyof FormValues;
                                        const propertyValue = values[dynamicProperty] as string[];    
                                        if (propertyValue.find((input) => input === value)) {
                                                setFieldValue(`${dynamicProperty}`, propertyValue.filter((opt: string) => opt !== value));
                                            } else {
                                                setFieldValue(`${dynamicProperty}`, [...propertyValue, value]);
                                            }
                                    }}
                                    value={
                                        isArrayOfStrings(values[`Question${question.id}` as keyof FormValues])
                                    }
                                    isTouched={!!touched?.[`Question${question.id}` as keyof FormValues]}
                                />
                            </Grid>

                        } else if (question.question_type === "Dropdown") {
                            return <Grid item sm={12} className={classes.additionalField}>
                                <Field
                                    name={`Question${question.id}`}
                                    component={CustomSelectDropdownComponent}
                                    type="string"
                                    variant="outlined"
                                    title={question.question_text}
                                    placeHolder="Choose"
                                    required={question.required}
                                    options={[...new Set(question.options)].map((option) => ({ value: option, label: option }))}
                                />
                            </Grid>
                        }
                    })
                }
            </Grid>
        </>
    }


    let title;
    if (step === 1) {
        title = `Apply to ${getJobDetails.attributes.company_name}`;
    } else if (step === 2) {
        title = 'Additional details';
    } else if (step === 3) {
        title = 'Preview';
    }

    return (
        <Grid item container className={classes.root} sm={12} >
            <Grid item sm={12} className={classes.header}>
                <Box onClick={() => backHandler()}>
                    <img src={leftblueicon} alt="back" />&nbsp;&nbsp;&nbsp;
                </Box>
                <Typography className={classes.title}>
                    {title}
                </Typography>
            </Grid>
            <Grid item container style={{ height: 'fit-content' }}>
                {
                    renderHeader()
                }
                <Grid item container className={classes.formcontainer}>
                    <Formik
                        data-test-id="form-ik"
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(data) => onSubmit(data)}
                    >
                        {({ values, errors, setFieldValue, setFieldError, touched, setTouched }) => (
                            <Form style={{ width: "inherit" }}>
                                <Grid className={classes.form}>
                                    <Grid container spacing={3} style={{ margin: "0px", width: "auto" }}>

                                        {
                                            (step === 1 || step === 3) && <>
                                                <Grid item sm={12} className={classes.body}>
                                                    <Field
                                                        name="email"
                                                        component={TextFieldComponent}
                                                        type="email"
                                                        variant="outlined"
                                                        title="Email Address"
                                                        controlledValue={email}
                                                        onChangeControlled={(event: { target: { value: string; }; }) => {
                                                            setEmail(event.target.value)
                                                            setFieldValue("email", event.target.value)
                                                        }}
                                                        placeHolder="abc@email.com"
                                                        touched={touched}
                                                    />
                                                </Grid>
                                                <Grid className={classes.body} item sm={12}>
                                                    <Typography
                                                        style={{
                                                            color: '#1F497D',
                                                            fontFamily: 'Arial',
                                                            fontSize: '14px',
                                                            fontStyle: 'normal',
                                                            fontWeight: 'bold',
                                                            lineHeight: '22px',
                                                            paddingBottom: '4px',
                                                        }} >Contact Number</Typography>
                                                    <PhoneInputStyledOne
                                                        containerStyle={{
                                                            height: "44px"
                                                        }}
                                                        inputStyle={{
                                                            height: "44px",
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            color: "#6D97C1",
                                                        }}
                                                        dropdownStyle={{
                                                            borderTopLeftRadius: "8px",
                                                            borderBottomLeftRadius: "8px",
                                                            background: "white"
                                                        }}
                                                        autoFormat
                                                        enableSearch
                                                        placeholder={"Enter your contact number here"}
                                                        specialLabel="Contact Number"
                                                        searchNotFound="Country Not Found"
                                                        data-test-id="PhoneInputStyled"
                                                        value={phoneNumber}
                                                        onChange={(value, countryData) => {
                                                            setPhoneNumber(value);
                                                            setFieldValue("full_phone_number", value);
                                                            setTouched({ "full_phone_number": true })
                                                            if (!isPhoneValidOrNot({ phone: value, country: countryData })) {
                                                                setTimeout(() => {
                                                                    setFieldError("full_phone_number", "Invalid mobile number");
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    {errors.full_phone_number && touched.full_phone_number && <p className={classes.errorText}>{errors.full_phone_number}</p>}
                                                </Grid>
                                                <Grid item sm={12} className={classes.body}>
                                                    <DragAndDrapFile
                                                        onChange={(file: File) => fileUploader(file, setFieldError, setFieldValue)}
                                                        error={errors.resume}
                                                        name="resume"
                                                        title="Upload Resume (Image/Doc)"
                                                        step={step}
                                                        value={values.resume}
                                                        handleRemoveFile={(removeID: string | number) => {
                                                            let filteredList = resumeList.filter((resume) => resume.selectId !== removeID);
                                                            setResume(filteredList);
                                                            if(filteredList.length > 0) {
                                                                setSelectedResume(filteredList[0].selectId);
                                                                setFieldValue("resume", filteredList[0].url)
                                                            }
                                                        }}
                                                        resumeList={resumeList}
                                                        resumeRadioButtonSelected={resumeRadioButtonSelected}
                                                        selectedId={selectedResume}
                                                        handleInputChange={(value: { url: string | File, name: string, size: number, selectId: string | number }) => {
                                                            setSelectedResume(value.selectId);
                                                            setFieldValue("resume", value.url);
                                                        }}
                                                    />
                                                    {loader.resume && <Uploadmedia3 navigation={undefined} id={''} progress={progress} time={remainingTime} />}
                                                </Grid>
                                                <Grid item sm={12} className={classes.resumeField}>
                                                    <DragAndDrapFile
                                                        onChange={(file: File) => uploadCoverLetter(file, setFieldError, setFieldValue)}
                                                        error={errors.cover_letter}
                                                        name="cover_letter"
                                                        title="Upload Cover Letter (Image/Doc)"
                                                        step={step}
                                                        value={values.cover_letter}
                                                        handleRemoveFile={(removeID: string | number) => {
                                                            let filteredList = coverLetterList.filter((coverLater) => coverLater.selectId !== removeID)
                                                            setCoverLetterList(filteredList);
                                                            if(filteredList.length > 0) {
                                                                setSelectedCoverLater(removeID);
                                                                setFieldValue("cover_letter", filteredList[0].url)
                                                            }
                                                        }}
                                                        resumeList={coverLetterList}
                                                        selectedId={selectedCoverLater}
                                                        handleInputChange={(value: { url: string | File, name: string, size: number, selectId: string | number }) => {
                                                            setSelectedCoverLater(value.selectId);
                                                            setFieldValue("cover_letter", value.url);
                                                        }}
                                                    />
                                                    {loader.cover_letter && <Uploadmedia3 navigation={undefined} id={''} progress={progress} time={remainingTime} />}
                                                </Grid>
                                            </>
                                        }
                                        {
                                            step === 3 && <Box style={{ borderBottom: '1px solid #F4F4F4', width: '100%' }}></Box>
                                        }
                                        {

                                            (step === 2 || step === 3) && (props.mainState.applyJobCurrent.attributes.questions.length > 0) &&  renderQuestions(errors, values, setFieldValue, touched)
                                        }
                                    </Grid>
                                </Grid>

                                <Divider style={{
                                    margin: "0px",
                                    height: "1px",
                                    background: "#F4F4F4",
                                    width: "100%"
                                }} />
                                <Grid item sm={12} className={classes.btnContainer}>
                                    <Button className={classes.noBtn} onClick={() => backHandler({email: values.email, full_phone_number: values.full_phone_number, resume: values.resume, cover_letter: values.cover_letter })}>Back</Button>
                                    <Button id="btn-sbmt" className={classes.yesBtn} type="submit">{buttonTitle}</Button>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ApplyJobForm

const PhoneInputStyledOne = styled(PhoneInput)({
    '& .flag-dropdown': {
        "&:hover" : {
            border: "2px solid #9c9c9c",
            borderRight: "0px !important"
        },
        "&:focus" : {
            border: "2px solid #9c9c9c",
            borderRight: "0px !important"
        },
        background: "white",
        borderRight: "0px !important",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
    },
    "& .open": {
        background: "white",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
    },
    "& .selected-flag": {
        background: "white",
        borderBottomLeftRadius: "8px !important",
        borderTopLeftRadius: "8px !important",
    },
    '& .form-control': {
        "&:focus" : {
            border: "2px solid #9c9c9c"
        },
        "&:hover" :{
            border: "2px solid #9c9c9c"
        },
        "&::placeholder": {
            "fontWeight": "lighter",
            "color": "#64748B",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "lineHeight": "22px"
        },        
    },
    '& .form-control:focus + div': {
        border: "2px solid #9c9c9c"
    },
    '& .form-control:hover + div': {
        border: "2px solid #9c9c9c"
    },
});