import { Box, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { EditIcon, acc } from "../../src/assets";

export interface CompanyDetail {
    company_name: string,
    company_description: string,
    company_website: string,
    company_indusry: string,
    company_size: number,
    company_headquarters: string
    company_founded_year: number,
    company_icon: string
}

interface PersonalInfoProps {
    handleCompanyProfile?: () => void;
    companyDetails: CompanyDetail;
    boxBorder?: string;
}

export default class CompanyDetails extends Component<PersonalInfoProps> {
    constructor(props: PersonalInfoProps) {
        super(props);
    }
    render() {
        return (
            <Box style={{ ...webstyle.itemBox, position: 'relative', overflow: 'auto', borderRadius: this.props.boxBorder ?? '20px' }} >
                <Box style={webstyle.personalDetailsTopBox}>
                    <Typography >
                        <span style={webstyle.blockHeading1}>
                            Company Details
                        </span>
                    </Typography>
                    {this.props.handleCompanyProfile &&
                     <Typography data-test-id="editPersonalDetails" style={webstyle.editIconStyleBox} onClick={this.props.handleCompanyProfile}>
                        <img alt="Edit icon" src={EditIcon} style={{ ...webstyle.editIcon, width: "15px", height: "15px" }} />
                        Edit here
                    </Typography>}
                </Box>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "10px", wordBreak: "break-word" }}>
                    {this.props.companyDetails?.company_icon && <img style={webstyle.companyLogo} src={this.props.companyDetails?.company_icon || ""} alt="logo" />}
                        <span style={webstyle.companyTextt}> {this.props.companyDetails?.company_name ?? "-"}
                    </span>
                </Box>
                <Box style={webstyle.companyDetailsBoxx}>
                    <Typography style={webstyle.companyDetailsTexts}>
                        {this.props.companyDetails?.company_description ?? "-"}
                    </Typography>
                </Box>
                <Box style={{ marginTop: "10px" }}>
                    <Typography style={webstyle.websiteTextt}>
                        Website
                    </Typography>
                    <a style={webstyle.websiteLink as React.CSSProperties} href={this.props.companyDetails.company_website} target="_blank" id="company-limk">
                        {this.props.companyDetails.company_website ?? "-"}
                    </a>
                </Box>
                <Box style={{ marginTop: "10px" }}>
                    <Typography style={webstyle.websiteTextt}>
                        Industry
                    </Typography>
                    <Typography style={webstyle.industryTextt}>
                        {this.props.companyDetails?.company_indusry ?? "-"}
                    </Typography>
                </Box>
                <Box style={{ marginTop: "10px" }}>
                    <Typography style={webstyle.websiteTextt}>
                        Company Size
                    </Typography>
                    <Typography style={webstyle.industryTextt}>
                        {this.props.companyDetails?.company_size ?? "-"}
                    </Typography>
                </Box>
                <Box style={{ marginTop: "10px" }}>
                    <Typography style={webstyle.websiteTextt}>
                    Headquarters
                    </Typography>
                    <Typography style={webstyle.industryTextt}>
                    {this.props.companyDetails?.company_headquarters ?? "-"}
                    </Typography>
                </Box>
            </Box>
        );
    }
}

const webstyle = {
    itemBox: {
        background: "#ffffff",
        padding: '20px',
        minHeight: '160px'
    },
    personalDetailsTopBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'fit-content',
        marginBottom: '15px'
    },
    blockHeading1: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#17365D'
    },
    editIconStyleBox: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#1F497D'
    },
    editIcon: {
        height: '20px',
        marginRight: '10px'
    },
    companyLogo: {
        width: "26px",
        height: "26px",
    },
    companyTextt: {
        marginRight: '10px',
        marginLeft: '4px',
        fontSize: "14px",
        fontFamily: "Arial",
        color: "#1F497D"
    },
    editIconImageBox: {
        width: '25px',
        height: "25px",
        cursor: 'pointer',
    },
    editIconSpan: {
        display: 'inline-flex',
        width: "80%",
        alignItems: 'center',
        justifyContent: 'center',
    },
    companyDetailsBoxx: {
        width: "100%",
        marginTop: "10px"
    },
    companyDetailsTexts: {
        color: "#9C9C9C",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Arial",
        wordBreak: "break-word" as const
    },
    websiteTextt: {
        color: "#17365D",
        fontSize: "14px",
        fontFamily: "Arial",
        fontWeight: 700
    },
    websiteLink: {
        color: "#538DD3",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Arial",
        wordBreak: "break-all"
    },
    industryTextt: {
        color: "#9C9C9C",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Arial"
    },
}