import { Box,Paper,Typography } from "@material-ui/core"
import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import NotesIcon from '@material-ui/icons/Notes';
import GetAppIcon from '@material-ui/icons/GetApp';

type Props = {
url: string,
downloadFile: (url:string,filename:string)=>void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      fileCard: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px"
      },
      fileIcon: {
        fontSize: 32,
        color: "#fff"
      },
      fileInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
      },
      fileName: {
        fontSize: '14px',
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
        fontFamily: "Arial",
        color: "#17365D",
        lineHeight: "1.375rem",
      },
      fileType: {
        fontSize: '1rem',
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
        fontFamily: "Arial",
        color: "#fff",
        lineHeight: "1.375rem",
        marginTop:"5px",
        marginBottom:"5px"
      },
      fileSize: {
        fontSize: '14px',
        fontFamily: "Arial",
        fontWeight: 400,
        lineHeight: "1.375rem",
        color: "#9C9C9C"
      },
      attachmentroot: {
        maxWidth: "240px",
        border: "1px solid #CBD5E1",
        borderRadius: '10px',
        marginBottom: '8px',
        marginTop: '5px',
        position: 'relative',
        '&:hover $downloadOverlay': {
          opacity: 1,
        },
      },
      iconbox: {
        backgroundColor: '#17365D',
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        padding: "12px",
        borderRadius: "8px 0px 0px 8px"
      },
      downloadOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0,
        transition: 'opacity 0.3s',
        borderRadius: "8px",
        border: "1px solid rgba(0, 0, 0, 0.7)",
        cursor: "pointer"
      },
      downloadIcon: {
        fontSize: 26,
      },
      donloadtext: {
        color: "#fff",
        fontSize: '1rem',
        fontFamily: "Arial",
        fontWeight: 400,
        lineHeight: "1.375rem",
      }

    }),
)
export default function ChatAttachments(Props: Props) {
    const classes = useStyles();
    const {url, downloadFile} = Props
    const urlObj = new URL(url);
    let size=''

const params = new URLSearchParams(urlObj.search);
const name = params.get('name') || ''; 
  size = params.get('size') || '';

    return (
      <Box className={classes.attachmentroot}>
      <Paper className={classes.fileCard} elevation={0}>
        <div className={classes.iconbox}>
          {url.includes('.docx') && <NotesIcon className={classes.fileIcon} />}
          {!url.includes('.docx') && url.includes('.doc') &&  <Typography className={classes.fileType}>DOC</Typography>}
          {url.includes('.pdf') &&  <Typography className={classes.fileType}>PDF</Typography>}
        </div>
    
      <div className={classes.fileInfo}>
        <Typography className={classes.fileName}>{name}</Typography>
      {size && 
      <Typography className={classes.fileSize}>{(Number(size) / 1024).toFixed(2)}{"KB"}</Typography>
      }
      </div>
      <div className={classes.downloadOverlay} onClick={()=>downloadFile(url,name)}>
        <GetAppIcon className={classes.downloadIcon} />
        <Typography className={classes.donloadtext}>Download</Typography>
      </div>
    </Paper>
    </Box>
    )
}