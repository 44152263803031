import JobDetailsListingController, {
    Props,
    // Customizable Area Start
    AppliedJob,
    configJSON,
    WorkExperience,
    TalentPoolCandidate
    // Customizable Area End
} from "./JobDetailsListingController.web";
// Customizable Area Start
import { Box, Grid, IconButton, Typography, Button, Tabs, Tab, Avatar, Divider, TableContainer, Table, TableBody, Paper, TableRow, TableCell, TableHead, FormControlLabel } from "@material-ui/core";
import React, { CSSProperties, ReactNode } from "react";
import { applied0, applied1, bgRightTop, chat0, chat1, clockIcon, comment, diamond, dont, down, down0, down1, downOpenIcon, dright0, dright1, hand0, hand1, love0, more, question, question0, question1, reject0, reject1, right, shakehand, sigma, sigma0, sigma1 } from "./assets";
import CustomChipComponent from "../../../../packages/components/src/CustomChipComponent.web";
import PopoverModal from "../../../../packages/components/src/PopoverModal.web";
import CustomCheckBoxComponent from "../../../../packages/components/src/CustomCheckBoxComponent";
import FilterModal from "./components/FilterModal";
import TextFieldComponent from "../../../../packages/blocks/catalogue/src/components/TextFieldComponent";
import { Field, Form, Formik } from "formik";
import RadioSelectComponent from "../../../../packages/components/src/RadioSelectComponent";
import CustomRadio from "../../../../packages/components/src/CustomRadio";
import CustomSelectDropdownComponent from "../../../../packages/components/src/CustomSelectDropdownComponent.web";
import { Job } from "./JobListingController";
import JobPostSubscriptionDialog from "../../../components/src/JobPostSubscriptionDialog.web";
import { TruncateWithTooltip, getCommaSeparatedValue, getPhoneNumber, handleCondition } from "../../../../packages/components/src/Utils.web";
import BootstrapTooltip from "../../../../packages/blocks/catalogue/src/components/BootstrapTooltip";
// Customizable Area End

class JobListing extends JobDetailsListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderTab = (tabName: string, iconOn: string, iconOff: string, label: string) => {
        const isActive = this.state.currentTab === tabName;
        const icon = isActive ? iconOn : iconOff;
        return (
            <Tab
                {...this.a11yProps(tabName)}
                label={
                    <Box sx={webStyle.bodTab}>
                        <img src={icon} />
                        <Typography style={isActive ? webStyle.tabTitleSelected as CSSProperties : webStyle.tabTitle as CSSProperties}>{label}</Typography>
                    </Box>
                }
                value={tabName}
            />
        );
    };

    renderStatusIcon = (status: keyof { rejected: ReactNode, messaged: ReactNode, review: ReactNode, interviewing: ReactNode, liked: ReactNode }, activeColor: string, inactiveColor: string, satatusOfApp: string, toolTipTitle: string = "") => {
        const active = status === satatusOfApp;
        const color = active ? activeColor : inactiveColor;
        const colorBorder = active ? activeColor : "#F4F4F4"
        const icons: { rejected: ReactNode, messaged: ReactNode, review: ReactNode, interviewing: ReactNode, liked: ReactNode } = {
            rejected: (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="white" />
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke={colorBorder} />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10ZM12 20C12 15.6 15.6 12 20 12C21.8 12 23.5 12.6 24.9 13.7L13.7 24.9C12.6 23.5 12 21.8 12 20ZM15.1 26.3C16.5 27.4 18.2 28 20 28C24.4 28 28 24.4 28 20C28 18.2 27.4 16.5 26.3 15.1L15.1 26.3Z" fill={color} />
                </svg>
            ),
            messaged: (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="white" />
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke={colorBorder} />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8 11H27.2C28.19 11 29 11.81 29 12.8V23.6C29 24.59 28.19 25.4 27.2 25.4H14.6L11 29V12.8C11 11.81 11.81 11 12.8 11ZM14.6 23.6H26.3C26.795 23.6 27.2 23.195 27.2 22.7V13.7C27.2 13.205 26.795 12.8 26.3 12.8H13.7C13.205 12.8 12.8 13.205 12.8 13.7V25.4L14.6 23.6Z" fill={color} />
                </svg>
            ),
            review: (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="white" />
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke={colorBorder} />
                    <path d="M18.6008 24C18.6008 22.65 18.7216 21.6792 18.9633 21.0875C19.2049 20.4958 19.7174 19.85 20.5008 19.15C21.1841 18.55 21.7049 18.0292 22.0633 17.5875C22.4216 17.1458 22.6008 16.6417 22.6008 16.075C22.6008 15.3917 22.3716 14.825 21.9133 14.375C21.4549 13.925 20.8174 13.7 20.0008 13.7C19.1508 13.7 18.5049 13.9583 18.0633 14.475C17.6216 14.9917 17.3091 15.5167 17.1258 16.05L14.5508 14.95C14.9008 13.8833 15.5424 12.9583 16.4758 12.175C17.4091 11.3917 18.5841 11 20.0008 11C21.7508 11 23.0966 11.4875 24.0383 12.4625C24.9799 13.4375 25.4508 14.6083 25.4508 15.975C25.4508 16.8083 25.2716 17.5208 24.9133 18.1125C24.5549 18.7042 23.9924 19.375 23.2258 20.125C22.4091 20.9083 21.9133 21.5042 21.7383 21.9125C21.5633 22.3208 21.4758 23.0167 21.4758 24H18.6008ZM20.0008 30C19.4508 30 18.9799 29.8042 18.5883 29.4125C18.1966 29.0208 18.0008 28.55 18.0008 28C18.0008 27.45 18.1966 26.9792 18.5883 26.5875C18.9799 26.1958 19.4508 26 20.0008 26C20.5508 26 21.0216 26.1958 21.4133 26.5875C21.8049 26.9792 22.0008 27.45 22.0008 28C22.0008 28.55 21.8049 29.0208 21.4133 29.4125C21.0216 29.8042 20.5508 30 20.0008 30Z" fill={color} />
                </svg>
            ),
            interviewing: (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="white" />
                    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke={colorBorder} />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.5059 13.9944L19.6259 23.8744L16.1459 20.4044C15.959 20.2171 15.7054 20.1119 15.4409 20.1119C15.1763 20.1119 14.9227 20.2171 14.7359 20.4044C14.3459 20.7944 14.3459 21.4244 14.7359 21.8144L18.9159 25.9944C19.3059 26.3844 19.9359 26.3844 20.3259 25.9944L30.9159 15.4144C31.3059 15.0244 31.3059 14.3944 30.9159 14.0044H30.9059C30.5259 13.6044 29.8959 13.6044 29.5059 13.9944ZM25.2658 14.0044C25.079 13.8171 24.8253 13.7119 24.5608 13.7119C24.2963 13.7119 24.0427 13.8171 23.8558 14.0044L18.2158 19.6444L19.6258 21.0544L25.2658 15.4044C25.6458 15.0244 25.6458 14.3844 25.2658 14.0044ZM13.2655 26.0044L9.08547 21.8244C8.69547 21.4344 8.69547 20.7944 9.08547 20.4044C9.2723 20.2171 9.52595 20.1119 9.79047 20.1119C10.055 20.1119 10.3086 20.2171 10.4955 20.4044L15.3755 25.3044L14.6755 26.0044C14.2855 26.3944 13.6555 26.3944 13.2655 26.0044Z" fill={color} />
                </svg>
            ),
            liked: (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2_2)">
                    <path d="M32 0.5H8C3.85786 0.5 0.5 3.85786 0.5 8V32C0.5 36.1421 3.85786 39.5 8 39.5H32C36.1421 39.5 39.5 36.1421 39.5 32V8C39.5 3.85786 36.1421 0.5 32 0.5Z" fill="white" />
                    <path d="M32 0.5H8C3.85786 0.5 0.5 3.85786 0.5 8V32C0.5 36.1421 3.85786 39.5 8 39.5H32C36.1421 39.5 39.5 36.1421 39.5 32V8C39.5 3.85786 36.1421 0.5 32 0.5Z" stroke={color}/>
                    <g clip-path="url(#clip1_2_2)">
                        <path d="M19.0035 27.4463L18.9955 27.4383L18.9871 27.4307L17.2626 25.856C15.5067 24.2492 13.9227 22.6569 12.51 21.0789L12.1375 21.4124L12.51 21.0789C11.157 19.5676 10.5 17.9291 10.5 16.1499C10.5 14.7077 10.9779 13.5291 11.9286 12.5785C12.8792 11.6278 14.0578 11.1499 15.5 11.1499C16.3099 11.1499 17.0694 11.3212 17.7841 11.6634C18.5051 12.0085 19.1148 12.4779 19.6183 13.0729L20 13.524L20.3817 13.0729C20.8852 12.4779 21.495 12.0085 22.2159 11.6634C22.9306 11.3212 23.6901 11.1499 24.5 11.1499C25.9422 11.1499 27.1208 11.6278 28.0714 12.5785C29.0221 13.5291 29.5 14.7077 29.5 16.1499C29.5 17.9302 28.8464 19.5736 27.5007 21.0934C26.0981 22.6775 24.5035 24.2723 22.7159 25.8779L22.7159 25.8779L22.7131 25.8804L21.0131 27.4304L21.0046 27.4382L20.9964 27.4463C20.866 27.5768 20.7184 27.669 20.5496 27.7272C20.3552 27.7942 20.1727 27.8249 20 27.8249C19.8272 27.8249 19.6447 27.7942 19.4504 27.7272C19.2817 27.669 19.1341 27.5768 19.0035 27.4463Z" fill={color === "#9C9C9C" ? "#ffffff" : color} stroke={color} stroke-width="2" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_2_2">
                        <rect width="40" height="40" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_2_2">
                        <rect width="22" height="20" fill="white" transform="translate(10 10)" />
                    </clipPath>
                </defs>
            </svg>
            )
        };

        return (
            <BootstrapTooltip title={toolTipTitle}>
            <Grid item>
                {icons[status]}
            </Grid>
            </BootstrapTooltip>
        );
    };

    renderApplicationTab = () => <>
        <Grid item container sm={12} style={webStyle.appCard}>
            <Grid item container style={{ ...webStyle.spaceBetween, paddingLeft: "5px" }}>
                <Grid item style={{ ...webStyle.center, ...webStyle.flexONE }}>
                    <Grid item style={webStyle.avtarContainer}>
                        <Avatar src={this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.photo} style={webStyle.avatarBlue}></Avatar>
                    </Grid>
                    <Grid item>
                        <Typography style={webStyle.cardname}>{this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.full_name}</Typography>
                        <Typography style={webStyle.cardDetails}>{this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.company_indusry}</Typography>
                        <Typography style={webStyle.cardDetails}>{this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.city + ", " + this.state.selectedJobApplicantDetails.attributes.job.data.attributes.country}</Typography>
                    </Grid>
                </Grid>
                <Grid item container style={{ ...webStyle.buttonsContainer, ...webStyle.flexONE, ...webStyle.flexEND }}>
                    <Grid item className="statusDetailsChange" style={{ cursor: "pointer" }} onClick={(event) => this.handleSingleStatusUpdate("rejected", [this.state.selectedJobApplicantDetails.id], event)}>
                        {this.renderStatusIcon('rejected', '#6D97C1', '#9C9C9C', this.state.selectedJobApplicantDetails.attributes.status, "Rejected")}
                    </Grid>
                    &nbsp;&nbsp;
                    <Grid item className="statusDetailsChange" style={{ cursor: "pointer" }} onClick={(event) => this.handleSingleStatusUpdate("messaged", [this.state.selectedJobApplicantDetails.id], event)}>
                        {this.renderStatusIcon('messaged', '#6D97C1', '#9C9C9C', this.state.selectedJobApplicantDetails.attributes.status, "Messaged")}
                    </Grid>
                    &nbsp;&nbsp;
                    <Grid item className="statusDetailsChange" style={{ cursor: "pointer" }} onClick={(event) => this.handleSingleStatusUpdate("review", [this.state.selectedJobApplicantDetails.id], event)}>
                        {this.renderStatusIcon('review', '#6D97C1', '#9C9C9C', this.state.selectedJobApplicantDetails.attributes.status, "Reviewed")}
                    </Grid>
                    &nbsp;&nbsp;
                    <Grid item className="statusDetailsChange" style={{ cursor: "pointer" }} onClick={(event) => this.handleSingleStatusUpdate("interviewing", [this.state.selectedJobApplicantDetails.id], event)}>
                        {this.renderStatusIcon('interviewing', '#6D97C1', '#9C9C9C', this.state.selectedJobApplicantDetails.attributes.status, "Interviewing")}
                    </Grid>
                    &nbsp;&nbsp;
                    <Grid item style={{ cursor: "pointer" }} className="statusDetailsChangeOne" onClick={() => this.handeleTalentpoolAdd(this.state.selectedJobApplicantDetails.attributes.pipeline_id || this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.id, this.state.selectedJobApplicantDetails.attributes.added_to_pipeline)} >
                        {this.renderStatusIcon('liked', '#6D97C1', '#9C9C9C', handleCondition(this.state.selectedJobApplicantDetails.attributes.added_to_pipeline, "liked", ""), "Talent Pipeline")}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} style={{ ...webStyle.footerContainer, paddingLeft: "0px" }} >
                <Grid item style={{ color: "#6D97C1", fontSize: "14px" }}>
                    <span >Potential Score:</span>&nbsp;&nbsp;<span>{Math.round(this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.total_potential_score)}</span>&nbsp;&nbsp;&nbsp;<span>|</span>&nbsp;&nbsp;&nbsp;<span>Rank:</span>&nbsp;&nbsp;&nbsp;<span>{this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.overall_rank}</span>
                </Grid>
                {this.handleTwoCondition(this.state.selectedJobApplicantDetails.attributes.status,
                <Grid item >
                    <CustomChipComponent type={this.getStatus(this.state.selectedJobApplicantDetails.attributes.status)} showDeleicon={false} title={this.state.selectedJobApplicantDetails.attributes.status.toUpperCase()} />
                </Grid>)
                }            
            </Grid>
        </Grid>
        <Grid item container sm={12} style={webStyle.appCard}>
            <Grid item sm={12}>
                <Typography style={webStyle.cardTitleAPPMain} >
                    Application Details
                </Typography>
            </Grid>
            <Grid item sm={12}>
                <Formik
                    initialValues={{
                        email: this.state.selectedJobApplicantDetails.attributes.email,
                        ContactNumber: getPhoneNumber(this.state.selectedJobApplicantDetails.attributes.contact)
                    }}
                    enableReinitialize
                    onSubmit={() => { }}
                >
                    {({ values, errors, setFieldValue, setFieldError, handleChange }) => (
                        <Form>
                            <Field
                                name="email"
                                component={TextFieldComponent}
                                type="email"
                                variant="outlined"
                                title="Email Address"
                                placeHolder="abc@email.com"
                                disabled={true}
                            />
                            &nbsp;
                            &nbsp;
                            <Field
                                name="ContactNumber"
                                component={TextFieldComponent}
                                type="string"
                                variant="outlined"
                                title="Contact Number"
                                placeHolder="1234567890"
                                disabled={true}
                            />
                            &nbsp;
                            &nbsp;
                            <Grid item sm={12}>
                                <Typography style={{ ...webStyle.cardTitleAPP, paddingBottom: "0px" }} >
                                    Resume
                                </Typography>
                            </Grid>
                            <RadioSelectComponent value={this.state.selectedJobApplicantDetails.attributes.resume} resumeList={[]} index={1} isPreview={true} showCrossIcon={false}/>
                            &nbsp;
                            &nbsp;
                            <Grid item sm={12}>
                                <Typography style={{ ...webStyle.cardTitleAPP, paddingBottom: "0px" }} >
                                    Cover Letter
                                </Typography>
                            </Grid>
                            <RadioSelectComponent value={this.state.selectedJobApplicantDetails.attributes.cover_letter} resumeList={[]} index={1} isPreview={true} showCrossIcon={false}/>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Grid item sm={12}>
                                <Typography style={webStyle.cardTitleAPPMain} >
                                    Additional Details
                                </Typography>
                            </Grid>
                            {
                                this.state.selectedJobApplicantDetails.attributes.answers.map(answer => answer.question.question_type === "Multiple Choice" && <><Grid key={question.id} item sm={12} container>
                                    <Grid sm={12} item>
                                        <Typography>{answer.question.question_text}</Typography>
                                    </Grid>
                                    <Grid item sm={12} style={{ display: "flex", flexDirection: "column" }}>
                                        {
                                            answer.question.options.map((option) => <FormControlLabel
                                                key={answer.question.id}
                                                value={option}
                                                control={<CustomRadio />}
                                                checked={!!answer.answer.split(",").some((value) => value === option)}
                                                label={<Typography style={{
                                                    fontFamily: "Arial",
                                                    fontWeight: "lighter",
                                                    color: "#1F497D",
                                                    fontSize: "12px"
                                                }}>{handleCondition(option === "Other...", answer.answer.split(",")[answer.answer.split(",").length - 1], option)}</Typography>}
                                            />)
                                        }
                                    </Grid>
                                </Grid>
                                    &nbsp;
                                    &nbsp;
                                </>)
                            }

                            {
                                this.state.selectedJobApplicantDetails.attributes.answers.map(answer => answer.question.question_type === "Dropdown" && <><Grid key={answer.id} item sm={12} container>
                                    <Grid item sm={12}>
                                        <Field
                                            name={answer.question.id}
                                            component={CustomSelectDropdownComponent}
                                            type="string"
                                            variant="outlined"
                                            title={answer.question.question_text}
                                            placeHolder="1234567890"
                                            disabled={true}
                                            options={[{ label: answer.answer || "", value: answer.answer || "" }]}
                                            controlledValue={answer.answer}
                                        />
                                    </Grid>
                                </Grid>
                                    &nbsp;
                                    &nbsp;
                                </>)
                            }

                            {
                                this.state.selectedJobApplicantDetails.attributes.answers.map(answer => answer.question.question_type === "Short Answer" && <Grid item key={answer.id} sm={12} container>
                                    <Grid item sm={12}>
                                        <Field
                                            name={answer.question.id}
                                            component={TextFieldComponent}
                                            type="string"
                                            variant="outlined"
                                            title={answer.question.question_text}
                                            placeHolder=""
                                            disabled={true}
                                            controlledValue={answer.answer}
                                        />
                                    </Grid>
                                </Grid>)
                            }
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
        <Grid item container sm={12} style={webStyle.appCard}>
            <Grid item sm={12}>
                <Typography style={webStyle.cardTitleAPPMain} >
                    Profile Details
                </Typography>
            </Grid>
            <Grid item sm={12}>
                <Typography style={webStyle.cardTitleAPP} >
                    Skills
                </Typography>
            </Grid>
            <Grid item container sm={12} spacing={2}>
                {
                    this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.skills.map(item => <Grid item key={item}>
                        <CustomChipComponent type="decline" showDeleicon={false} title={item} />
                    </Grid>)
                }
            </Grid>
            <Divider style={{ width: "100%", height: "1px", margin: "15px 0px 10px 0px" }} />
            <Grid item sm={12}>
                <Typography style={webStyle.cardTitleAPP} >
                    Work Experience
                </Typography>
            </Grid>
            <Grid item container sm={12} >
                <Grid item sm={12} style={{ ...styles.item, position: 'relative' }}>
                    {this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.work_experiences.data.length > 0 ?
                        <TableContainer component={Paper} elevation={0} style={styles.tableWrapper}>
                        <Table style={{ borderCollapse: 'collapse' }} aria-label="simple table">
                            <TableBody>
                                {
                                    this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.work_experiences.data.map((data: WorkExperience, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{ ...styles.tableItem, width: '1%', fontWeight: '400', verticalAlign: 'top', padding: '2px 5px' }}
                                            >
                                                <span className='verticalLine'></span>
                                                {(index % 2) === 0 ?
                                                    <span className="workExperienceColumn" style={{ backgroundColor: '#C7D9F0' }}>{index + 1}</span> :
                                                    <span className="workExperienceColumn">{index + 1}</span>

                                                }
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{ ...styles.tableItem, fontWeight: 'bold', padding: '5px' }}
                                            >
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        height: '45px',
                                                        fontSize: "14px"
                                                    }}
                                                >
                                                    <span>
                                                        {data.attributes.position}
                                                    </span>
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: '12px',
                                                            lineHeight: '18px',
                                                            color: "#17365D",
                                                        }}>
                                                        Specialization Sector: {data.attributes.specialization}
                                                    </span>
                                                </span>
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    ...styles.tableItem,
                                                    fontWeight: '400',
                                                    verticalAlign: 'top',
                                                    padding: '5px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <span style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'start',
                                                    fontSize: "12px",
                                                }}>
                                                    <img src={clockIcon} style={{ marginRight: '5px' }} />
                                                    <span style={{ fontFamily: "Arial" }}>

                                                        {new Date(data.attributes.started_time).toLocaleString('default', { month: 'short' }) + " " + new Date(data.attributes.started_time).getFullYear()} {data.attributes.currently_working ? " till Present" : new Date(data.attributes.ended_time).toLocaleString('default', { month: 'short' }) + " to " + new Date(data.attributes.ended_time).getFullYear()}
                                                    </span>
                                                </span>
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                style={{
                                                    ...styles.tableItem,
                                                    width: "16.67%",
                                                    cursor: "pointer",
                                                    verticalAlign: "top",
                                                    padding: "5px",
                                                    paddingRight: "20px",
                                                    paddingTop: "3px"
                                                }}>
                                                <span style={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    {this.handleTwoCondition(data.attributes.company_icon_url,
                                                     <img
                                                        alt="Edit icon"
                                                        src={data.attributes.company_icon_url}
                                                        style={{ width: '18px', cursor: 'pointer', marginRight: '4px' }}
                                                    />)
                                                    }
                                                   <span
                                                        style={{
                                                            marginRight: '10px',
                                                            marginLeft: '4px',
                                                            fontSize: "14px",
                                                            fontFamily: "Arial"
                                                        }}>
                                                        {data.attributes.company_name}
                                                    </span>
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer> :

                        <div>
                            <Typography style={{ ...styles.noRecord, textAlign: "center" }}>No records found</Typography>
                        </div>}
                </Grid>
            </Grid>
            <Divider style={{ width: "100%", height: "1px", margin: "15px 0px 10px 0px" }} />
            <Grid item sm={12}>
                <Typography style={webStyle.cardTitleAPP} >
                    Deal Experience
                </Typography>
            </Grid>
            <Grid item sm={12} >
                <Grid style={{ ...styles.item, position: 'relative', paddingBottom: '26px' }}>
                    {this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.deal_experiences.data.length > 0 ? 
                    <TableContainer component={Paper} elevation={0} style={styles.tableWrapper}>
                        <Table style={{ borderCollapse: 'collapse' }} aria-label="simple table">
                            <TableHead style={{}}>
                                <TableRow>
                                    <TableCell style={{ ...styles.tableItem, fontWeight: '700', paddingTop: "5px", paddingBottom: "5px" }} >Date</TableCell>
                                    <TableCell style={{ ...styles.tableItem, fontWeight: '700', paddingTop: "5px", paddingBottom: "5px" }}  >Bidder Company</TableCell>
                                    <TableCell style={{ ...styles.tableItem, fontWeight: '700', paddingTop: "5px", paddingBottom: "5px" }}  >Buyer/Lender</TableCell>
                                    <TableCell style={{ ...styles.tableItem, fontWeight: '700', paddingTop: "5px", paddingBottom: "5px" }}  >Enterprise Value ('000s)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.deal_experiences.data.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row" style={{ ...styles.tableItem, fontWeight: '400', paddingTop: "5px", paddingBottom: "5px" }}>
                                        {data.attributes.created_at}
                                        </TableCell>
                                        <TableCell style={{ ...styles.tableItem, fontWeight: '400', paddingTop: "5px", paddingBottom: "5px" }}>{handleCondition(data.attributes.deal_type === "debt", data.attributes.borrower_name,  data.attributes.target_name)}</TableCell>
                                        <TableCell style={{ ...styles.tableItem, fontWeight: '400', paddingTop: "5px", paddingBottom: "5px" }}>{handleCondition(data.attributes.deal_type === "debt", data.attributes.lender_name,  data.attributes.buyer_investor_name)}</TableCell>
                                        <TableCell style={{ ...styles.tableItem, fontWeight: '400', paddingTop: "5px", paddingBottom: "5px" }}>{handleCondition(data.attributes.deal_type === "debt", getCommaSeparatedValue(data.attributes.debt_size), getCommaSeparatedValue(data.attributes.enterprise_value))}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> :
                        <div>
                            <Typography style={{...styles.noRecord, textAlign: "center"}}>No records found</Typography>
                        </div>

                    }
                </Grid>
            </Grid>
        </Grid>
    </>

    renderOtheApplicationTab = (item: Job) => <Grid item container key={item.id} style={webStyleOne.cardWSO} className="list-item-569">
        <Grid item container sm={12} style={webStyleOne.cardBodyWSO}>
            <Grid item sm={12} style={{ ...webStyleOne.flexBetweenCenterWSO, flexWrap: "wrap" }}>
                <span style={webStyleOne.cardTitleWSO}>{item.attributes.job_title}</span>
                <PopoverModal data-test-id="otherJobPopoverModal" handleJobStatusClick={this.handleChangeJobStatus} onView={this.props.handleViewClick} handleDelete={this.props.handleDelete} job={item} handleEditClick={this.props.handleEditClick}>
                    <img alt="icon" src={more} />
                </PopoverModal>
            </Grid>
            <Grid item sm={12} style={{ ...webStyleOne.flexBetweenCenterWSO, flexWrap: "wrap" }}>
                <div style={{ ...webStyleOne.flexBetweenCenterWSO, flexWrap: "wrap" }}>
                    <span style={item.attributes.status !== "close" ? webStyleOne.positionTitleWSO : webStyleOne.positionTitleDisabledWSO}>{item.attributes.department}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div style={{ ...webStyleOne.flexBetweenCenterWSO, flexWrap: "wrap" }}>
                    <span style={ item.attributes.status !== "close" ? webStyleOne.statusTitleWSO: webStyleOne.statusTitleDisabledWSO}>Status: </span>&nbsp;&nbsp;&nbsp;
                    {item.attributes.status === "close" ? <CustomChipComponent title="Closed" type={item.attributes.status} showDeleicon={false} />  : <CustomChipComponent title={item.attributes.status.charAt(0).toUpperCase() + item.attributes.status.slice(1)} type={item.attributes.status} showDeleicon={false} /> }
                </div>
            </Grid>
        </Grid>
        <Grid item sm={12} style={item.attributes.status !== "close" ? webStyleOne.cardFooterWSO : webStyleOne.cardFooterDisabledWSO}>
            <div style={{ ...webStyleOne.flexBetweenCenterWSO, flexWrap: "wrap" }}>
                <span style={webStyleOne.footerTitleWSO}>{item.attributes.candidatesCount} <span style={webStyleOne.footerTitle0WSO}>Candidates</span></span>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <span style={webStyleOne.countTitleWSO}>
                        <img src={sigma} alt="icon" />
                        &nbsp;{item.attributes.candidatesCount}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={webStyleOne.countTitleWSO}>
                        <img src={down} alt="icon" />
                        &nbsp;{item.attributes.job_applications_count.invited}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={webStyleOne.countTitleWSO}>
                        <img src={diamond} alt="icon" />
                        &nbsp;{item.attributes.job_applications_count['applied+']}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={webStyleOne.countTitleWSO}>
                        <img src={shakehand} alt="icon" />
                        &nbsp;{item.attributes.job_applications_count.applied}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={webStyleOne.countTitleWSO}>
                        <img src={dont} alt="icon" />
                        &nbsp;{item.attributes.job_applications_count.rejected}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={webStyleOne.countTitleWSO}>
                        <img src={question} alt="icon" />
                        &nbsp;{item.attributes.job_applications_count.review}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={webStyleOne.countTitleWSO}>
                        <img src={comment} alt="icon" />
                        &nbsp;{item.attributes.job_applications_count.messaged || 0}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={webStyleOne.countTitleWSO}>
                        <img src={right} alt="icon" />
                        &nbsp;{item.attributes.job_applications_count.interviewing}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
            </div>
        </Grid>
    </Grid>

    renderTabComponent = () => {
        return (<>
            {
                this.state.currentJobtab === "OtherApplications" && this.state.allCreatedJobs.filter((item) => item.id !== this.props.jobId).map((item: Job) => {
                    return this.renderOtheApplicationTab(item);
                })
            }
            {
                this.state.currentJobtab === "Application" && !!this.state.selectedJobApplicantDetails?.attributes && this.renderApplicationTab()
            }
        </>)
    }

    renderDetails = () => <>
        <Grid md={5} sm={12} xs={12} item container style={webStyle.left} className="left-container">
            <Grid item container style={(this.state.selectedJobs.length > 0 && (this.state.selectedJobs.length === this.state.totalApplication)) ? webStyle.selected : webStyle.notSelected} >
                <Grid item style={{ flex: 1, }} >
                    <CustomCheckBoxComponent fontSize="16px" title={(this.state.selectedJobs.length > 0 && (this.state.selectedJobs.length === this.state.totalApplication)) ? "selected" : `${this.state.selectedJobs.length} selected`} checkedColorBg="#FFFFFF" onChange={() => this.handleSelectAll()} checked={(this.state.selectedJobs.length > 0 && (this.state.selectedJobs.length === this.state.totalApplication))} isPrimary={true} />
                </Grid>
                <Grid item>
                    {
                        this.state.selectedJobs.length > 0 ? <PopoverModal styleOfIcon={{ paddingLeft: "0px" }} job={{}} handleoptionClick={this.handleSelectionChange} options={["Rejected", "Review", "Messaged", "Interviewing"]}>
                            {
                                this.state.selectedJobs.length === this.state.totalApplication ? <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.1984 11.2492C13.3088 11.249 13.3992 11.3385 13.3992 11.449V16.0096C13.3992 16.1856 13.6102 16.2758 13.7374 16.1542L21.6466 8.59527C21.7295 8.51601 21.7289 8.38339 21.6453 8.30487L13.7361 0.879284C13.6084 0.759394 13.3992 0.849935 13.3992 1.02509V5.14259C13.3992 5.25305 13.306 5.34246 13.1956 5.34405C5.00285 5.46214 1.64053 12.6947 0.592086 15.7165C0.516525 15.9343 0.798174 16.0518 0.931444 15.8637C3.8589 11.7321 8.02583 11.2606 13.1984 11.2492Z" fill="white" />
                                </svg> : <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.1984 11.2492C13.3088 11.249 13.3992 11.3385 13.3992 11.449V16.0096C13.3992 16.1856 13.6102 16.2758 13.7374 16.1542L21.6466 8.59527C21.7295 8.51601 21.7289 8.38339 21.6453 8.30487L13.7361 0.879284C13.6084 0.759394 13.3992 0.849935 13.3992 1.02509V5.14259C13.3992 5.25305 13.306 5.34246 13.1956 5.34405C5.00285 5.46214 1.64053 12.6947 0.592086 15.7165C0.516525 15.9343 0.798174 16.0518 0.931444 15.8637C3.8589 11.7321 8.02583 11.2606 13.1984 11.2492Z" fill="#9C9C9C" />
                                </svg>
                            }
                        </PopoverModal> : <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.1984 11.2492C13.3088 11.249 13.3992 11.3385 13.3992 11.449V16.0096C13.3992 16.1856 13.6102 16.2758 13.7374 16.1542L21.6466 8.59527C21.7295 8.51601 21.7289 8.38339 21.6453 8.30487L13.7361 0.879284C13.6084 0.759394 13.3992 0.849935 13.3992 1.02509V5.14259C13.3992 5.25305 13.306 5.34246 13.1956 5.34405C5.00285 5.46214 1.64053 12.6947 0.592086 15.7165C0.516525 15.9343 0.798174 16.0518 0.931444 15.8637C3.8589 11.7321 8.02583 11.2606 13.1984 11.2492Z" fill="#9C9C9C" />
                        </svg>

                    }
                </Grid>
            </Grid>
            &nbsp;
            &nbsp;
            <Grid item style={{ display: "flex" }}>
                <PopoverModal styleOfIcon={{ paddingLeft: "0px" }} job={{}} handleoptionClick={this.handleSortChange} options={["Newest", "Oldest", "Relevance", "Potential Score", "Rank"]}>
                    <Box style={webStyle.sortByContainer}>
                        <Typography style={webStyle.sortBytitle}>Sort by : {this.state.sortBy}</Typography>
                        &nbsp;
                        &nbsp;
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.12655 1.00002L6.00655 4.88002L9.88655 1.00002C10.2765 0.61002 10.9065 0.61002 11.2965 1.00002C11.6865 1.39002 11.6865 2.02002 11.2965 2.41002L6.70655 7.00002C6.31655 7.39002 5.68655 7.39002 5.29655 7.00002L0.706548 2.41002C0.519295 2.22319 0.414062 1.96954 0.414062 1.70502C0.414062 1.4405 0.519295 1.18685 0.706548 1.00002C1.09655 0.62002 1.73655 0.61002 2.12655 1.00002Z" fill="#1F497D" />
                        </svg>
                    </Box>
                </PopoverModal>
            </Grid>
            <Grid item container sm={12} style={webStyle.cardContainer as CSSProperties} id="appliedCandidatesBystatus">
                {
                    this.state.jobs.map((item: AppliedJob, index) => {
                        return (<Grid item key={item.id} container sm={12} style={{
                            ...webStyle.card,
                            filter: this.checkForBlur(item.id) ? "blur(4px)" : "none",
                            ...(item.id === this.state.selectedJobApplicantDetails.id && webStyle.cardSelected)
                          }} 
                            className="list-item-0705" onClick={(event) => this.setCurrentSelectedApplicant(item, event, index)}>
                            <Grid item container style={webStyle.center}>
                                <Grid item >
                                    <CustomCheckBoxComponent title="" checked={this.state.selectedJobs.includes(item.id)} onChange={(event) => this.handleSelectApplication(item.id, event)} />
                                </Grid>
                                <Grid item style={webStyle.avtarContainer}>
                                    <Avatar src={item.attributes.candidate_profile.data.attributes.photo} style={webStyle.avatarGrey}></Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography style={webStyle.cardname}>{item.attributes.candidate_profile.data.attributes.full_name}</Typography>
                                    <Typography style={webStyle.cardDetails}>{item.attributes.candidate_profile.data.attributes.company_indusry}</Typography>
                                    <Typography style={webStyle.cardDetails}>{item.attributes.candidate_profile.data.attributes.city + ", " + item.attributes.candidate_profile.data.attributes.country}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} style={webStyle.footerContainer} >
                                <Grid item style={{ color: "#6D97C1", fontSize: "14px" }}>
                                    <span >Potential Score:</span>&nbsp;&nbsp;<span>{Math.round(item.attributes.candidate_profile.data.attributes.total_potential_score)}</span>&nbsp;&nbsp;&nbsp;<span>|</span>&nbsp;&nbsp;&nbsp;<span>Rank:</span>&nbsp;&nbsp;&nbsp;<span>{item.attributes.candidate_profile.data.attributes.overall_rank}</span>
                                </Grid>
                                {
                                   this.handleTwoCondition( item?.attributes?.status,
                                    <Grid item >
                                        <CustomChipComponent type={this.getStatus(item?.attributes?.status || "")} showDeleicon={false} title={item.attributes.status.toUpperCase()} />
                                    </Grid>)
                                }
                            </Grid>
                            <Grid item sm={12} style={webStyle.appliedTest}>
                                <Typography style={{ fontFamily: "Arial", color: "#9C9C9C", fontSize: "12px", fontWeight: "lighter" }}>{item.attributes.created_at}</Typography>
                            </Grid>
                            <Grid item sm={12} style={webStyle.footerContainer} >
                                <Grid item container style={webStyle.buttonsContainer}>
                                    <Grid item className="statusIcon" style={{ cursor: "pointer" }} onClick={(event) => this.handleSingleStatusUpdate("rejected", [item.id], event)}>
                                        {this.renderStatusIcon('rejected', '#6D97C1', '#9C9C9C', item.attributes.status, "Rejected")}
                                    </Grid>
                                    &nbsp;&nbsp;
                                    <Grid item className="statusIcon" style={{ cursor: "pointer" }} onClick={(event) => this.handleSingleStatusUpdate("messaged", [item.id], event)}>
                                        {this.renderStatusIcon('messaged', '#6D97C1', '#9C9C9C', item.attributes.status, "Messaged")}
                                    </Grid>
                                    &nbsp;&nbsp;
                                    <Grid item className="statusIcon" style={{ cursor: "pointer" }} onClick={(event) => this.handleSingleStatusUpdate("review", [item.id], event)}>
                                        {this.renderStatusIcon('review', '#6D97C1', '#9C9C9C', item.attributes.status, "Reviewed")}
                                    </Grid>
                                    &nbsp;&nbsp;
                                    <Grid item className="statusIcon" style={{ cursor: "pointer" }} onClick={(event) => this.handleSingleStatusUpdate("interviewing", [item.id], event)}>
                                        {this.renderStatusIcon('interviewing', '#6D97C1', '#9C9C9C', item.attributes.status, "Interviewing")}
                                    </Grid>
                                </Grid>
                                <Grid item id="handleChangeTalentpoolStatus" onClick={() => this.handeleTalentpoolAdd(item.attributes.pipeline_id || item.attributes.candidate_profile.data.id, item.attributes.added_to_pipeline)}>
                                    {this.renderStatusIcon('liked', '#6D97C1', '#9C9C9C', handleCondition(item.attributes.added_to_pipeline, "liked", ""), "Talent Pipeline")}
                                </Grid>
                            </Grid>
                        </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
        <Grid md={7} sm={12} xs={12} item className="right-container" container style={webStyle.rightContainer as CSSProperties}>
            <Grid style={webStyle.right as CSSProperties}>
                <Grid item sm={12} container >
                    <Tabs
                        value={this.state.currentJobtab}
                        onChange={this.handleApplicationChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        className="app-tab"
                    >
                        <Tab
                            className="app-tab-main"
                            label={<Typography style={(this.state.currentJobtab === "Application" ? webStyle.tabTitleSelectedTab : webStyle.tabTitleTab) as CSSProperties}>Application</Typography>}
                            value="Application"
                        />
                        <Tab
                            className="app-tab-main"
                            label={<Typography style={(this.state.currentJobtab === "OtherApplications" ? webStyle.tabTitleSelectedTab : webStyle.tabTitleTab) as CSSProperties}>Other Applications</Typography>}
                            value="OtherApplications"
                        />
                    </Tabs>
                    <Divider style={{ height: "1px", width: "100%" }}></Divider>
                </Grid>
                <Grid item container sm={12} className="cardContainer" id="rootContainerScroller" style={webStyleOne.cardContainer as CSSProperties}>
                    {
                        this.renderTabComponent()
                    }
                </Grid>
            </Grid>

        </Grid>
    </>
    renderTalentPool = () => <Grid sm={12} style={webStyle.cardsOfTalentpool as CSSProperties}>
        <Grid sm={12} style={webStyle.candidateCountsContainer}>
            <Typography style={webStyle.titleCandStyle} >{this.state.talentPoolCandidates.length} Candidates</Typography>
        </Grid>
        {
            this.state.talentPoolCandidates.map((item: TalentPoolCandidate) => <Grid className="talentpool-card-user" key={item.id} item container sm={12} style={webStyle.appCard11}>
                <Grid item container style={{ ...webStyle.spaceBetween, paddingLeft: "5px" }}>
                    <Grid item style={{ ...webStyle.center, ...webStyle.flexONE }}>
                        <Grid item style={webStyle.avtarContainer}>
                            <Avatar src={item.attributes.candidate_detail.data.attributes.photo} style={webStyle.avatarBlue}></Avatar>
                        </Grid>
                        <Grid item>
                            <Typography style={webStyle.cardname}>{item.attributes.candidate_detail.data.attributes.full_name}</Typography>
                            <Typography style={webStyle.cardDetails}>{item.attributes.candidate_detail.data.attributes.company_industry}</Typography>
                            <Typography style={webStyle.cardDetails}>{item.attributes.candidate_detail.data.attributes.city + ", " + item.attributes.candidate_detail.data.attributes.country}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{ ...webStyle.buttonsContainer, ...webStyle.flexONE, ...webStyle.flexEND }}>
                        <Grid item className="statusDetailsChange">
                            {this.renderStatusIcon('rejected', '#6D97C1', '#9C9C9C', "", "Rejected")}
                        </Grid>
                        &nbsp;&nbsp;
                        <Grid item className="statusDetailsChange">
                            {this.renderStatusIcon('messaged', '#6D97C1', '#9C9C9C', "", "Messaged")}
                        </Grid>
                        &nbsp;&nbsp;
                        <Grid item className="statusDetailsChange">
                            {this.renderStatusIcon('review', '#6D97C1', '#9C9C9C', "", "Reviewed")}
                        </Grid>
                        &nbsp;&nbsp;
                        <Grid item className="statusDetailsChange">
                            {this.renderStatusIcon('interviewing', '#6D97C1', '#9C9C9C', "", "Interviewing")}
                        </Grid>
                        &nbsp;&nbsp;
                        <Grid item id="handleChangeTalentpoolStatus0" style={{ cursor: "pointer" }} onClick={() => this.handeleTalentpoolAdd(item.id, true)}>
                            {this.renderStatusIcon('liked', '#6D97C1', '#9C9C9C', "liked", "Talent Pool")}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} style={{ ...webStyle.footerContainer, paddingLeft: "0px", paddingTop: "10px" }} >
                    <Grid item style={{ color: "#6D97C1", fontSize: "14px" }}>
                        <span >Potential Score:</span>&nbsp;&nbsp;<span>{Math.round(item.attributes.candidate_detail.data.attributes.total_potential_score)}</span>&nbsp;&nbsp;&nbsp;<span>|</span>&nbsp;&nbsp;&nbsp;<span>Rank:</span>&nbsp;&nbsp;&nbsp;<span>{item.attributes.candidate_detail.data.attributes.overall_rank}</span>
                    </Grid>
                    <Grid item >
                        <></>
                    </Grid>
                </Grid>
                <Grid item sm={12} style={webStyle.appliedTest0}>
                    <Typography data-test-id="appliedTime" style={{ fontFamily: "Arial", color: "#9C9C9C", fontSize: "12px", fontWeight: "lighter" }}>{this.renderAppliedTime(item.attributes.candidate_detail.data.attributes)}</Typography>
                </Grid>
            </Grid>)
        }
    </Grid>

    renderSubscriptionDialog = () => {
        return (
            <JobPostSubscriptionDialog
                data-test-id="JobPostSubscriptionDialog"
                isModalOpen={this.state.showSubscriptionDialog}
                handleModalClose={this.closeSubscriptionDialog}
                handleShowPlans={this.navigationToSubscription}
            />
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <Grid container style={webStyle.root} spacing={3}>
                <style>{configJSON.globalCSS}</style>
                <Grid item container  >
                    <Grid item container sm={12} style={{ ...webStyle.header } as CSSProperties}>
                        <img src={bgRightTop} style={webStyle.img as CSSProperties} />
                        <Grid item container sm={12} style={webStyle.spaceBetween}>
                            <Grid sm={6} style={webStyle.center}>
                                <IconButton onClick={this.props.handleJobDetailListingClose} style={webStyle.padTopZero}>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.99953 9.87508L3.11953 5.99508L6.99953 2.11508C7.38953 1.72508 7.38953 1.09508 6.99953 0.705083C6.8127 0.517831 6.55905 0.412598 6.29453 0.412598C6.03001 0.412598 5.77636 0.517831 5.58953 0.705083L0.999531 5.29508C0.609531 5.68508 0.609531 6.31508 0.999531 6.70508L5.58953 11.2951C5.97953 11.6851 6.60953 11.6851 6.99953 11.2951C7.37953 10.9051 7.38953 10.2651 6.99953 9.87508Z" fill="#9C9C9C" />
                                    </svg>
                                </IconButton>
                                    <Box>
                                        <Typography style={webStyle.titleJob}>
                                            <BootstrapTooltip title={this.state.jobDetails.attributes.job_title.length >= 30 ? this.state.jobDetails.attributes.job_title : ""}>
                                                {
                                                    <span>
                                                        {TruncateWithTooltip(this.state.jobDetails.attributes.job_title, 30)}
                                                    </span>
                                                }
                                            </BootstrapTooltip>
                                        </Typography>
                                        <Typography style={webStyle.jobType}>
                                            <BootstrapTooltip title={this.state.jobDetails.attributes.job_title.length >= 30 ? this.state.jobDetails.attributes.job_title : ""}>
                                                <span>
                                                    {TruncateWithTooltip(this.state.jobDetails.attributes.department, 30)}
                                                </span>
                                            </BootstrapTooltip>
                                        </Typography>
                                    </Box>
                            </Grid>
                            <Grid sm={6} style={webStyle.cardheader}>
                                <Button style={webStyle.cardButton}>
                                    <CustomChipComponent type={this.state.jobDetails.attributes.status} showDeleicon={false} title={this.state.jobDetails.attributes.status[0].toUpperCase() + this.state.jobDetails.attributes.status.substring(1)} />
                                </Button>
                                <Button style={webStyle.cardButton} id="openFilterModalBTN" onClick={this.handleFilterModal}>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" fill="white" />
                                        <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" stroke="#F4F4F4" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1633 20.7177C10.5033 20.3502 10.1058 19.6827 10.1058 18.9327V14.9577C10.1058 14.5827 9.85828 14.0202 9.62578 13.7352L6.82078 10.7652C6.34828 10.2927 5.98828 9.4827 5.98828 8.8752V7.1502C5.98828 5.9502 6.89578 5.0127 8.05078 5.0127H17.9508C19.0908 5.0127 20.0133 5.9352 20.0133 7.0752V8.7252C20.0133 9.5127 19.5408 10.4052 19.0983 10.8477L15.8508 13.7202C15.5358 13.9827 15.2883 14.5602 15.2883 15.0252V18.2502C15.2883 18.9177 14.8683 19.6902 14.3433 20.0052L13.3083 20.6727C12.9708 20.8827 12.5883 20.9877 12.2058 20.9877C11.8458 20.9877 11.4933 20.8977 11.1633 20.7177ZM7.11328 7.1502V8.8752C7.11328 9.1527 7.33828 9.6927 7.62328 9.9777L10.4808 12.9852C10.8633 13.4577 11.2383 14.2452 11.2383 14.9502V18.9252C11.2383 19.4127 11.5758 19.6527 11.7183 19.7277C12.0333 19.9002 12.4158 19.9002 12.7083 19.7202L13.7508 19.0527C13.9608 18.9252 14.1708 18.5202 14.1708 18.2502V15.0252C14.1708 14.2227 14.5608 13.3377 15.1233 12.8652L18.3333 10.0227C18.5883 9.7677 18.8958 9.1602 18.8958 8.7177V7.0752C18.8958 6.5577 18.4758 6.1377 17.9583 6.1377H8.05078C7.52578 6.1377 7.11328 6.5802 7.11328 7.1502Z" fill="#1F497D" />
                                    </svg>
                                </Button>
                                <PopoverModal data-test-id="popoverModal2" handleJobStatusClick={this.handleChangeJobStatus} styleOfIcon={{ paddingLeft: "0px" }} onView={this.props.handleViewClick} handleDelete={this.props.handleDelete} job={this.state.jobDetails} handleEditClick={this.props.handleEditClick}>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" fill="white" />
                                        <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" stroke="#F4F4F4" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 11.5C7.675 11.5 7 12.175 7 13C7 13.825 7.675 14.5 8.5 14.5C9.325 14.5 10 13.825 10 13C10 12.175 9.325 11.5 8.5 11.5ZM17.5 11.5C16.675 11.5 16 12.175 16 13C16 13.825 16.675 14.5 17.5 14.5C18.325 14.5 19 13.825 19 13C19 12.175 18.325 11.5 17.5 11.5ZM11.5 13C11.5 12.175 12.175 11.5 13 11.5C13.825 11.5 14.5 12.175 14.5 13C14.5 13.825 13.825 14.5 13 14.5C12.175 14.5 11.5 13.825 11.5 13Z" fill="#6D97C1" />
                                    </svg>
                                </PopoverModal>
                            </Grid>
                        </Grid>
                        <Grid item container sm={12} style={webStyle.justSpacebetween as CSSProperties}>
                            <Grid item style={{ flexGrow: "1", width: "100%" }} sm={10}>
                                <div style={{ position: "static" } as CSSProperties} color="default">
                                    <Tabs
                                        value={this.state.currentTab}
                                        onChange={this.handleChangeTab}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                    >
                                        {this.renderTab('All', sigma1, sigma0, `All (${this.state.job_applicants_count})`)}
                                        {this.renderTab('Invited', down1, down0, `Invited (${this.state.job_counts_as_per_statuses.invited})`)}
                                        {this.renderTab('Applied+', applied1, applied0, `Applied + (${this.state.job_counts_as_per_statuses["applied+"]})`)}
                                        {this.renderTab('Applied', hand1, hand0, `Applied (${this.state.job_counts_as_per_statuses.applied})`)}
                                        {this.renderTab('Rejected', reject1, reject0, `Rejected (${this.state.job_counts_as_per_statuses.rejected})`)}
                                        {this.renderTab('Review', question1, question0, `Review (${this.state.job_counts_as_per_statuses.review})`)}
                                        {this.renderTab('Messaged', chat1, chat0, `Messaged (${this.state.job_counts_as_per_statuses.messaged || 0})`)}
                                        {this.renderTab('Interviewing', dright1, dright0, `Interviewing (${this.state.job_counts_as_per_statuses.interviewing})`)}
                                    </Tabs>
                                </div>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Box sx={webStyle.bodTab} id="openTalentPool" onClick={() => this.handleTalentPool("TalentPool")}>
                                        {this.state.currentTab !== "TalentPool" ? <img src={love0} />
                                            : <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 18.3249C9.76667 18.3249 9.52917 18.2832 9.2875 18.1999C9.04583 18.1166 8.83333 17.9832 8.65 17.7999L6.925 16.2249C5.15833 14.6082 3.5625 13.0041 2.1375 11.4124C0.7125 9.82074 0 8.06657 0 6.1499C0 4.58324 0.525 3.2749 1.575 2.2249C2.625 1.1749 3.93333 0.649902 5.5 0.649902C6.38333 0.649902 7.21667 0.837402 8 1.2124C8.78333 1.5874 9.45 2.0999 10 2.7499C10.55 2.0999 11.2167 1.5874 12 1.2124C12.7833 0.837402 13.6167 0.649902 14.5 0.649902C16.0667 0.649902 17.375 1.1749 18.425 2.2249C19.475 3.2749 20 4.58324 20 6.1499C20 8.06657 19.2917 9.8249 17.875 11.4249C16.4583 13.0249 14.85 14.6332 13.05 16.2499L11.35 17.7999C11.1667 17.9832 10.9542 18.1166 10.7125 18.1999C10.4708 18.2832 10.2333 18.3249 10 18.3249Z" fill="#6D97C1" />
                                            </svg>}
                                        <Typography style={(this.state.currentTab !== "TalentPool" ? webStyle.tabTitle : webStyle.tabTitleSelected) as CSSProperties}>Talent Pipeline</Typography>
                                    </Box>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.currentTab !== "TalentPool" ? this.renderDetails() : this.renderTalentPool() }
                <FilterModal isTalentPoolFilter={this.state.currentTab === "TalentPool"} getAllCandidatesTalenPool={this.getAllCandidatesTalenPool} handleClearFiler={this.handleClearFiler} getAllJobsForDetails={this.getAllJobsForDetails} skillsListHandler={this.skillsListHandler} filteredSlillsOption={this.state.filteredSlillsOption} onChangeFilterModalsValue={this.onChangeFilterModalsValue} tempCity={this.state.tempCity} tempCountry={this.state.tempCountry} tempSkill={this.state.tempSkill} selectedSkills={this.state.selectedSkills} selectedCity={this.state.selectedCity} selectedCountry={this.state.selectedCountry} selectedExperience={this.state.selectedExperience} countries={this.props.countries} cities={this.props.cities} open={this.state.filterModal} onClose={this.handleFilterModal} handleCountryChange={this.props.handleCountryChange} />
            </Grid >
            {this.renderSubscriptionDialog()}</>
            // Customizable Area End
        );
    }
}

export default JobListing;

// Customizable Area Start
const webStyle = {
    cardsOfTalentpool: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center"
    },
    candidateCountsContainer: {
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#17365D",
        maxWidth: "805px",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%"
    },
    titleCandStyle: {
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: "bold",
    },
    root: {
        background: "#F3F4F8",
        height: "auto",
        maxWidth: "1280px",
        border: "1px solid rgb(244, 244, 244)",
        padding: "10px 20px",
    },
    header: {
        position: "relative",
        borderRadius: "16px",
        backgroundColor: "#FFFFFF",
        padding: "0px 10px",
        minHeight: "127px",
        margin: "0px 0px 0px 0px",
    },
    img: {
        position: "absolute",
        top: "0",
        right: "0",
    },
    left: {
        paddingLeft: "30px",
        alignContent: "baseline"
    },
    rightContainer: {
        paddingRight: "30px",
        alignContent: "baseline",
        width: "100%",
    },
    right: {
        background: "#FFFFFF",
        borderRadius: "16px",
        display: "flex",
        height: "fit-content",
        marginBottom: "20px",
        flexDirection: "column",
        justifyContent: "start",
        padding: "10px",
        alignContent: "baseline",
        width: "100%",
    },
    tabTitle: {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#9C9C9C",
        textTransform: "none",
        paddingLeft: "5px",
        cursor: "pointer"
    },
    tabTitleSelected: {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#1F497D",
        textTransform: "none",
        paddingLeft: "5px",
        cursor: "pointer"
    },
    bodTab: {
        display: "flex",
        alignItems: "center",
    },
    justSpacebetween: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: "5px",
        width: "inherit",
        flexWrap: "wrap-reverse",
        marginBottom: "-10px"
    },
    notSelected: {
        background: "#FFFFFF",
        padding: "3px 12px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
        alignSelf: "baseline"
    },
    selected: {
        background: "#6D97C1",
        padding: "3px 12px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
        alignSelf: "baseline"
    },
    spaceBetween: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    center: { display: "flex", justifyContent: "start", alignItems: "center" },
    titleJob: {
        fontSize: "24px",
        fontFamily: "Arial",
        fontWeight: "bold",
        color: "#17365D",
    },
    jobType: {
        fontSize: "12px",
        fontFamily: "Arial",
        fontWeight: "lighter",
        color: "#9C9C9C",
    },
    cardheader: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        color: "",
    },
    sortBytitle: {
        fontFamily: "Arial",
        color: "#1F497D",
        fontSize: "14px",
        fontWeight: "lighter",
    },
    cardButton: { padding: "0px", minWidth: "42px" },
    sortByContainer: {
        background: "#FFFFFF",
        height: "100%",
        padding: "0px 12px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    cardContainer: { display: "flex", flexDirection: "row", marginTop: "20px", maxHeight: "80vh", overflowY: "auto" },
    card: {
        background: "#FFFFFF",
        padding: "18px 12px",
        borderRadius: "16px",
        display: "flex",
        height: "auto",
        marginBottom: "20px",
        cursor: "pointer"
    },
    cardSelected: {
        background: "#FFFFFF",
        padding: "18px 12px",
        borderRadius: "16px",
        display: "flex",
        height: "auto",
        marginBottom: "20px",
        cursor: "pointer",
        border: "1px solid #1F497D"
    },
    avtarContainer: { marginLeft: "-10px", paddingRight: "16px" },
    cardname: {
        fontFamily: "Arial",
        color: "#0F172A",
        textDecoration: "underline",
        textDecorationColor: "#0F172A",
        fontSize: "14px",
        fontWeight: "bold",
    },
    cardDetails: {
        fontFamily: "Arial",
        color: "#9C9C9C",
        fontSize: "14px",
        fontWeight: "lighter",
    },
    footerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "30px",
        width: "100%",
    },
    appliedTest: { padding: "5px 5px 5px 30px", width: "100%" },
    appliedTest0: { padding: "5px 5px 5px 0px", width: "100%" },
    buttonsContainer: { color: "#6D97C1", fontSize: "14px", display: "flex" },
    tabTitleSelectedTab: {
        fontFamily: "Arial",
        color: "#17365D",
        fontSize: "16px",
        fontWeight: "bold",
        textTransform: "none",
    },
    tabTitleTab: {
        fontFamily: "Arial",
        color: "#9C9C9C",
        fontSize: "16px",
        fontWeight: "lighter",
        textTransform: "none",
    },
    appCard: {
        background: "#FFFFFF",
        padding: "12px 12px",
        borderRadius: "16px",
        display: "flex",
        height: "auto",
        margin: "10px 0px 0px 0px",
        border: "1px solid #F4F4F4"
    },
    appCard11: {
        background: "#FFFFFF",
        padding: "12px 12px",
        borderRadius: "16px",
        display: "flex",
        height: "auto",
        margin: "10px 0px 0px 0px",
        border: "1px solid #F4F4F4",
        maxWidth: "830px"
    },
    appCardHeaderTop: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    flexONE: {
        flex: 1
    },
    flexEND: {
        justifyContent: "end"
    },
    cardTitleAPP: {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#17365D",
        paddingBottom: "12px"
    },
    cardTitleAPPMain: {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#6D97C1",
        paddingBottom: "12px"
    },
    padTopZero: {
        marginBottom: "13px"
    },
    avatarBlue: {
        border: "2px solid #6D97C1"
    },
    avatarGrey: {
        border: "2px solid #F4F4F4"
    }
};

const webStyleOne = {
    cardContainer: {
        padding: "0px",
        maxHeight: "80vh",
        overflowY: "auto",
        marginTop: "5px",
        paddingRight: "10px"
    },
    cardWSO: {
        background: "#FFFFFF",
        borderRadius: "16px",
        margin: "10px 0px",
        border: "1px solid #F4F4F4"
    },
    cardBodyWSO: {
        padding: "17px",
    },
    cardFooterWSO: {
        padding: "10px 17px",
        background: "#1F497D",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px"
    },
    cardFooterDisabledWSO: {
        padding: "10px 17px",
        background: "#9C9C9C",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",
    },
    flexBetweenCenterWSO: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "14px"
    },
    cardTitleWSO: {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#17365D",
        textDecoration: "underline",
        textDecorationColor: "#17365D",
        textDecorationThickness: "2px"
    },
    cardTitleDisabledWSO: {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#9C9C9C",
        textDecoration: "underline",
        textDecorationColor: "#9C9C9C",
        textDecorationThickness: "2px"
    },
    positionTitleWSO: {
        fontFamily: "Arial",
        fontWeight: "lighter",
        fontSize: "14px",
        color: "#1F497D",
    },
    positionTitleDisabledWSO: {
        fontFamily: "Arial",
        fontWeight: "lighter",
        fontSize: "14px",
        color: "#9C9C9C",
    },
    statusTitleWSO: {
        fontFamily: "Arial",
        fontWeight: "lighter",
        fontSize: "14px",
        color: "#17365D",
    },
    statusTitleDisabledWSO: {
        fontFamily: "Arial",
        fontWeight: "lighter",
        fontSize: "14px",
        color: "#9C9C9C",
    },
    footerTitleWSO: {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#EEECE1",
    },
    footerTitle0WSO: {
        fontFamily: "Arial",
        fontWeight: "lighter",
        fontSize: "14px",
        color: "#EEECE1",
    },
    countTitleWSO: {
        fontFamily: "Arial",
        fontWeight: "lighter",
        fontSize: "14px",
        color: "#EEECE1",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
};
const styles = {
    item: {
        background: "#ffffff",
        borderRadius: '20px',
    },
    bottomRight: {
        position: "absolute",
        bottom: "0px",
        right: "0px"
    },
    topRight: {
        position: "absolute" as "absolute",
        top: "0px",
        right: "0px"
    },
    tableWrapper: {
        overflow: 'auto',
    },
    icon: {
        display: 'none',
    },
    tableItem: {
        border: 'none',
        color: '#1F497D',
        fontFamily: 'Arial',
        fontSize: '14px'
    },
    noRecord: {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "12px",
        color: "rgb(23, 54, 93)",
        paddingBottom: "12px"
    }
}

// Customizable Area End
