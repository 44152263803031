import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ClickAwayListener, Grow, Popper, MenuItem, MenuList, Button, Typography } from "@material-ui/core";
import { openMenuicon, selectedRighticon } from "../assets";
import CustomizedRadios from "./CustomizedRadio";
import { StateOfCatelogue } from "../CatalogueController1";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popover: {
            "borderRadius": "16px",
            "padding": "15px 20px",
            "border": "2px solid #CBD5E1",
            "background": "#FFF",
        },
        btn: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "30px",
            background: "#C7D9F0",
            padding: "10px 16px",
            fontWeight: 400,
            '&:hover': {
                backgroundColor: '#C7D9F0',
            },
            "textTransform": "none",
            "color": "rgba(31, 73, 125, 1) ",
            "textAlign": "right",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "lineHeight": "20px",
        },
        
        menuitem: {
            "padding": "10px"
        },
        opttitleSelected: {
            "paddingRight": "60px",
            "lineHeight": "18px",
            "color": "#17365D",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
        },
        opttitle: {
            "paddingRight": "60px",
            "color": "#17365D",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "initial",
            "lineHeight": "18px"
        },
       
    })
);

type Option = {
    id: number | string;
    title: string;
};

type Props = {
    fieldName: string;
    options: Array<Option>;
    countries?: { label: string, value: string, isoCode: string }[],
    city?: { label: string, value: string }[],
    cityListHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    countryListHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    locationRadioButtonHandler?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void,
    handleCountryChange: (value: string, isoCode: string | null | undefined) => void,
    handleCityChange: (value: string) => void,
    state: StateOfCatelogue
};

export default function CustomSelect(props: Props) {
    const { fieldName, handleCityChange, handleCountryChange, locationRadioButtonHandler, countries, city, countryListHandler, cityListHandler, state } = props;

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };



    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div style={{ marginRight: "10px" }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={classes.btn }
            >
                {fieldName} <img src={openMenuicon} alt="open" style={{ paddingLeft: "10px" }} />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ marginTop: "10px", background: "white", zIndex: 1000000, borderRadius: "16px" }}
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "top right",
                        }}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <div className={classes.popover}>
                                <CustomizedRadios
                                    state={state}
                                    handleCountryChange={handleCountryChange}
                                    handleCityChange={handleCityChange}
                                    locationRadioButtonHandler={locationRadioButtonHandler} countries={countries} city={city} countryListHandler={countryListHandler} cityListHandler={cityListHandler} />
                            </div>
                        </ClickAwayListener>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
