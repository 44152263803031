import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { Autocomplete } from '@material-ui/lab';
import { Country } from 'country-state-city';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clearIndicator: {
            display: 'none'
        },
        root: {
            display: 'flex',
            alignItems: 'center',
            width: "100%",
            borderRadius: '20px',
            border: '1px solid #CBD5E1',
            background: '#FFF',
            padding: '0px',
            fontFamily: 'Arial',
            boxShadow: '0px 4px 4px 0px rgba(24, 57, 98, 0.10)',
            height: '41px',
            "& .MuiAutocomplete-root": {
                width: "100%",
                fontSize: '14px',
            },
        },
        input: {
            flex: 1,
            '& .MuiInputBase-input': {
                padding: '0px',
                paddingTop: '5px',
                fontSize: '14px',
            },
            fontSize: '14px',
            color: '#6D97C1',
            fontWeight: "bold"
        },
        iconButton: {
            color: "#1F497D",
            fontFamily: "Arial",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "initial",
            paddingRight: "25px"
        },
        "& .MuiAutocomplete-listbox": {
            color: "#1F497D",
            fontFamily: "Arial",
            fontSize: "14px",
        },
        option: {
            color: "#1F497D",
            fontFamily: "Arial",
            fontSize: "14px",
            "&[data-focus='true']": {
                backgroundColor: "rgba(31, 73, 125, 0.1)",
            },
            "&[aria-selected='true']": {
                backgroundColor: "rgba(31, 73, 125, 0.2)",
            }
        }
    }),
);

type Props = {
    placeHolder: string,
    field: string,
    icon: string,
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    name?: string,
    options: { name: string, value: string, type: string, label: string }[]
    handleChangeValue: (value: string) => void
    value:string
};


export default function AutoCompleteSeacrh(props: Props) {
    const classes = useStyles();
    const { placeHolder, field, icon, onChangeHandler = () => { }, name, options, handleChangeValue, value = "" } = props;

    return (
        <Box className={classes.root}>
            <IconButton className={classes.iconButton} style={{cursor: "auto"}} aria-label="menu">
                {field}
            </IconButton>
            <Autocomplete
                options={options}
                classes={{
                    option: classes.option,
                    clearIndicator: classes.clearIndicator
                }}
                value={options.find(option => option.name.toLocaleLowerCase() === value.toLocaleLowerCase()) || null}
                getOptionLabel={(option) => option?.name || ''}
                renderOption={(option) => <span style={{ display: "flex" , justifyContent: "space-between", flexDirection: "column"}}>
                    <span style={{fontSize: "14px"}}>{option?.name || ""}</span>
                    <span style={{fontSize: "12px"}}>{option?.type || ""}</span>
                </span>
                }
                onChange={(event, value) => onChangeHandler({ target: { value: value?.name || "", name: name || "location" } } as unknown as React.ChangeEvent<HTMLInputElement>)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={(event) => {
                            handleChangeValue(event.target.value)
                        }}
                        value={value}
                        className={classes.input}
                        placeholder={placeHolder}
                        name={name}
                        variant="standard"
                        InputProps={{ ...params.InputProps, disableUnderline: true, style: { color: '#6D97C1', fontWeight: "lighter" } }}
                    />
                )}
            />
            <IconButton type="submit" className={classes.iconButton} style={{cursor: "auto"}} aria-label="search">
                <img src={icon} alt="icon" />
            </IconButton>
        </Box>
    );
}
