// Customizable Area Start
import React from "react";
import RecruiterProfileController1, {
    DealExperience,
    Props,
    WorkExperience,
} from "./RecruiterProfile1Controller";
import CustomMenu from "./components/CustomMenu.web";
import {
    Grid, Tab, Typography, styled, Tabs, Button, Box, Chip, Paper, Table, TableCell, TableContainer,
    TableHead, TableRow, TableBody,
    IconButton,
    DialogTitle,
    Dialog,
    CircularProgress,
    Avatar,
} from "@material-ui/core";
import { add, report, message, connect, bottomRight, tableClockIcon, showAll, backIcon, followingIcon } from "./assets"
import CustomPost from "./components/CustomPost.web";
import ShowAllPosts from "./components/ShowAllPosts.web";
import ConfirmDialog from "../../../components/src/ConfirmDialog.web";
import ReportDialog from "./components/ReportDialog.web";
import CreateFeedPost from "../../../blocks/landingpage/src/components/CreateFeedPost.web";
import InvitationDialog from "../../../components/src/InvitationDialog.web";
import { ToastContainer } from "react-toastify";
import ChatReportDialog from "../../../../packages/components/src/ChatReportDialogue";
import { ReportedSuccessDialogue } from "../../../../packages/components/src/ReportedSuccessDialogue";
import RecruiterProfileDetails from "./components/RecruiterProfileDetails.web";
import { getCommaSeparatedValue, handleConditionFunction, TruncateWithTooltip } from "../../../../packages/components/src/Utils.web";
import BootstrapTooltip from "../../../../packages/blocks/catalogue/src/components/BootstrapTooltip";
// Customizable Area End
export default class RecruiterProfile1 extends RecruiterProfileController1 {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <ProfileContainer style={webStyle.mainContainer} id="pageContainer">
                <Grid style={webStyle.gridContainer}>
                    <Grid style={webStyle.TabContainer}>
                        <TabsInput
                            data-test-id="TabContainer"
                            value={this.state.tabPanelNo}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab data-test-id="ProfileBtn" label="Profile" id={"1"} />
                            <Tab data-test-id="AtivititesBtn" label="Activities" id={"2"} />
                        </TabsInput>
                    </Grid>
                    {this.state.userProfile.user_profile && this.handleCondition(this.state.tabPanelNo === 0,
                        <>
                        {handleConditionFunction(this.state.userProfile.user_profile.data.attributes.account_details.role === "recruiter",
                            <>
                                {
                                    <RecruiterProfileDetails
                                        userProfile={this.state.userProfile}
                                        data-test-id='recruiterProfileDetails'
                                        userDetails={this.state.userDetails}
                                        userID={this.state.userID}
                                        handleFollowClick={this.handleFollowClick}
                                        FollowFromFollowing={this.FollowFromFollowing}
                                        withdrawConnection={this.state.withdrawConnection}
                                        removeConnectionPopup={this.state.removeConnectionPopup}
                                        withdrawConnectionRequest={this.withdrawConnectionRequest}
                                        removeConnectionRequest={this.removeConnectionRequest}
                                        handleInvitationPopup={this.handleInvitationPopup}
                                        followClick={this.state.followClick}
                                        loggedId={this.state.loggedId}
                                        handleWithdraw={this.handleWithdraw}
                                        ignoreFriendRequestApiCall={this.ignoreFriendRequestApiCall}
                                        handleRemoveConnection={this.handleRemoveConnection}
                                        handleMessageClick={this.handleMessageClick} 
                                        handleReportOpen={this.handleReportOpen }    
                                    />
                                }
                            </>,
                        <>
                            <Grid direction="column" spacing={2} style={{ width: "100%" }}>
                                <Grid container style={webStyle.imageBox}>
                                    <Grid item style={{ width: "18%", height: "10%" }}>
                                        <Avatar src={this.state.userProfile.user_profile?.data?.attributes.photo} style={{
                                            height: "132px",
                                            width: "132px",
                                            borderRadius: "50%"
                                        }} >
                                        </Avatar>
                                    </Grid>
                                    <Grid item style={{ paddingLeft: "4px", width: "80%" }}>
                                        <Typography style={webStyle.nameText}>
                                            {this.state.userProfile.user_profile?.data?.attributes?.full_name}
                                        </Typography>
                                        <BootstrapTooltip title={this.state.userProfile.user_profile?.data?.attributes?.current_title}>
                                            <Typography style={webStyle.positionText}>
                                                {TruncateWithTooltip(this.state.userProfile.user_profile?.data?.attributes?.current_title, 35)}
                                            </Typography>
                                        </BootstrapTooltip>
                                        {/* <Typography style={webStyle.positionText}>
                                            {this.state.userProfile.user_profile?.data?.attributes?.current_title}
                                        </Typography> */}
                                        <Typography style={webStyle.connectionText}>
                                            {this.state.userProfile.user_profile?.data?.attributes?.total_connections} connections
                                        </Typography>
                                        {
                                            this.handleCondition(
                                            (`${this.state.userID}` !== `${this.state.userDetails.account_details?.id}`), 
                                            <Grid container spacing={1} style={{ marginTop: "8px", }}>
                                            <Grid item style={{ position: "relative" }}>
                                                {this.handleCondition(this.state.userProfile.user_profile.data.attributes.is_followed === false,
                                                    <Box style={webStyle.followButtonBox} data-test-id="addFromFollowing"
                                                        onClick={() => this.FollowFromFollowing(this.state.userProfile.user_profile?.data?.attributes?.account_details?.id)}
                                                    >
                                                        <img src={add} style={{width: "16px"}} />
                                                        <Typography style={webStyle.followText}>Follow</Typography>

                                                    </Box>, <Box style={webStyle.followButtonBox} data-test-id="unFollowFromFollowing"
                                                        onClick={this.handleFollowClick}>
                                                    <img src={followingIcon} style={webStyle.iconImage}/>
                                                    <Typography style={webStyle.followText}>Following</Typography>
                                                </Box>
                                                )}
                                            </Grid>
                                            <Grid item>
                                                {this.handleCondition(this.state.userProfile.user_profile?.data?.attributes?.connection_request_details === null,
                                                    <Box sx={webStyle.connectButton} data-test-id="addConnection"
                                                        onClick={() => this.handleInvitationPopup(this.state.userProfile.user_profile?.data?.attributes?.account_details?.id)} >
                                                        <img src={connect} style={webStyle.iconImage} />
                                                        <Typography style={webStyle.connectText}>
                                                            Connect</Typography>
                                                    </Box>, ""
                                                )}
                                                {this.handleCondition(this.state.userProfile.user_profile?.data?.attributes?.connection_request_details?.status === "pending" && 
                                                Number(this.state.loggedId) === this.state.userProfile.user_profile?.data?.attributes?.connection_request_details?.sender_id,
                                                    <Box sx={webStyle.connectButton} data-test-id="withdrawConnectionRequest" onClick={this.handleWithdraw} >
                                                        <img src={connect} style={webStyle.iconImage} />
                                                        <Typography style={webStyle.connectText}>
                                                            Pending</Typography>
                                                    </Box>, ""
                                                )}
                                                 {this.handleCondition(Number(this.state.loggedId) !== this.state.userProfile.user_profile?.data?.attributes?.connection_request_details?.sender_id && this.state.userProfile.user_profile?.data?.attributes?.connection_request_details?.status === "pending" ,
                                                    <Box sx={webStyle.connectButton} data-test-id="ignoreConnectionRequest" onClick={this.ignoreFriendRequestApiCall} >
                                                        <img src={connect} style={webStyle.iconImage} />
                                                        <Typography style={webStyle.connectText}>
                                                            Ignore</Typography>
                                                    </Box>, ""
                                                )}
                                                {this.handleCondition(this.state.userProfile.user_profile?.data?.attributes?.connection_request_details?.status === "accepted",
                                                    <Box sx={webStyle.connectButton} data-test-id="removeConnectionRequest" onClick={this.handleRemoveConnection}>
                                                        <img src={connect} style={webStyle.iconImage} />
                                                        <Typography style={webStyle.connectText}>
                                                            Remove</Typography>
                                                    </Box>, ""
                                                )}
                                            </Grid>
                                            {this.handleCondition(this.checkForMessage(this.state.userProfile),
                                            <Grid item>
                                                <Box style={webStyle.messageButton} data-test-id="handleMessageClick" onClick={this.handleMessageClick}>
                                                    <img src={message} style={webStyle.iconImage} />
                                                    <Typography style={webStyle.messageText}>Message</Typography>
                                                </Box>
                                            </Grid>
                                            , ""
                                            )}
                                            <Grid item>
                                                {this.state.userProfile.user_profile.data.attributes.is_reported === false ?
                                                    <Box data-test-id="reportButton" style={webStyle.reportButton}
                                                        onClick={this.handleReportOpen}>
                                                        <img src={report} style={{width: "22px"}} />
                                                        <Typography style={webStyle.reportText}>
                                                            Report
                                                        </Typography>
                                                    </Box> :
                                                    <Box style={webStyle.reportButton}>
                                                        <img src={report} style={{width: "22px"}} />
                                                        <Typography style={webStyle.reportText}>
                                                            Reported
                                                        </Typography>
                                                    </Box>
                                                }
                                                   
                                            </Grid>
                                            </Grid>,
                                         "")
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={webStyle.skillsMainBox}>
                                <Typography style={webStyle.skillsText}>
                                    Skill Details
                                </Typography>
                                <Grid container spacing={1} style={webStyle.skillContainer}>
                                    {this.handleCondition(this.state.userProfile.user_profile?.data?.attributes?.skills.length === 0,
                                        <Typography style={webStyle.noskillsText}>
                                            No skills
                                        </Typography>,
                                        this.state.userProfile.user_profile?.data?.attributes?.skills.map((item: string[], index: number) => (
                                            <Chip key={index} label={item} style={webStyle.skillsChip} />
                                        )))}
                                    <img
                                        src={bottomRight}
                                        alt="icons"
                                        style={webStyle.skillImage}
                                    />
                                </Grid>
                            </Grid>
                            <Grid style={{ width: "100%", marginTop: "4px" }}>
                                <Box style={{ ...webStyle.item, position: 'relative', paddingBottom: '26px', minHeight: "50px", overflow: 'auto', marginTop: "20px" }}>
                                    <Box style={{ ...webStyle.personalDetailsTop, marginRight: '33px' }}>
                                        <Typography>
                                            <span style={webStyle.blockHeading}>
                                                Work Experience
                                            </span>
                                        </Typography>
                                    </Box>
                                    <TableContainer component={Paper} elevation={0} style={webStyle.tableWrapper}>
                                        <Table style={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label="simple table">
                                            {this.handleCondition(this.state.userProfile.user_profile?.data?.attributes?.work_experiences?.data.length === 0,
                                                <Typography style={webStyle.noWorkExperienceText}>
                                                    No work experiences
                                                </Typography>,
                                                this.state.userProfile.user_profile?.data?.attributes?.work_experiences?.data.map((item: WorkExperience, index: number) => {
                                                    return <TableRow key={item?.attributes?.id} data-test-id="workExperienceTable">
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            style={{ ...webStyle.tableItem, width: '1%', fontWeight: 400, verticalAlign: 'top', padding: '2px 5px' }}
                                                        >
                                                            <span className='verticalLine'></span>
                                                            {this.handleCondition((index % 2) === 0,
                                                                <span className="workExperienceColumn" >{index + 1}</span>,
                                                                <span className="workExperienceColumn" style={{ backgroundColor: '#C7D9F0' }}>{index + 1}</span>)}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            style={{ ...webStyle.tableItem, width: '16.67%', fontWeight: 400, padding: '5px' }}
                                                        >
                                                            <span style={webStyle.positionSpan}>
                                                                <span style={webStyle.positionTexts}>
                                                                    {item?.attributes?.position}
                                                                </span>
                                                                <span style={webStyle.specText}>
                                                                    Specialization Sector: {item?.attributes?.specialization}
                                                                </span>
                                                            </span>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                ...webStyle.tableItem,
                                                                width: '16.67%',
                                                                fontWeight: 400,
                                                                verticalAlign: 'top',
                                                                padding: '5px',
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <span style={webStyle.clockIcon}>
                                                                <img src={tableClockIcon} style={{ marginRight: '5px' }} />
                                                                <span style={{ fontFamily: "Arial" }}>
                                                                    {`${this.formatDate(item?.attributes?.started_time)}
                                                                ${this.handleCondition(item?.attributes?.currently_working,
                                                                        " till Present",
                                                                        ` to ${this.formatDate(item?.attributes?.ended_time)}`)}
                                                                  `}
                                                                </span>
                                                            </span>
                                                        </TableCell>
                                                        <TableCell
                                                            align="right" style={{
                                                                ...webStyle.tableItem,
                                                                verticalAlign: 'top',
                                                                padding: '5px',
                                                                paddingRight: '20px',
                                                            }}>
                                                            <span style={webStyle.editIconSpan}>
                                                                <img
                                                                    alt="Edit icon"
                                                                    src={item?.attributes?.company_icon_url}
                                                                    style={webStyle.editIconImage}
                                                                />
                                                                <span style={webStyle.companyText}>
                                                                    {item?.attributes?.company_name}
                                                                </span>
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                                })
                                            )}
                                        </Table>
                                    </TableContainer>
                                    <img src={bottomRight} alt="icons" style={webStyle.bottomRightBox} />
                                </Box>
                            </Grid>
                            <Grid style={{ width: "100%" }}>
                                <Box style={{ ...webStyle.item, marginBottom: "110px", position: 'relative', paddingBottom: '26px', overflow: 'auto', marginTop: "20px" }}>
                                    <Box style={{ ...webStyle.personalDetailsTop, marginRight: '33px' }}>
                                        <Typography >
                                            <span style={webStyle.blockHeading}>
                                                Deal Experience
                                            </span>
                                        </Typography>
                                    </Box>
                                    {this.handleCondition(!this.state.userDeal?.deal_experiences ||
                                        this.state.userDeal?.deal_experiences?.data?.length === 0,
                                        <Typography style={{ color: "rgb(23, 54, 93)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            No Deal experiences
                                        </Typography>,
                                        <TableContainer component={Paper} elevation={0} style={webStyle.tableWrapper}>
                                            <Table style={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label="simple table">
                                                <TableHead >
                                                    <TableRow>
                                                        <TableCell style={{ ...webStyle.tableItem, fontWeight: 700 }} >Date</TableCell>
                                                        <TableCell style={{ ...webStyle.tableItem, fontWeight: 700 }}  >Bidder Company</TableCell>
                                                        <TableCell style={{ ...webStyle.tableItem, fontWeight: 700 }}  >Buyer/Investor</TableCell>
                                                        <TableCell style={{ ...webStyle.tableItem, fontWeight: 700 }}  >Enterprise Value ('000s)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.userDeal?.deal_experiences?.data?.map((data: DealExperience, index: number) => (
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row" style={{ ...webStyle.tableItem, width: '16.67%', fontWeight: 400 }}>
                                                                {data?.attributes?.created_at}
                                                            </TableCell>
                                                            <TableCell id="companyOne" style={{ ...webStyle.tableItem, width: '16.67%', fontWeight: 400 }}>{data.attributes.deal_type === "debt" ? data.attributes.borrower_name : data.attributes.target_name }</TableCell>
                                                            <TableCell id="companyTwo" style={{ ...webStyle.tableItem, width: '16.67%', fontWeight: 400 }}>{data.attributes.deal_type === "debt" ? data.attributes.lender_name : data.attributes.buyer_investor_name}</TableCell>
                                                            <TableCell id="companyThree" style={{ ...webStyle.tableItem, width: '16.67%', fontWeight: 400 }}>{data.attributes.deal_type === "debt" ? getCommaSeparatedValue(data.attributes.debt_size) : getCommaSeparatedValue(data.attributes.enterprise_value)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>)}
                                    <img src={bottomRight} alt="icons" style={webStyle.bottomRightBox} />
                                </Box>
                            </Grid>
                        </>)}
                        </>,
                        this.handleCondition(this.state.showAllPosts,
                            <Box style={webStyle.showAllPost}>
                                <Box style={webStyle.backIconBox} onClick={this.handleShowAllPosts}>
                                    <img src={backIcon} style={webStyle.backIcon} />
                                    <Typography style={webStyle.activityTypeText}>
                                        All {this.state.activityType}
                                    </Typography>
                                </Box>
                                <ShowAllPosts
                                    activity={this.state.activity} upvotePost={this.upvotePost}
                                    downvotePost={this.downvotePost} activityType={this.state.activityType}
                                    toggleComments={this.toggleComments} postShowID={this.state.postShowID}
                                    createComment={this.createComment} upvotePostComment={this.upvotePostComment}
                                    downvotePostComment={this.downvotePostComment} openPostDeleteDialog={this.handlePostMenuCall}
                                    userDetails={this.state.userDetails} handleFeedModalOpen={this.handleFeedModalOpen}
                                    handleSavePostApi={this.handleSavePost} getInitials={this.getInitial}
                                    progressComment={this.state.progressComment}
                                    remainingTimeComment={this.state.remainingTimeComment}
                                    loaderComment={this.state.loaderComment}
                                    handleDeleteComment={this.handleDeleteComment}
                                    updateComment={this.updateComment}
                                    expandedPosts={this.state.expandedPosts}
                                    toggleReadMore={this.toggleReadMore}
                                    handleUserNavigation={this.handleUserNavigation}
                                />
                            </Box>,
                            <Grid direction="column" spacing={1} style={{ width: "100%" }}>
                                <Grid item sm={12} className="allActivityWrapper">
                                    <Box style={{ ...webStyle.item, position: 'relative', marginTop: "16px" }} className="allActivityInnerBox">
                                        <Typography style={webStyle.blockHeading}>All Activity</Typography>
                                        <Box>
                                            <Button
                                                data-test-id="postsButton"
                                                style={{
                                                    ...webStyle.activityButton, marginLeft: '0',
                                                    ...(this.state.activityType === "posts" ? {} : webStyle.inactiveButton)
                                                }}
                                                onClick={() => this.handleUpdateActivity("posts")}
                                            >
                                                Posts
                                            </Button>
                                            <Button
                                                data-test-id="commentsButton"
                                                style={{
                                                    ...webStyle.activityButton,
                                                    margin: '10px 8px',
                                                    ...(this.state.activityType === "comments" ? {} : webStyle.inactiveButton)
                                                }}
                                                onClick={() => this.handleUpdateActivity("comments")}
                                            >
                                                Comments
                                            </Button>
                                            <Button
                                                data-test-id="reactionsButton"
                                                style={{
                                                    ...webStyle.activityButton,
                                                    ...(this.state.activityType === "reactions" ? {} : webStyle.inactiveButton)
                                                }}
                                                onClick={() => this.handleUpdateActivity("reactions")}
                                            >
                                                Reactions
                                            </Button>
                                        </Box>
                                        {(this.state.activityType === 'posts' || this.state.activityType === 'comments' || this.state.activityType === 'reactions') &&
                                            (this.state.activity?.[this.state.activityType]?.length > 0) ?
                                            <>
                                                <CustomPost
                                                    data-test-id="activityList"
                                                    posts={this.state.activity?.[this.state?.activityType]?.slice(0, 2)}
                                                    upvotePost={this.upvotePost}
                                                    downvotePost={this.downvotePost}
                                                    activityType={this.state.activityType}
                                                    toggleComments={this.toggleComments}
                                                    postShowID={this.state.postShowID}
                                                    createComment={this.createComment}
                                                    isAdvanceSearch={true}
                                                    upvotePostComment={this.upvotePostComment}
                                                    downvotePostComment={this.downvotePostComment}
                                                    openConfirmDialog={this.handlePostMenuCall}
                                                    userDetails={this.state.userDetails}
                                                    openEditFeedModal={this.handleFeedModalOpen}
                                                    handleSavePost={this.handleSavePost}
                                                    getInitials={this.getInitial}
                                                    handleDeleteComment={this.handleDeleteComment}
                                                    updateComment={this.updateComment}
                                                    progressComment = {this.state.progressComment}
                                                    remainingTimeComment = {this.state.remainingTimeComment}
                                                    loaderComment = {this.state.loaderComment}
                                                    expandedPosts={this.state.expandedPosts}
                                                    toggleReadMore={this.toggleReadMore}
                                                    showReadMore
                                                    handleNameClick={this.handleUserNavigation}
                                                />
                                                <Box style={{ display: 'flex', justifyContent: 'end', paddingTop: '10px' }}>
                                                    <Box
                                                        style={webStyle.showAllPosts}
                                                        onClick={this.handleShowAllPosts}
                                                    >
                                                        <Typography style={webStyle.showAllActivityText}>
                                                            Show all {this.state.activityType}
                                                        </Typography>
                                                        <img src={showAll} />
                                                    </Box>
                                                </Box>
                                            </>
                                            : !this.state.loading && <Box>
                                            <Typography style={{ color: "rgb(23, 54, 93)", textAlign: "center", textTransform: "capitalize", marginTop: "4px" }}>No {this.state.activityType}</Typography>
                                        </Box>}
                                    </Box>
                                </Grid>
                            </Grid>
                        )
                    )}
                </Grid>
                <ConfirmDialog
                    open={this.state.removeConnectionPopup}
                    type={"confirm"}
                    handleClose={this.handleRemoveConnection}
                    hadndleDelete={this.removeConnectionRequest}
                    questionOne={`Are you sure you want to remove the connection ?`}
                    onDeleteText="Remove"
                />
                <ConfirmDialog
                    open={this.state.withdrawConnection}
                    type={"confirm"}
                    handleClose={this.handleWithdraw}
                    hadndleDelete={this.withdrawConnectionRequest}
                    questionOne={`Are you sure you want to withdraw the connection request ?`}
                    onDeleteText="Withdraw"
                />
                <ConfirmDialog
                    open={this.state.followClick}
                    type={"confirm"}
                    handleClose={this.handleFollowClick}
                    hadndleDelete={this.unFollowFromFollowing}
                    questionOne={`Are you sure you want to unfollow ${this.state.userProfile.user_profile?.data?.attributes?.full_name} ?`}
                    onDeleteText="Yes"
                    description1={`Stop seeing posts from ${this.state.userProfile.user_profile?.data?.attributes?.full_name} on your feed.`}
                    description2={`${this.state.userProfile.user_profile?.data?.attributes?.full_name} won't be notified that you've unfollowed.`} 
                />
  
                <ConfirmDialog
                    data-test-id="confirmDialog"
                    open={this.state.openConfirmDialogNew.open}
                    handleClose={this.handleClose}
                    hadndleDelete={this.state.openConfirmDialogNew.onConfirm}
                    questionOne={this.state.openConfirmDialogNew.title1}
                    questionTwo={this.state.openConfirmDialogNew.title2}
                />
                {
                    this.state.openEditFeedPost &&
                    <CreateFeedPost
                        postDescription={this.state.currentPost.description}
                        postContentHandler={this.postContentHandler}
                        handleDeleteImage={this.handleDelete}
                        handleStateUpdate={this.handleStateUpdateClick}
                        images={this.state.currentPost.images}
                        handleImageChange={this.handleFeedImageChange}
                        loading={this.state.loading}
                        selectedTopics={this.state.currentPost.selectedTopicId}
                        selectTopics={this.state.selectTopicsList}
                        open={this.state.openEditFeedPost}
                        selectTopicsValue={this.selectTopicsValue}
                        onClose={this.handleFeedModalOpen}
                        createFeedPostApi={this.createFeedPost}
                        userProfileName={this.state.userDetails.full_name ?? ''}
                        isPostImageUploaded={this.state.isPostImageUploaded} loader={this.state.loaderComment} progress={0} remainingTime={0}
                        profileImage={this.state.userDetails.photo}
                    />
                }
                <InvitationDialog
                    data-test-id="invitationDialog"
                    open={this.state.openInvitationDialog.open}
                    handleClose={this.handleInvitationClose}
                    handleSendInvitation={this.handleInviteClick}
                    questionOne={"Add a note to your invitation?"}
                    questionTwo={`Personalize your invitation ${this.getUserFullName()} by adding a note. Members are more likely to accept invitations that include a note.`}
                />
                <ToastContainer />
                {this.state.isReportOpen && 
                    <ReportedSuccessDialogue
                    handleClose={this.handleReportPoupOpen}
                    open={this.state.isReportOpen}
                    type={this.state.reportType}
                    />
                }
                {this.state.reportClick &&
                    <ChatReportDialog
                        handleClose={this.handleReportOpen}
                        selectedReason={this.state.selectedReason}
                        handleSelect={this.handleSelect}
                        handleSubmit={this.addReasons}
                        list={this.state.userReasons}
                        reportType={this.state.reportType}
                    />
                }
            </ProfileContainer>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const ProfileContainer = styled(Box)({
    "&::-webkit-scrollbar": {
        width: "16px",
    },
    "&::-webkit-scrollbar-track": {
        background: "#D9D9D9",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#1F497D",
        width: "16px",
        borderRadius: "50px",
        height: "367px !important",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#1F497D",
    },
    '@media (max-width: 1024px)': {
        width: '100%',
    },
})
const TabsInput = styled(Tabs)({
    "& .MuiTab-root": {
        maxWidth: "50% !important",
    },
    "& .MuiTab-textColorPrimary": {
        width: "100%",
        color: "#9C9C9C",
        fontSze: "16px",
        textTransform: "capitalize"
    },
    "& .Mui-selected": {
        color: "#17365D",
        fontWeight: 900,
        fontSize: "16px",
        fontFamily: "Arial"
    },
    "& .MuiTabs-indicator": {
        background: "#6D97C1",
    }
})
const webStyle = {
    mainContainer: {
        width: "100%",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column" as "column",
    },
    gridContainer: {
        minWidth: "1170px",
        width: "1170px",
        maxWidth: "1170px",
        paddingTop: "10px",
        marginBottom: "80px"
    },
    imageBox: {
        padding: "20px 32px",
        borderRadius: "8px",
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        background: "#FFFFFF",
        marginTop: "20px",
    },
    positionText: {
        fontSize: "14px",
        color: "#9C9C9C",
        fontFamily: "Arial",
        fontWeight: 400,
        width: "30%"
    },
    TabContainer: {
        borderRadius: "16px",
        height: "fit-content",
        paddingTop: "10px",
        background: "#FFFFFF",
        marginTop: "20px",
    },
    nameText: {
        fontSize: "30px",
        color: "#17365D",
        fontFamily: "Arial",
        fontWeight: 700,
    },
    followButtonBox: {
        background: "#6D97C1",
        display: "flex",
        color: "#FFFFFF",
        borderRadius: "8px",
        gap: "6px",
        padding: "10px 26px",
        height: "fit-content",
        cursor: "pointer",
    },
    connectButton: {
        borderRadius: "8px",
        display: "flex",
        padding: "10px 26px",
        border: "1px solid #6D97C1",
        cursor: "pointer",
        gap: "6px",
        height: "fit-content"
    },
    connectionText: {
        fontSize: "14px",
        color: "#6D97C1",
        fontFamily: "Arial",
        fontWeight: 400,
    },
    messageButton: {
        borderRadius: "8px",
        display: "flex",
        padding: "10px 26px",
        border: "1px solid #6D97C1",
        cursor: "pointer",
        gap: "6px",
        height: "fit-content"
    },
    followText: {
        fontSize: "14px",
        color: "#ffffff",
        fontWeight: 700,
        fontFamily: "Arial"
    },
    reportButton: {
        borderRadius: "8px",
        display: "flex",
        padding: "10px 26px",
        border: "1px solid #6D97C1",
        cursor: "pointer",
        gap: "6px",
        height: "fit-content",
        alignItems: "center",
        justifyContent: "center",
        color: "#6D97C1",
        fontSize: "14px"
    },
    connectText: {
        fontSize: "14px",
        color: "#6D97C1",
        fontFamily: "Arial",
        fontWeight: 700
    },
    skillsMainBox: {
        marginTop: "20px",
        borderRadius: "16px",
        display: "flex",
        padding: "18px 0px 10px 15px",
        flexDirection: "column" as "column",
        gap: "4%",
        background: "#ffffff",
        minHeight: "102px"
    },
    messageText: {
        fontSize: "14px",
        fontFamily: "Arial",
        color: "#6D97C1",
        fontWeight: 700
    },
    reportText: {
        fontSize: "14px",
        fontFamily: "Arial",
        color: "#6D97C1",
        fontWeight: 700
    },
    skillsText: {
        fontSize: "14px",
        color: "#17365D",
        fontFamily: "Arial",
        fontWeight: 700,
    },
    skillImage: {
        bottom: "0px",
        position: "absolute" as "absolute",
        top: "32px",
        right: "4px",
    },
    skillContainer: {
        marginTop: "4px",
        position: "relative" as "relative",
        padding: "10px"
    },
    item: {
        borderRadius: '20px',
        background: "#ffffff",
        minHeight: '50px',
        padding: '20px',
    },
    personalDetailsTop: {
        alignItems: 'center',
        display: 'flex',
        height: 'fit-content',
        justifyContent: 'space-between',
        marginBottom: '15px'
    },
    blockHeading: {
        fontWeight: 700,
        fontFamily: 'Arial',
        lineHeight: '22px',
        fontSize: '14px',
        color: '#17365D'
    },
    bottomRight: {
        bottom: "0px",
        position: "absolute",
        right: "0px"
    },
    tableItem: {
        width: '16.67%',
        border: 'none',
        fontFamily: 'Arial',
        color: '#1F497D',
        fontSize: '12px'
    },
    tableWrapper: {
        overflow: 'auto',
        maxHeight: '490px',
        marginTop: '22px'
    },
    noskillsText: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        color: "rgb(23, 54, 93)"
    },
    skillsChip: {
        borderRadius: '4px',
        height: '24px',
        backgroundColor: '#EEECE1',
        padding: '6px 12px 6px 8px',
        margin: '0 13px 13px 2px',
        color: '#1F497D',
        fontFamily: 'Arial',
        fontSize: '12px',
    },
    activityButton: {
        height: "24px",
        fontWeight: 700,
        fontSize: '16px',
        fontFamily: 'Arial',
        color: '#17365D',
        borderRadius: '20px',
        padding: '6px 10px 6px 10px',
        backgroundColor: '#C7D9F0',
        margin: '10px',
        textTransform: "capitalize" as "capitalize"
    },
    inactiveButton: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #6D97C1',
        fontSize: '14px',
        fontWeight: 400,
        color: "#9C9C9C"
    },
    showAllActivityText: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: '14px',
        color: '#1F497D',
        paddingRight: '10px',
    },
    showAllPosts: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    noWorkExperienceText: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "rgb(23, 54, 93)"
    },
    positionTexts: {
        color: "#1F497D",
        fontWeight: 700,
        fontSize: "12px",
        fontFamily: "Arial"
    },
    specText: {
        fontWeight: 400,
        fontSize: '8px',
        lineHeight: '18px',
        color: "#17365D"
    },
    companyText: {
        marginRight: '10px',
        marginLeft: '4px',
        fontSize: "10px",
        fontFamily: "Arial"
    },
    editIconImage: {
        width: '30px',
        cursor: 'pointer',
        marginRight: '4px',
    },
    editIconSpan: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: "4px",
    },
    clockIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: "10px",
    },
    positionSpan: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between',
        height: '45px',
    },
    backIcon: {
        width: '6.59px',
        height: '11.17px',
        marginRight: '10px',
        cursor: 'pointer'
    },
    activityTypeText: {
        fontFamily: 'Arial',
        fontSize: '14px',
        fontWeight: 700,
        color: '#17365D'
    },
    showAllPost: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        width: '100%'
    },
    backIconBox: {
        display: 'flex', alignItems: 'center',
        justifyContent: 'start',
        marginBottom: '20px'
    },
    bottomRightBox: {
        position: "absolute" as "absolute",
        bottom: "0px",
        right: "0px"
    },
    loadingContainer: {
        position: "absolute" as "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgb(0, 0, 0, .4)",
        zIndex: 1,
    },
    circularContainer: {
        position: "absolute" as "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
    },
    iconImage: {
        width: "24px",
    },
    noBtn: {
        "color": "#1F497D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        },
        "borderRadius": "8px",
        "border": "1px solid  #1F497D",
        "background": "#F4F4F4",
        "width": "120px",
        "height": "56px",
        "padding": "10px 16px",
        "textTransform": "none"
    },
    yesBtn: {
        "border": "1px solid #C7D9F0",
        "color": "#FFFFFF",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "#C7D9F0"
        },
        "borderRadius": "8px",
        "background": "#1F497D",
        "display": "flex",
        "width": "120px",
        "height": "56px",
        "padding": "16px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "textTransform": "none",
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    btncontainerMain: { margin: "0px", width: "auto", paddingTop: "22px" },
    btnContainer1: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingTop: "170px !important"
    },
}
// Customizable Area End 