import React, { Component, ChangeEvent, RefObject } from 'react';
import { Grid, Typography, IconButton, Avatar, Button } from '@material-ui/core';
import { withStyles, WithStyles, createStyles, styled } from '@material-ui/core/styles';
import { gallary, remove } from '../assets';
import { Comment, Post, UserDetails } from '../RecruiterProfileController.web';
import Uploadmedia3 from '../../../uploadmedia3/src/Uploadmedia3.web';

const styles = createStyles({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    field: {
        border: "1px solid #9C9C9C",
        borderRadius: "16px",
        padding: "0px 0px 0px 10px",
        display: "flex",
        alignItems: "center",
        cursor: "text",
        flexDirection: "column",
        flex: 1,
        position: 'relative',
    },
    placeholder: {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#94A3B8",
        alignSelf: "center"
    },
    avatar: {
        height: "28px",
        width: "28px",
        alignSelf: "center",
        border: "2px solid #1F497D"
    },
    gallary: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%"
    },
    input: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    fieldContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    gallaryImage: {
        height: "90px",
        width: "90px",
        padding: "10px 10px 10px 0px",
        borderRadius: "20px"
    },
    imageContainer: {
        position: "relative",
    },
    removeIcon: {
        top: "17px",
        right: "16px",
        position: "absolute",
        cursor: "pointer"
    },
    header: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%"
    },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
    },
    postBtn: {
        color: "#64748B",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: "bold",
        background: "#F1F5F9",
        borderRadius: "8px",
        padding: "8px 18px",
        margin: "0px 10px 10px",
        textTransform: "none",
        height: "32px",    
    },
    postBtnActive: {
        color: "#FFFFFF",
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: "bold",
        background: "#1F497D",
        borderRadius: "8px",
        padding: "8px 18px",
        margin: "0px 10px 10px",
        textTransform: "none",
        height: "32px",
        "&:hover": {
            color: "#FFFFFF",
            fontFamily: "Arial",
            fontSize: "16px",
            fontWeight: "bold",
            background: "#1F497D",
            borderRadius: "8px",
            padding: "8px 18px",
            margin: "0px 10px 10px",
        }
    },
    textArea: {
        width: "100%",
        border: "0px",
        paddingTop: "10px",
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#1F497D",
        resize: "none",
        "&:focus": {
            border: "0px",
            outline: "none"
        },
        "&::placeholder": {
            fontFamily: "Arial",
            fontSize: "14px",
            color: "#94A3B8",
            paddingTop: "0px"
        }
    }
});

interface PostButtonProps {
    notActive: boolean;
}

const PostButton = styled('button')(({ notActive }: PostButtonProps) => ({
    color: notActive ? '#64748B': '#FFFFFF',
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "bold",
    background: notActive ? "#F1F5F9":'#1F497D',
    borderRadius: "8px",
    padding: "8px 18px",
    margin: "0px 10px 10px",
    textTransform: "none",
    height: "32px",
    display: "flex",
    alignItems: "center",
    border:'none',
    cursor:notActive ? 'auto' : 'pointer'
}));

interface Props extends WithStyles<typeof styles> {
    getInitials: (value?: string) => string;
    userDetails: UserDetails;
    createComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string, activityType: string) => void;
    post: Post;
    cmt: Comment;
    isNested: boolean;
    openNestedCommetns: () => void;
    activityType: string;
    loader:boolean;
    remainingTime: number;
    progress: number;
    isEditMode?: boolean;
    updateComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string) => void;
    handleEditMode?: (value: boolean) => void;
}

class PostCommentTextField extends Component<Props> {
    state = {
        open: false,
        images: [] as File[],
        comment: "",
    };

    ref: RefObject<HTMLTextAreaElement> = React.createRef();

    handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = [...Array.from(e.target.files)];
            this.setState({ images: files });
        }
    };

    
    componentDidMount(): void {
        if (this.props.isEditMode) {
            this.setState({
                open: true,
                comment: this.props.cmt.comment || "",
                images: this.props.cmt.comment_images,
            })
        }
    }

    handleDeleteImage = (index: number) => {
        const updatedImages = this.state.images.filter((_, i) => i !== index);
        this.setState({ images: updatedImages });
    };

    onClickButton = () => {
        const { createComment, post, cmt, isNested, openNestedCommetns, isEditMode = false, updateComment, handleEditMode = () => { } } = this.props;
        const { images, comment } = this.state;
        if (isEditMode) {
            updateComment(comment, images, isNested, isNested ? cmt.id : post.id, post.id);

            this.setState({
                images: [],
                comment: "",
            });
            handleEditMode(false)
        } else {
            createComment( comment, images, isNested, isNested ? cmt.id : post.id, post.id, this.props.activityType );

            openNestedCommetns()
            this.setState({
                images: [],
                comment: "",
            })
        }
    }


    render() {
        const { loader, progress, remainingTime, classes } = this.props;
        const { open, images, comment } = this.state;

        return (
            <Grid container style={{ padding: "5px 20px 0px 20px" }}>
                <Grid className={classes.input}>
                    <Grid className={classes.fieldContainer}>
                        <Grid className={classes.center}>
                            <Avatar 
                                className={classes.avatar}
                                src={this.props?.userDetails?.photo || ''}
                            >
                            </Avatar>
                        </Grid>
                        &nbsp;
                        &nbsp;
                        <Grid id="open_field" className={classes.field} onClick={() => this.setState({ open: true }, () => this.ref.current?.focus())}>
                            {loader ?
                                <div style={{ zIndex: 100, position: 'absolute', top: '50%', transform: 'translateY(-50%)', }}>
                                    <Uploadmedia3 navigation="navigation" id="id" progress={progress} time={remainingTime} />
                                </div> : null
                            }
                            <Grid className={classes.header}>
                                {!open ? <Typography className={classes.placeholder}>Add a comment</Typography> : <textarea id="text-area" ref={this.ref} style={{zIndex:99}} placeholder='Add a comment' name="Text1" value={comment} onChange={(event) => this.setState({ comment: event.target.value })} rows={2} className={classes.textArea}></textarea>}
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    style={{ padding: "10px" }}
                                >
                                    <img src={gallary} alt="gallery" />
                                    <input
                                        type="file"
                                        hidden
                                        name="photo"
                                        accept="image/*"
                                        id="group_image"
                                        multiple={false}
                                        onChange={(event) => this.handleImageChange(event)}
                                    />
                                </IconButton>
                            </Grid>
                            {open && <Grid className={classes.gallary}>
                                {images.map((item, index) => (
                                    <Grid className={classes.imageContainer} key={index}>
                                        <img src={(this.props.isEditMode && 'url' in item) ? (item as { url: string }).url : URL.createObjectURL(item)} alt={`Preview ${index}`} className={classes.gallaryImage} />
                                        <img src={remove} alt="remove" className={classes.removeIcon} onClick={() => this.handleDeleteImage(index)} />
                                    </Grid>
                                ))}
                            </Grid>}
                            {open && <Grid className={classes.footer}>
                                <PostButton
                                    id="postButton"
                                    className={(comment.trim() === "") && images.length === 0 ? classes.postBtn : classes.postBtnActive}
                                    notActive={(this.state.comment.trim() === "") && this.state.images.length === 0}
                                    onClick={(comment.trim() === "") && images.length === 0 ?
                                        () => { } :
                                        this.onClickButton
                                    }>
                                    Post
                                </PostButton>
                            </Grid>
                            }
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(PostCommentTextField);
