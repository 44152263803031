import React from "react";

// Customizable Area Start
import {

  Box,
  Button,
  Typography,
  Grid,
  TextField,
  IconButton, InputAdornment,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  ClickAwayListener,
  Checkbox,
  Paper,
  Avatar, Card, List, ListItem, ListItemAvatar, ListItemText,
  Container,
  Tooltip,
  CircularProgress

} from "@material-ui/core";
import BootstrapTooltip from "../../../../packages/blocks/catalogue/src/components/BootstrapTooltip";
import { Theme, ThemeProvider, createStyles, createTheme, styled, withStyles, } from "@material-ui/core/styles";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {  cancelIcon, deleteIcon,search,pencilIcon, threeDotsIcon, noMessage, pendingMessage, leftArrowIcon  } from "./assets"
import clsx from 'clsx';
import CustomRadio from '../../../components/src/CustomRadio'
import NotesIcon from '@material-ui/icons/Notes';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/styles";
import { ToastContainer } from 'react-toastify';
import Uploadmedia3 from '../../uploadmedia3/src/Uploadmedia3.web';
import { handleConditionFunction } from "../../../../packages/components/src/Utils.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },

  },
});


const CustomBox = styled(Box)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#D3D3D3',
    borderRadius: theme.shape.borderRadius,
  },
  '& .MuiOutlinedInput-input': {
    padding: '5px',
  },
  '& .MuiButton-contained:hover': {
    boxShadow: "none"
  },
  '& .MuiButton-contained': {
    boxShadow: "none"
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: "white"
  },
  GridCont: {
    "&:hover": {
      backgroundColor: "yellow",
    }
  },

}));
const ScrollableBox = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 311px)",
overflowY: "auto",
scrollbarWidth: 'none', // For Firefox
'&::-webkit-scrollbar': {
  display: 'none', // For Chrome, Safar
},
}));
const ScrollableSearchBox = styled(Box)(({ theme }) => ({
  position: "absolute" as const,
  background: "#ffffff",
  borderRadius: 16,
  zIndex: 99,
  boxShadow: "0px 2px 8px 0px #00000014",
  border: "1px solid #CBD5E1",
  width: "calc(100% - 80px)",
  maxHeight: "calc(100vh - 348px)",
overflowY: "auto",
scrollbarWidth: 'none', // For Firefox
'&::-webkit-scrollbar': {
  display: 'none', // For Chrome, Safari, and Opera
},
}));
const ScrollableMessageBox = styled(Box)(({ theme }) => ({
  padding: "0px 40px",
  // height: "calc(-465px + 100vh)", 
  overflowY: "auto",
  '@media (max-height: 850px)': {
    height: "calc(-455px + 100vh)",
  },
scrollbarWidth: 'none', // For Firefox
'&::-webkit-scrollbar': {
  display: 'none', // For Chrome, Safari, and Opera
},
}));
const ScrollablenewSearchBox = styled(Box)(({ theme }) => ({
  position: "absolute" as const,
  background: "#ffffff",
  zIndex: 99,
  width: "calc(100% - 77px)",
  height: "calc(100vh - 460px)",
minHeight: "fit-content",
overflowY: "auto",
scrollbarWidth: 'none', // For Firefox
'&::-webkit-scrollbar': {
  display: 'none', // For Chrome, Safari, and Opera
},
}));
const MessageBox = styled(Box)(({ theme }) => ({
  "@media (max-height: 850px)": {
      padding: "10px 40px !important",  
  },
}))

const FileMessageGrid = styled(Grid)(({ theme }) => ({
  "@media (max-height: 850px)": {
  }
}))


const SelectedUser = styled(Grid)(({ theme }) => ({
  "@media (max-height: 850px)": {
    padding: "10px 40px !important",  
},
}))

const NewMessageHeading = styled(Grid)(({ theme }) => ({
  "@media (max-height: 850px)": {
    padding: "10px 40px !important",  
},
}))

const EmptyBox = styled(Box)(({theme}) => ({
  "@media (max-height: 850px)": {
    height: "calc(-453px + 100vh) !important",
  }
}))

const ProfileImage = styled(Avatar)(({ theme }) => ({
  "@media (max-height: 850px)": {
    height: "65px !important",
    width: "65px !important",
  }
}))

const NewMessageGrid = styled(Grid)(({theme }) => ({
  "@media (max-height: 850px)": {
    height: "calc(-362px + 100vh) !important",
},
}))

const LeftGrid = styled(Grid)(({ theme }) => ({
  "& .gridContainer1Hide": {
    "@media (max-width: 960px)": {
      display: "none !important",
    }
  }
}))

const RightGrid = styled(Grid)(({ theme }) => ({
  "& .leftArrowIcon": {
    "@media (min-width: 960px)": {
      display: "none"
    }
  },

  "& .hidden": {
    "@media (min-width: 960px)": {
      display: "none"
    }
  },

  "& .MuiIconButton-root": {
    padding: "0px"
  }
}))

const FocusedBox = styled(Box)(({ theme}) => ({
  "@media (max-height: 850px)": {
      padding: "5px 0px !important",  
  },
}))

const ScrollableFilesBox = styled(Box)(({ theme }) => ({
  maxHeight: "calc(-512px + 100vh)",
overflowY: "auto",
scrollbarWidth: 'none', // For Firefox
'&::-webkit-scrollbar': {
  display: 'none', // For Chrome, Safari, and Opera
},
}));

export const CustomizedTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-root": {
        borderRadius: 8,
        height: 42,
        color: theme.palette.common.black,
        background: "#1C1C1C0D",
        fontFamily: "Arial",
        fontSize: "14px"
      },
      "& .MuiOutlinedInput-notchedOutline": {
      },
      "& .MuiOutlinedInput-notchedOutline:hover": {
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1.23px solid #6D97C1"
      },
      "& .MuiOutlinedInput-root:hover": {

      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "white"
      },
      "& .MuiSvgIcon-root": {
        fontSize: "25px"
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline:hover": {
        borderColor: "white !important"
      },
      "& .MuiOutlinedInput-input":{
        color:"#17365D",
        fontSize: "14px"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent', 
        },
        '&:hover fieldset': {
          borderColor: 'transparent', 
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent', 
        }
      },
    }
  }))(TextField);

  export const CustomizedMsgTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-root": {
        borderRadius: 8,
        fontFamily: "Arial",
        color: theme.palette.common.black,
        background: "#1C1C1C0D"
      },

      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1.23px solid #6D97C1"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "white"
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline:hover": {
        borderColor: "white !important"
      },
      '& .MuiInputBase-input': {
        color: '#1F497D',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "22px",
        fontFamily: "Arial",
        height: "50px",
        '@media (max-height: 850px)': {
          height: "30px"
        },
        overflowY:"auto !important"
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#6D97C1',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "22px",
        fontFamily: "Arial"

      },
      "& .MuiOutlinedInput-multiline":{
        padding: "10px 20px"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent', 
        },
        '&:hover fieldset': {
          borderColor: 'transparent', 
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent', 
        }
      },
    }
  }))(TextField);

const useStyles = makeStyles((theme: Theme) => ({
  checkedBoxIcon: {
    border: '1px solid #9C9C9C',
    borderRadius: 6,
    width: 20,
    height: 20,
    '$root.Mui-focusVisible &': {
      outline: `1px auto ##9C9C9C`,
      outlineOffset: 2,
    },
  },
  tickIcon: {
    backgroundColor: "#1F497D",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#1F497D',
    },
  },

}));

interface CustomCheckboxProps {
  name: string
  onChange: ()=>void
  "data-test-id"?: string
  checked: boolean;
}

export const CustomCheckboxSecondary = withStyles({
  root: {
    "&$checked": {
      color: "#EA0C78",
    },
    padding: "0px",
  },
  checked: {},
})((props: CustomCheckboxProps) => {
  const classes = useStyles();
  return (
    <Checkbox
      checkedIcon={<span className={clsx(classes.checkedBoxIcon, classes.tickIcon)} />}
      icon={<span className={classes.checkedBoxIcon} />}
      color="default"
      {...props}
    />
  );
});
import { ChatObj,AccountDetailsItemObj,MessageObj,AttachmentObj } from "./ChatController";
import ChatAttachments from "./components/ChatAttachments.web"
import ChatReportDialog from "../../../components/src/ChatReportDialogue";


// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderViewThreeDots = () => {
    return (
      <ClickAwayListener data-test-id="threeDotsView" onClickAway={this.closeViewThreeDots}>
        <Box style={webStyles.outerThreeDots}>
          <Box style={webStyles.InnerThreeDots}>
            <MenuItem style={webStyles.menuItemActive} data-test-id="manageConversation" onClick={(event)=>this.handleToggleSelectedVisibility(event)}>Manage Conversation</MenuItem>
          </Box>
        </Box>

      </ClickAwayListener>
    )
  }
  renderChatViewThreeDots = () => {
    return (
      <ClickAwayListener data-test-id="selectedUserActions" onClickAway={this.closeViewThreeDots}>
        <Box style={webStyles.outerThreeDots} minWidth={"192px"} >
          <Box style={webStyles.InnerThreeDotsPersonal}>
            <MenuItem style={webStyles.menuDeleteItemActive} data-test-id="menuDeleteItemActive" onClick={this.handleDeleteDialogOpen}>Delete</MenuItem>
            <MenuItem style={webStyles.menuDeleteItem} data-test-id="menuMarkAsUnread" onClick={(event)=>this.markMessagesUnread(event)}>Mark Unread</MenuItem>
            <MenuItem style={webStyles.menuDeleteItem} data-test-id="menuReport" onClick={() => !this.checkForReport() && this.handleReportDialogue()}>{this.handleCondition(this.checkForReport(), "Reported", "Report")}</MenuItem>
          </Box>
        </Box>

      </ClickAwayListener>
    )
  }


  renderEnterButtonThreeDots = () => {
    return (
      <ClickAwayListener data-test-id="sendBtnSettingPreview" onClickAway={this.closeViewThreeDots}>
        <Box style={webStyles.outerSendThreeDots} >
          <Box style={webStyles.sendInnerDots}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={this.state.send_message_option}
                onChange={this.handleChangeRadio}
                data-test-id="chooseSettingOptions">
                <FormControlLabel
                  value="Press Enter to Send"
                  style={webStyles.radioFormControll}
                  control={<CustomRadio />}
                  label={
                    <Box style={webStyles.radioText}>
                      <Typography variant="h6" style={webStyles.sendButton1}>Press Enter to Send</Typography>
                      <Typography variant="body2" style={webStyles.sendButton2}>
                        Pressing enter will send message
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="Click To Send"
                  style={webStyles.radioFormControll}
                  control={<CustomRadio />}
                  label={
                    <Box style={webStyles.radioText}>
                      <Typography variant="h6" style={webStyles.sendButton1}>Click Send</Typography>
                      <Typography variant="body2" style={webStyles.sendButton2}>
                        Clicking send will send message
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>

      </ClickAwayListener>
    )
  }
  renderSearchPopup = (userType:string) => {
    return (
      <ClickAwayListener onClickAway={this.closeViewThreeDots}>
        <ScrollableSearchBox>
           <List style={{padding:"4px"}}>
      {this.state.existSearchUsersList.map((item:ChatObj, index:number) => {
         const userInfo = item.attributes.account_details.data.find((selectItem:AccountDetailsItemObj)=>item.attributes.current_user_id != Number(selectItem.id))
        return(
          <>
          {userInfo && 
         
        <Card
          key={index}
          style={{
            marginBottom: 2,
            borderRadius:"20px",
            borderBottom: "1px solid #F4F4F4",
            backgroundColor: this.state.hoveredMsgExistUserItemId==Number(userInfo.id)?"#6D97C133":"#fff",
            cursor: "pointer"
          }}
          onMouseEnter={() => this.handleExistUserMouseEnter(Number(userInfo.id))}
          onMouseLeave={this.handleExistUserMouseLeave}
          onClick={() => this.startConversastion(userType,userInfo,item)}
          elevation={0}
          data-test-id="userList"
        >
          <ListItem style={webStyles.existUserlistItem}>
            <ListItemAvatar  style={webStyles.existUserImgRoot} >
              <Avatar src={userInfo.attributes.profile_details.data.attributes.photo}  alt={"Profile photo"}   style={webStyles.messagegriditem7} />
            </ListItemAvatar>
            <ListItemText style={webStyles.existUserText}
              primary={
                <Typography style={webStyles.existUsertypo1}>{userInfo.attributes.profile_details.data.attributes.full_name}</Typography>
              }
              secondary={  <Typography style={webStyles.typo11}>{userInfo.attributes.profile_details.data.attributes.current_title}</Typography>}
            />
          </ListItem>
        </Card>
         }
        </>
        )
  })}
    </List>
    </ScrollableSearchBox>
      </ClickAwayListener>
    )
  }

  renderNoResult = () => {
    return handleConditionFunction(
      !this.state.newSearchLoader && this.state.newUsersList.length < 1,
      <Typography style={{marginTop: "5%", textAlign: "center", color: "#1F497D"}} >
        No results found
      </Typography>,
      null
    )
  }

  renderNewUserSearchPopup = (userType:string) => {
    return (
      <ClickAwayListener onClickAway={this.closeViewThreeDots}>
        <ScrollablenewSearchBox>
           <List>
      {this.renderNoResult()}
      {this.state.newUsersList.map((item:AccountDetailsItemObj, index:number) => (
        <Card
          key={'newuser_'+index}
          elevation={0}
          style={{
            marginBottom: this.state.hoveredMsgExistUserItemId==Number(item.id)?0:2,
            borderBottom: "1px solid #F4F4F4",
            backgroundColor: this.state.hoveredMsgExistUserItemId==Number(item.id)?"#6D97C133":"#fff",
            cursor: "pointer"
          }}
          onMouseEnter={() => this.handleExistUserMouseEnter(Number(item.id))}
          onMouseLeave={this.handleExistUserMouseLeave}
          onClick={() => this.startConversastionWithNewUser(userType,item)}
          data-test-id="newSearchedUsers"
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar src={item.attributes.profile_details.data.attributes.photo} alt={'Photo'}   style={webStyles.messagegriditem7}/>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography style={webStyles.newMessagetypo1}>{item.attributes.profile_details.data.attributes.full_name}</Typography>
              }
              secondary={  <Typography style={webStyles.typo11}>{ item.attributes.role !== "recruiter" ? item.attributes.profile_details.data.attributes.current_title : `Recruiter at ${item.attributes.profile_details.data.attributes.company_name}`}</Typography>}
            />
          </ListItem>
        </Card>
      ))}
    </List>
    </ScrollablenewSearchBox>
      </ClickAwayListener>
    )
  }

  renderReportModal = () => {
    if (this.state.reportClick)
      return <ChatReportDialog
        data-test-id="ChatReportDialog"
        handleClose={this.handleReportDialogue}
        selectedReason={this.state.selectedReason}
        handleSelect={this.handleSelect}
        handleSubmit={this.addReasons1}
        list={this.state.userReasons}
      />
  }

  renderReportMessageModal = () => {
    return (
      <>
        <Dialog open={this.state.reportedSuccessfully} data-test-id="reportModal" onClose={this.handleCloseReportMessage}>
          <Grid item xs={12} style={{width: "570px"}}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                <img src={cancelIcon}
                  onClick={this.handleCloseReportMessage}
                  style={webStyles.dialogIconSize}
                  data-test-id="reportDialogCancelIcon"
                />
              </Grid>
            </Grid>
          </Grid>
          <DialogContent style={webStyles.contentBoxReport}>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h6" style={webStyles.deleteContentText}>
                  This user has been reported successfully. 
                  <br></br>
                  Our team will soon connect with you.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    )
  }

  renderDeleteModel = () => {
    return (
      <>
        <Dialog open={this.state.dialogOpen} data-test-id="deleteModal" onClose={this.handleDeleteDialogClose}>
        <Grid item xs={12}>
                      <Grid container direction="row"  alignItems="center">
                        <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                          <img src={cancelIcon}
                            onClick={this.handleDeleteDialogClose}
                            style={webStyles.dialogIconSize}
                            data-test-id="deleteDialogCancelIcon"
                          />
                        </Grid>
                        </Grid>
                        </Grid>
          <DialogContent style={webStyles.contentBox}>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h6" style={webStyles.deleteContentText}>
                   {this.state.deleteMultiple ? configJSON.multipleMessageDeleteConfirmation : configJSON.singleMessageDeleteConfirmation}
                  </Typography>
                </Grid>
                {this.state.deleteMultiple &&
                  <Grid item xs={12}>
                    <Typography variant="h6" style={webStyles.dialogCoversastion as React.CSSProperties}>
                      {this.state.checkedItems.length} {this.state.checkedItems.length>1?'conversations':'conversation'}, including all history will be permanently removed from Workcentivo.
                    </Typography>
                    <Typography variant="h6" style={webStyles.dialogCoversastionTop as React.CSSProperties}>
                      This action cannot be undone.
                    </Typography>
                  </Grid>
                }


              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={webStyles.dialogActionStyles}>
            <Box display={'flex'} alignItems={'center'}
              >
              <Box>
                <Button onClick={this.handleDeleteDialogClose}
                  style={webStyles.cancelButton}
                data-test-id="closeDeleteDialogBtn"
                >
                  Cancel
                </Button>
              </Box>
              <Box>


                <Button onClick={this.handleDeleteConfirm}
                  style={webStyles.deleteButton}
                  data-test-id="deleteDialogButton"
                >
                  {this.state.deleteMultiple ? "Yes, Delete" : "Delete"}
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  toggleMessageBox=()=>{
    return(
      <Box>
      <IconButton style={webStyles.IconButton1}>
        {!this.state.toggleMessageBoxOpenClose &&  
           <KeyboardArrowUpIcon data-test-id="uparrow" onClick={()=>this.messageToggleUp()}/>
        }
           {this.state.toggleMessageBoxOpenClose &&  
           <KeyboardArrowDownIcon data-test-id="downarrow" onClick={()=>this.messageToggleDown()} />
        }
     
      </IconButton>
    </Box>
    )
  }

  fileAttachmentBlock=()=>{
    return(
      <Box>
      <IconButton style={{...webStyles.IconButtonFile, ...webStyles.mr4}} data-test-id="openFileAttachment" onClick={() => this.openFile('image')}>
        <PhotoLibraryIcon />
      </IconButton>
        <input
          ref={this.fileInputRef}
          hidden
          type="file"
          accept={this.state.acceptanceCriteria}
          onChange={(event) => this.handleFileSelect(event)}
          data-test-id="chooseFile"
        />
      <IconButton style={webStyles.IconButtonFile} data-test-id="openAttachmentFile" onClick={() => this.openFile('doc')}>
        <AttachFileIcon />
      </IconButton>
    </Box>
    )
  }

  sendButtonsBlock=()=>{
    return(
      <Box display={'flex'} alignItems={'center'} style={{ gap: '15px' }}>
      <Box position={'relative'}>
        <Button variant="contained" disabled={this.disbableSendButton()} onClick={()=>this.checkForNewUser()} style={webStyles.sendButton} data-test-id="sendButton">Send</Button>
      </Box>     
      <Box onClick={this.handleSendButtonPopup} data-test-id="sendButtonPopup" position={'relative'}>
        <img src={threeDotsIcon} style={webStyles.iconSize} />
        {this.state.sendButtonPopupStatus && this.renderEnterButtonThreeDots()}
      </Box>
    </Box>
    )
  }

  messagingBlock=()=>{
    return (
      <MessageBox sx={webStyles.griditem2} className="messageHeading">
      <Grid item style={webStyles.griditem3}>
        <Typography style={webStyles.typo1}>Messaging</Typography>
      </Grid>
      <Grid item style={{
        display: "flex",
        flexDirection: "row",
        alignItems: 'center'
      }}>
        <Tooltip title="New Message">
        <IconButton style={webStyles.IconEditButton}>
          <img data-test-id="startNewUserFlow" src={pencilIcon} style={webStyles.iconSize}
            onClick={() => this.handleToggleNewMessageFlow()}
            />
        </IconButton>
        </Tooltip>
        <Box onClick={this.openViewThreeDots} data-test-id="threeDotsIcon" position={'relative'}>
          <img src={threeDotsIcon} style={webStyles.iconSize} />
          {this.state.viewThreeDotsStatus && this.renderViewThreeDots()}
        </Box>

      </Grid>
    </MessageBox>
    )
  }
  userDetailsObj=()=>{
    return(
      <Grid data-test-id="userDetails">
      <Grid container style={webStyles.griditem14} >
        <Grid item>
          <Box style={webStyles.box3}>
            {this.state.selectedUser && 
                <ProfileImage src={this.state.selectedUser?.attributes?.profile_details?.data?.attributes?.photo}  alt={"Profile photo"}  style={webStyles.img1}/>
             }
                {this.state.selectedUser==null && 
                <div style={webStyles.emptySelectUser} />
             }
          </Box>
        </Grid>
        <Grid item style={{ flex: 1,whiteSpace: "nowrap",minWidth: "0px" }}>
          <Typography style={webStyles.typo10}>{this.state.selectedUser?.attributes?.profile_details?.data?.attributes?.full_name}</Typography>
          <Typography style={webStyles.typoMessage}>{this.state.selectedUser?.attributes?.profile_details?.data?.attributes?.bio}</Typography>
          {this.state.selectedUser?.attributes?.role=='candidate' && this.state.selectedUser?.attributes?.profile_details?.data?.attributes?.current_company
           &&      <Typography style={webStyles.typosubMessage}>{`Candidate at ${this.state.selectedUser?.attributes?.profile_details?.data?.attributes?.current_company}`}</Typography>}
          {this.state.selectedUser?.attributes?.role=='recruiter' && this.state.selectedUser?.attributes?.profile_details?.data?.attributes?.company_name
           &&      <Typography style={webStyles.typosubMessage}>{`Recruiter at ${this.state.selectedUser?.attributes?.profile_details?.data?.attributes?.company_name}`}</Typography>}
        </Grid>
        <Grid item style={webStyles.griditem9}>
          <Typography style={{...webStyles.typo10, fontSize: "12px"}}>{this.state.lastMessageTime}</Typography>
        </Grid>
      </Grid>
    </Grid>
    )
  }

  selectedExistUserActionBlock=()=>{
    return(
      <SelectedUser style={webStyles.box2} data-test-id="selectedUser" className="selectedUser">
        <img
          src={leftArrowIcon}
          alt="back"
          style={webStyles.backBtn}
          onClick={this.handleHiddenList}
          className="leftArrowIcon"
        />
        <Box >
          <Typography style={webStyles.typo9}>{this.state.selectedUser?.attributes?.profile_details?.data?.attributes?.full_name}</Typography>
        </Box>
        <Box display={'flex'} alignItems="center">
          <Box>
            <IconButton style={webStyles.IconButton2}>
              {
                !this.state.isStared && this.state.selectedUser && <BootstrapTooltip title={"Star"}>
                  <StarBorderIcon data-test-id="starbordericon" onClick={() => this.handleStared('star')} />
                </BootstrapTooltip>
              }
              {
                this.state.isStared && this.state.selectedUser && <BootstrapTooltip title="Unstar">
                  <StarIcon style={{ color: "#1F497D" }} data-test-id="starIcon" onClick={() => this.handleStared('unstar')} />
                </BootstrapTooltip>
              }

            </IconButton>
          </Box>
          <Box onClick={this.openViewChatThreeDots} data-test-id="selectedUserthreeDotsIcon" position={'relative'}>
            {this.state.selectedUser && <img src={threeDotsIcon} style={webStyles.iconSize} />}
            {!this.state.selectedUser && <div style={webStyles.emptyStarDive}></div>}
            {this.state.viewChatThreeDotsStatus && this.renderChatViewThreeDots()}
          </Box>
        </Box>
      </SelectedUser>
    )
  }

  renderSubscribeCandidate = () => {
    return (
      <>
        {this.handleConditionElement(
          this.checkForRecruiterMessage(),
          this.renderMessagePending("candidate"),
          this.renderForNoUsers("candidate")
        )}
      </>
    )
  }

  renderSubscribeRecruiter = () => {
    return (
      <>
        {this.renderForNoUsers("recruiter")}
        {this.renderMessagePending("recruiter")}
      </>
    )
  }

  renderForNoUsers = (userRole: string) => {
    if(this.showSubscribe() && (this.state.existUsersList.length <= 0 || userRole === "candidate"))
    {return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }} >
        <Box style={{ textAlign: "center", width: "50%", margin: "auto" }}>
          <img src={noMessage} style={{ height: "200px" }}/>
          {this.handleConditionElement(userRole === "recruiter",
            <Typography style={webStyles.noMessageText}>No message yet</Typography>,
            <Typography style={webStyles.noMessageText}>No recruiter message yet</Typography>
          )}
          <Typography style={webStyles.subscribeText}>Don't miss out on exclusive benefits! Subscribe now to unlock premium perks and enhance your experience with us today.</Typography>
          <Button data-test-id="subscribeBtn" style={{ ...webStyles.subscribeButton }} onClick={ () => this.handleSubscribeNavigation()}>Subscribe Now</Button>
        </Box>
      </Box>
    )} else {
      return <></>
    }
  }

  renderMessagePending = (userRole: string) => {
    if(this.showSubscribe() && this.state.existUsersList.length > 0)
    {return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }} >
        <Box style={{ textAlign: "center", width: "50%", margin: "auto" }}>
          <img src={pendingMessage} style={{ height: "200px" }} />
          {this.handleConditionElement(userRole === "recruiter", 
          <Typography style={webStyles.noMessageText}>Messages pending, Subscribe Now.</Typography>
          ,
          <Typography style={webStyles.noMessageText}>Recruiter messages pending, Subscribe Now.</Typography>
          ) }
          <Typography style={webStyles.subscribeText}>Upgrade your plan now to unlock access to potential opportunities from recruiters. Don't miss out on valuable messages waiting for you!</Typography>
          <Button data-test-id="subscribeBtn" style={{ ...webStyles.subscribeButton }} onClick={ () => this.handleSubscribeNavigation()}>Subscribe Now</Button>
        </Box>
      </Box>
    ) }
    else {
      return <></>
    }
  }

  existUsersBlock=()=>{
    return(
    <ScrollableBox>
                  {this.state.existUsersList.length <= 0 && 
                  <Box style={{height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',}}>
                    <Typography style={{...webStyles.typo4, textAlign: "center"}}>
                      No messages
                    </Typography>
                  </Box>
                  }
                  {this.state.existUsersList.filter((chat: ChatObj) => !chat.attributes.is_reported).map((item:ChatObj, index: number) => {
                  const userInfo= item.attributes.account_details.data.find((selectItem:AccountDetailsItemObj)=>item.attributes.current_user_id != Number(selectItem.id))
                    return(
                      <>
                      {userInfo &&
                    <Grid
                      container
                      style={{
                        height: "auto",
                        borderBottom: "1px solid #F4F4F4",
                        padding: "8px 21px",
                        display: "flex",
                        flexDirection: "row",
                        transition: "background-color 0.3s, border-color 0.3s",
                        backgroundColor: this.handleCondition(this.checkForBlur(index), "inherit", this.handleCondition(this.state.hoveredItemId === Number(userInfo.id) || this.state.selectedUser?.id === userInfo.id, '#F3F4F8' , 'inherit')),
                        borderLeft: this.handleCondition(this.checkForBlur(index), "inherit", this.handleCondition(this.state.hoveredItemId === Number(userInfo.id) || this.state.selectedUser?.id === userInfo.id, '4px solid #6D97C1', 'inherit')),
                        filter: this.handleCondition(this.checkForBlur(index), "blur(4px)", "none"),
                        cursor: "pointer",
                        userSelect: "none"
                      }}
                      onMouseEnter={() => this.handleMouseEnter(Number(userInfo.id))}
                      onMouseLeave={this.handleMouseLeave}
                      key={userInfo.id}
                      onClick={()=> !this.checkForBlur(index) && this.selectUser(userInfo,item)}
                      data-test-id="existUsersList"
                      >
                      {this.state.manageConversationClicked && (
                        <Grid item style={webStyles.griditem6}>
                          <CustomCheckboxSecondary
                            onChange={() => this.handleCheckboxChange(Number(item.id))}
                            name={""} checked={this.state.checkedItems.includes(Number(item.id))}
                            data-test-id="selectCheckBox" />
                        </Grid>
                      )}
                      <Grid item>
                        <Box>
                        <Avatar src={userInfo.attributes.profile_details.data.attributes.photo}  alt={"Profile photo"}  style={webStyles.griditem7} />
                        </Box>
                      </Grid>
                      <Grid item style={webStyles.griditem8}>
                        <Typography style={webStyles.typo5}>{userInfo.attributes.profile_details.data.attributes.full_name}</Typography>
                        {userInfo.attributes.role=='candidate' && userInfo.attributes.profile_details.data.attributes.current_company
                         &&      <Typography style={webStyles.typo6}>{`Candidate at ${userInfo.attributes.profile_details.data.attributes.current_company}`}</Typography>}
                        {userInfo.attributes.role=='recruiter' && userInfo.attributes.profile_details.data.attributes.company_name
                         &&      <Typography style={webStyles.typo6}>{`Recruiter at ${userInfo.attributes.profile_details.data.attributes.company_name}`}</Typography>}
                   
                   {item.attributes.last_message?.attributes?.created_by.full_name && item.attributes.last_message?.attributes?.message && 
                    <Typography style={webStyles.typo7}>{`${ Number(userInfo.id)==item.attributes.last_message?.attributes.account_id?item.attributes.last_message?.attributes?.created_by.full_name:'You'}: ${item.attributes.last_message?.attributes?.message}`}</Typography>
                   }
                   {item.attributes.last_message?.attributes?.created_by.full_name && item.attributes.last_message.attributes?.attachments && 
                    <Typography data-test-id="attachment-text" style={webStyles.typo7}>{`${ Number(userInfo.id)==item.attributes.last_message.attributes.account_id?item.attributes.last_message.attributes.created_by.full_name:'You'}: sent an attachment`}</Typography>
                   }
                       
                      </Grid>
                      <Grid item style={webStyles.griditemTime as React.CSSProperties}>
                        <Typography style={webStyles.typo8}>{item.attributes.last_message?.attributes?.created_at}</Typography>
                       <div style={webStyles.favouriteInfo}>
                        {this.isUnreadMessagesPresent(item) &&  
  <Typography component="span" style={webStyles.typoCount as React.CSSProperties} data-test-id="unreadMessagesCount" >{item.attributes.unread_messages_count}</Typography>
                        }
                                {
                                  item.attributes.starred && <StarIcon style={{ color: "#1F497D" }} data-test-id="unstar-icon" onClick={() => { this.handleStared('unstar') }} />
                                }                     

                      </div>
                      </Grid>
                    </Grid>
                   } 
                    </>
      )})}
                  </ScrollableBox>
    )
  }

  selectNewUserDetails=()=>{
    return(
      <Box style={webStyles.newUserInfo} data-test-id="selectedNewUser">
      <Grid container style={webStyles.griditemNewUser14} >
      <Grid item>
        <Box style={webStyles.newUserbox3}>
          {this.state.selectedNewUserInfo && 
            <Avatar src={this.state.selectedNewUserInfo.data.attributes.photo || ''}  alt={"Profile photo"} style={webStyles.newuserImg1} />
          }
        </Box>
      </Grid>
          <Grid item style={{ flex: 1 }}>
            {this.state.selectedNewUserInfo &&
              <>
                <Typography style={webStyles.newUsertypo10}>{this.state.selectedNewUserInfo.data.attributes.full_name}</Typography>
                <Typography id='companyNameForNewUser' style={webStyles.typosubMessage}>
                  {this.renderCompanyName(
                    this.state.selectedNewUserInfo.data.attributes.account_details.role,
                    this.state.selectedNewUserInfo.data.attributes.current_title,
                    this.state.selectedNewUserInfo.data.attributes.company_name
                  )}
                </Typography>
              </>
            }
          </Grid>
    </Grid>
    </Box>
    )
  }


uploadAttachmentBlock=()=>{
  return(
     <ScrollableFilesBox id="uploadfilebox">
     <List style={{paddingTop:'0px',paddingBottom:'0px'}} id="uploadfileboxList" data-test-id="selectedImage" >
{this.state.selectedFiles.map((file, index) => (
<ListItem key={index}  style={{borderTop:'1px solid #CBD5E1', paddingTop:'5px',paddingBottom:'5px'}}>
   {this.state.selectedFiles.length>0 && ["image/jpeg", "image/png", "image/gif"].includes(file.type) && URL.createObjectURL(file) ?
<img src={URL.createObjectURL(file)} alt={file.name} style={{... webStyles.uploadFileImage,marginRight:"10px"}} />
:  <>{file.type=="application/pdf" && <Typography style={{... webStyles.fileType,padding:"10px 7px"}}>PDF</Typography>}

{file.type=="application/msword" && <Typography style={webStyles.fileType}>DOC</Typography>}
{file.type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" && <NotesIcon fontSize="large" style={webStyles.documentIcon} />}
</>


}
 <ListItemText primary={
      <Typography style={webStyles.fileName}> { file.name}</Typography>
  
   } secondary= {
     <Typography style={webStyles.fileSize}> 
     { `${(file.size / 1024).toFixed(2)}KB`}
   </Typography>
   }
   />
 <IconButton edge="end" aria-label="delete"    data-test-id="clearChooseImage" onClick={() => this.handleFileDelete(index)}>
   <CloseIcon />
 </IconButton>
</ListItem>
))}
</List>
</ScrollableFilesBox>
  )
}

chatMessageBlock=()=>{
  return(
    <ScrollableMessageBox id="messageBoxexistUser" style={{height: this.renderMessageInput() ? "calc(-485px + 100vh)" : "calc(-300px + 100vh)" }}>
    {this.state.messageListInfo?.chat?.data?.attributes?.messages.map((message:MessageObj)=>
    <>

<Grid item key={message.id} style={{paddingTop:'14px'}}>
<Box display="flex" alignItems="flex-start">
<Avatar alt={message.attributes.created_by.full_name} src={message.attributes.created_by.profile_image || ''}  />
<Paper elevation={0} style={{flex:1,marginLeft:"12px"}}>
  <Grid container >
    <Grid item xs={12}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" style={webStyles.messageText} >
          {message.attributes.created_by.full_name}
        </Typography>
        <Typography variant="caption" style={webStyles.messagetime}>
          {message.attributes.created_at}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={11}>
     {message?.attributes?.message && message?.attributes?.message !="''" && <Typography variant="body1" style={webStyles.messageTextOne}>{message?.attributes?.message}</Typography>} 
      {message.attributes.attachments && Array.isArray(message.attributes.attachments) && message.attributes.attachments.map((attachment:AttachmentObj)=>
           <> 
           {
            (attachment.url.includes('.jpg') || attachment.url.includes('.png') || attachment.url.includes('.gif') ||  attachment.url.includes('.jpeg'))  && 
            <img src={attachment.url} alt="png" style={webStyles.uploadImage} data-test-id="openImageModal" onClick={()=>this.handleOpenImageModal(attachment.url)}/>
           }
          {
            (attachment.url.includes('.doc') || attachment.url.includes('.docx') || attachment.url.includes('.pdf')) && 
            <ChatAttachments url={attachment.url} data-test-id="downloadDocFile" downloadFile={(url:string, filename:string)=>this.downloadFile(url,filename)}/>
          }

           </>
                                     
          )}
    </Grid>
  </Grid>
</Paper>
</Box>
</Grid>

    </>
    )}
          </ScrollableMessageBox>
  )
}

existUserSearchBlock=()=>{
  return(
    <Box position={'relative'} style={webStyles.searchBox}  >
    <CustomizedTextField
      value={this.state.searchReceptient}
      onChange={this.handelSearch}
      variant="outlined"
      placeholder="Search messages"
      data-test-id="searchExistUser"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={webStyles.msgadornment}>
            <IconButton size="small">
              <img src={search}  style={webStyles.msgHeadSearchImg}/>
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            ⌘/
          </InputAdornment>
        ),
      }}
    />
    {this.state.searchPopUpStatus && this.renderSearchPopup('existUser')}
  </Box>
  )
}

newUserSearchBlock=()=>{
  return(
    <>
    <NewMessageGrid style={webStyles.griditem15} id="newMsgMessagegriditem1">
    <Box position={'relative'} padding={'10px 38px'}>
      <CustomizedTextField
        value={this.state.searchnewMessageReceptient}
        onChange={this.handelNewMessageSearch}
        variant="outlined"
        placeholder="New message"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={webStyles.msgadornment} >
              <IconButton size="small">
                <img src={search}  style={webStyles.msgHeadSearchImg}/>
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              ⌘/
            </InputAdornment>
          ),
        }}
        data-test-id="newMessageSearch"
      />
      {this.state.searchNewMessagePopUpStatus && this.renderNewUserSearchPopup("newUser")}
      {this.renderLoader()}
    </Box>
    <Box>
    </Box>
  </NewMessageGrid>
  {this.state.selectedFiles.length> 0 && this.uploadAttachmentBlock()}  
     </>
  )
}

  renderLoader = () => {
    if (this.state.newSearchLoader)
      return (
        <div style={webStyles.loaderStyle}>
          <CircularProgress />
        </div>
      )
  }

existUserMessageBlock=()=>{
  return(
    <Grid style={webStyles.messageBoxRoot}>
    {this.state.loaderMessage ?
                      <div style={{ zIndex: 100, position: 'absolute', top: '50%', transform: 'translateY(-50%)',right:'25%',backgroundColor:'rgb(203, 213, 225,0.5)', borderRadius:'5px' }}>
                          <Uploadmedia3 navigation="navigation" id="id" progress={this.state.progressMessage} time={this.state.remainingTimeMessage} />
                      </div> 
                      : null 
                   } 
                      {this.state.loader ?
                      <div style={{ zIndex: 100, position: 'absolute', top: '50%', transform: 'translateY(-50%)',right:'25%', borderRadius:'5px' }}>
                         <CircularProgress />
                      </div> 
                      : null 
                   } 
          {this.chatMessageBlock()}
          {this.state.selectedFiles.length> 0 && this.uploadAttachmentBlock()}
    </Grid>
  )
}

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <>
      <style>{configJSON.globalCSS}</style>
      <ThemeProvider theme={theme}>
        {this.renderDeleteModel()}
        {this.renderReportModal()}
        {this.renderReportMessageModal()}
          <Container maxWidth={false} style={
          webStyles.custombox                       
        }>
            <Grid item xs={12}>
          <Grid container style={
            webStyles.gridmainCon
          }>
            <LeftGrid container item md={4} xs={12} style={webStyles.gridContainer1} className={`${this.state.hideList ? 'hideForMobile' : ''}`}>
              <Box style={{width: "100%", display: "contents"}}>
                <Box sx={webStyles.griditem1}>
                  {this.messagingBlock()}
                  {!this.state.selectedVisible && this.existUserSearchBlock()}

                  {this.state.selectedVisible && this.state.manageConversationClicked && (
                    <Box margin={'10px 11px'}>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}
                        padding={'10px 20px'}

                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <Box display={'flex'} alignItems={'center'} style={{ gap: '10px' }}>
                          <Box>
                            <img src={cancelIcon} data-test-id="cancelIcon" style={webStyles.iconSize} onClick={this.handleInitiateDeleteMultipleConversation} />
                          </Box>
                          <Box>
                            <Typography variant="h6" style={webStyles.typo2}>{`${this.state.checkedItems.length} Selected`}</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <img src={deleteIcon} data-test-id="deleteIcon" style={ this.state.checkedItems.length>0 ? webStyles.iconSize : webStyles.disabledIconSize}  onClick={this.handleDialogOpen} />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <FocusedBox sx={webStyles.box1}>
                    <Typography style={webStyles.typo4}>{configJSON.focused}</Typography>
                  </FocusedBox>
                </Box>
              </Box>
              <Box style={{ maxWidth: "100%", width: "100%" }}>
              {this.existUsersBlock()}
              </Box>
            </LeftGrid>
            <RightGrid item md={8} xs={12} style={webStyles.griditem10} className={`${this.state.hideList ? '' : 'hideForMobile'}`}>
              {this.handleConditionElement(this.state.userRole === "candidate", this.renderSubscribeCandidate(), this.renderSubscribeRecruiter())}
              {!this.state.newMessageFlow && this.state.isSubscribed &&
                <>
                  {this.selectedExistUserActionBlock()}
                  {this.userDetailsObj()}
                  {this.existUserMessageBlock()}        
                </>
              }
      

              {this.state.newMessageFlow &&
                <>
                  <NewMessageHeading style={webStyles.box2}>
                    <Box style={{display: "flex"}} >
                          <img
                            src={leftArrowIcon}
                            alt="back"
                            style={{...webStyles.backBtn, marginRight: "20px"}}
                            onClick={this.handleHiddenList}
                            className="leftArrowIcon"
                          />
                      <Typography style={webStyles.newMessageText}>
                        New Message
                        </Typography>
                    </Box>            
                  </NewMessageHeading>

                  {
                  this.state.isConversationStartWithNewUser &&
                  <>
                {this.state.isSubscribed && this.selectNewUserDetails()}
                {this.state.loaderMessage ?
                                    <div style={{ zIndex: 100, position: 'absolute', top: '50%', transform: 'translateY(-50%)',right:'25%',backgroundColor:'rgb(203, 213, 225,0.5)', borderRadius:'5px' }}>
                                        <Uploadmedia3 navigation="navigation" id="id" progress={this.state.progressMessage} time={this.state.remainingTimeMessage} />
                                    </div> 
                                     : null 
                                 } 
                <EmptyBox id="emptyBox" style={webStyles.emptyBox}></EmptyBox>   
                {this.state.selectedFiles.length> 0 && this.uploadAttachmentBlock()}   
                </>
                }
         
                {!this.state.isConversationStartWithNewUser &&  this.newUserSearchBlock() }
            
                </>
              }
              {this.renderMessageInput() && <Grid item xs={12}>
                <Grid container style={{...webStyles.gridcontainer2, flexWrap: "nowrap"}}>
                
             
                  <Grid item style={{width: "95%", paddingRight: "20px"}}>
                    <Box style={webStyles.messageBoxContainer}>
                      <CustomizedMsgTextField
                      className="CustomizedMsgTextField"
                     style={webStyles.TextField3}
                     variant="outlined"
                     placeholder="Write a message ..."
                     multiline
                     minRows={this.state.massgeMaxRows}
                     fullWidth
                     value={this.state.messageText}
                     onChange={(event)=>this.handleChangeMessage(event)}               
                     data-test-id="writeMessage"
                     InputProps={{
                      inputProps: {
                        style: {  height: '50px',
                        overflowY: 'auto'}
                      },
                    }}
                    id="textarea"
                    onKeyPress={(event)=>this.handleKeyPress(event)}
                    /> 
                      </Box>
                  </Grid>
                  <Grid item  style={webStyles.griditem16}>
                  {this.toggleMessageBox()}
                  </Grid>
                </Grid>
                <FileMessageGrid container direction="row" justifyContent="space-between" alignItems="center" style={{ padding: "17px 40px 0px 40px" }}>
                {this.fileAttachmentBlock()}
                {this.sendButtonsBlock()}
                </FileMessageGrid>
              </Grid>}
            </RightGrid>
          </Grid>
</Grid>
</Container>

<Dialog   data-test-id="imageModal"open={this.state.imageModalOpen} onClose={() => this.handleImageModalClose()} maxWidth="lg" fullWidth>
                    <IconButton  data-test-id="modalCloseIcon" onClick={() => this.handleImageModalClose()}  style={{ position: 'absolute', right: 0, top: 0 }}>
                        <CloseIcon />
                    </IconButton>
                    <img src={this.state.previewImageUrl} style={{ width: '100%', height: '100%'  }}  />
                </Dialog>

      </ThemeProvider>
      <ToastContainer />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  loaderStyle: {
    zIndex: 100,
    position: 'absolute' as const,
    top: 0,
    transform: 'translateY(-50%)',
    right: '50%',
    borderRadius: '5px',
    marginTop: '15%',
  },
  custombox: {
    fontFamily: "Arial !important",
    backgroundColor:'#fff',
    paddingBottom: "40px"
  },
  gridmainCon: {
    backgroundColor: "white",
    border: "1px solid #CBD5E1",
    borderRadius: "4px",
    margin: "5px",
    height: "100%",
    marginBottom: "15px"
  },
  gridContainer1: {
  },
  griditem1: {
    borderBottom: "4px solid #1F497D",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
  },
  griditem2: {
    display: "flex",
    alignItems: 'center',
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "2px solid #F4F4F4",
    padding: "24px 40px",
    "@media (max-width: 850px)": {
      padding: "10px 40px",
    },
  },
  backBtn: {
    cursor: "pointer"
  },
  griditem3: {
  },
  typo1: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1F497D",
    lineHeight: "28px"
  },
  newMessagetypo1: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "14px",
    color: "#1F497D",
    lineHeight: "22px",
    cursor: "default"
  },
  existUsertypo1: {
    fontWeight: 400,
    fontFamily: "Arial",
    color: "#1F497D",
    fontSize: "14px",
    lineHeight: "22px"

  },
  griditem4: {

  },
  IconButton1: {
    color: "#1F497D",
    position: 'relative' as const
  },
  IconButtonFile:{
    color: "#1F497D",
    position: 'relative' as const,
    padding:'4px'
  },
  IconEditButton: {
    color: "#1F497D",
    position: 'relative' as const,
    padding:"0px",
    marginRight:"20px"
  },
  TextField1: {
  },
  ListItemIcon1: {
    width: 24,
    height: 24
  },
  TextField2: {
    padding: "10px 20px 5px 25px",
    width: "90%"
  },
  typo2: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "20px",
    color: "#1F497D",
    lineHeight:'28px'
  },
  typo3: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "14px",
    color: "#1F497D"
  },
  box1: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    padding: "14px 0px 14px"
  },
  typo4: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1F497D",
    lineHeight: "28px"
  },
  griditem5: {
    height: "calc(99%)",
    overflowY: "auto"
  },
  griditem6: {
    paddingRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  input1: {
    height: "15px",
    width: "15px"
  },
  griditem7: {
    borderRadius: "50%",
    height: "61px",
    width: "61px"
  },
  griditem8: {
    flex: 1,
    paddingLeft: "15px",
    whiteSpace: "nowrap",
    minWidth: "0px"
  },
  messagegriditem8:{
    flex: 1,
    paddingLeft: "10px",
    minWidth:"0px"
  },
  typo5: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "14px",
    color: "#17365D",
    lineHeight: "28px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  typo6: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6D97C1",
    lineHeight: "22px"
  },
  typo7: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "14px",
    color: "#9C9C9C",
    lineHeight: "22px",
    overflow: "hidden",
    textOverflow: "ellipsis"   
  },
  griditem9: {
  },
  griditemTime: {
    paddingRight: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  typo8: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "12px",
    color: "#1F497D",
    lineHeight: "28px"
  },
  typoCount: {
    borderRadius: "50%",
    backgroundColor: " #DC2626",
    color: "#fff",
    padding: "1px 6px",
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "Arial"
  },
  griditem10: {
    borderLeft: "1px solid #CBD5E1",
  },
  
  subscribeGridItem10: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    width: '100%', 
    height: '100%' 
  },

  griditem11: {

  },
  box2: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #F4F4F4",
    alignItems: 'center',
    padding: '24px 40px',
  },
  typo9: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1F497D",
    lineHeight: "28px"
  },
  griditem13: {

  },
  IconButton2: {
    color: "#1F497D",
    padding:"0px",
    marginRight:'20px'
  },
  IconButton3: {
    color: "#1F497D"
  },
  griditem14: {
    borderBottom: "1px solid #F4F4F4",
    padding:"12px 40px 8px 40px"
  },
  griditemNewUser14:{
    borderBottom: "1px solid #F4F4F4",
    padding:"8px 10px"
  },
  box3: {
    paddingRight: "20px"
  },
  messageBox3: {
   display:'flex'
  },
  newUserbox3:{
    paddingRight: "12px"
  },
  img1: {
    borderRadius: "60%",
    height: "92px",
    width: "92px",
    border: "3px solid #04CB00 "
  },
  newuserImg1:{
    borderRadius: "60%",
    height: "39px",
    width: "39px",
  },
  typo10: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#17365D",
    lineHeight: "28px",
    fontFamily: "Arial"
  },
  newUsertypo10:{
    fontWeight: 400,
    fontSize: "16px",
    color: "#17365D",
    lineHeight: "22px" ,
    fontFamily: "Arial"
  },
  typoMessage: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "14px",
    color: "#9C9C9C",
    lineHeight: "22px",
    overflow: "hidden",
textOverflow: "ellipsis"
  },
  typosubMessage: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "14px",
    color: "#9C9C9C",
    lineHeight: "22px"
  },
  typo11: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "14px",
    color: "#9C9C9C",
    lineHeight: "22px"
  },
  typo12: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "18px",
    color: "#17365D"
  },
  griditem15: {
    top: "20px",
    height: "calc(-393px + 100vh)", // 397
  },
  gridcontainer2: {
    padding: "17px 40px",
    paddingRight: "25px",
    borderBottom: "1px solid #CBD5E1",
    borderTop: "1px solid #CBD5E1",
    width: "100%",
    "@media (max-width: 480px)": {
        padding: "17px 26px",
  },
  },
  TextField3: {
    border: "none",
    fontSize: "14px",
    width: "100%",
    backgroundColor: "#F4F4F4 !important",
    position:'relative' as  const,
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#e0f7fa', 
    },
  },
  griditem16: {

  },
  griditem17: {

  },
  iconSize: {
    width: '24px',
    height: '24px',
    cursor: "pointer"
  },
  outerThreeDots: {
    top: '30px',
    right: '0px',
    position: "absolute" as const,
    background: "#ffffff",
    borderRadius: 16,
    zIndex: 99,
    border: " 1px solid #F4F4F4",
    boxShadow: "0px 2px 8px 0px #00000014"
  },
  outerSendThreeDots: {
    bottom: '40px',
    right: '-50%',
    position: "absolute" as const,
    background: "#ffffff",
    borderRadius: 16,
    zIndex: 99,
    border: " 1px solid #F4F4F4",
    width: 'max-content',
    boxShadow: "0px 2px 8px 0px #00000014"
  },
  sendInnerDots: {
    padding: '16px 20px'
  },
  InnerThreeDots: {
    padding: "15px 23px"
  },
  InnerThreeDotsPersonal: {
    padding: "4px"
  },
  sendButton: {
    background: "#C7D9F0",
    textTransform: 'none' as const,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: 700,
    borderRadius: '8px',
    padding: '9px 16px',
    color:'#17365D',
    lineHeight: "24px",
    cursor: "pointer"
  },
  sendButton1: {
    fontFamily: "Arial",
    fontSize: "14px",
    color: "#1F497D"
  },
  sendButton2: {
    fontFamily: "Arial",
    fontSize: "14px",
    color: "#9C9C9C"
  },
  deleteContentText: {
    color: "#17365D",
    fontFamily: "Arial",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",

  },
  deleteButton: {
    width: "120px",
    height: "56px",
    gap: "8px",
    borderRadius: "8px",
    opacity: "0px",
    background: "#DC2626",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: '16px',
    textTransform: 'none' as const,
    fontFamily: "Arial",

  },
  cancelButton: {
    width: "120px",
    height: "56px",
    gap: "8px",
    borderRadius: "8px",
    opacity: "0px",
    background: "#F4F4F4",
    color: "#1F497D",
    fontWeight: 700,
    fontSize: '16px',
    textTransform: 'none' as const,
    fontFamily: "Arial",
    marginRight:'34px'
  },
  searchPopupOuter: {
    position: "absolute" as const,
    background: "#ffffff",
    borderRadius: 16,
    zIndex: 99,
    boxShadow: "0px 2px 8px 0px #00000014",
    border: "1px solid #CBD5E1",
    width: '81%'
  },
  searchNewMessagePopupOuter: {
    position: "absolute" as const,
    background: "#ffffff",
    zIndex: 99,
    width: "calc(100% - 77px)"
  },
  searchInnerPopup: {
    padding: '8px 10px',
    borderRadius: '20px',
    borderBottom: "1px solid #F4F4F4",
    boxShadow: "0px 8px 32px 0px #0000000F , 0px 4px 8px 0px #00000008"

  },
  menuItem: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    color: " #1F497D",
    fontFamily: "Arial",
  },
  menuItemActive: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    color: " #1F497D",
    fontFamily: "Arial",
  },
  menuDeleteItem: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#17365D",
    padding:'9px 20px 9px 16px',
    fontFamily: "Arial"
  },
  menuDeleteItemActive: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "17365D",
    padding:'9px 20px 9px 16px',
    fontFamily: "Arial"
  },

  dialogCoversastion: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "left",
    color: "#9C9C9C",
    fontFamily: "Arial",
  },
  dialogCoversastionTop: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "left",
    color: "#9C9C9C",
    paddingTop: '20px',
    fontFamily: "Arial",
  },
  newMessageText: {
    fontWeight: 400,
    fontSize: "20px",
    color: "#1F497D",
    fontFamily: "Arial",
    lineHeight: "28px",
  },
  messageText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#64748B",
    fontFamily: "Arial",
    wordBreak: "break-word" as const
  },
  messageTextOne: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#64748B",
    fontFamily: "Arial",
    wordBreak: "break-word" as const,
    whiteSpace: "pre-wrap"
  },
  messageBox:{
    padding: "0px 40px"
  },
  uploadImage:{
    borderRadius: "8px",
    height: "120px", 
    width: "180px",
    display: "block",
    marginTop: "5px",
    marginBottom: "8px",
    cursor: "pointer"
  },
  messageBoxRoot: {
    top: "20px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red"
    }
  },
  img: {
    outline: "none"
  },
  uploadFileBox:{
    display: "flex",
    flexDirection: "row" as const, 
    backgroundColor: "#fff",
     borderRadius: "8px",
      boxShadow: "0px 4px 8px 0px #00000008",
      alignItems: "center",
      position:'absolute' as const
  },
  documentIcon:{
    color: "#9C9C9C",
backgroundColor:" #F4F4F4",
padding: "3px", //12
marginRight: "10px" //24
  },
  cancelIcon:{
    position:'absolute' as const,
    top:"4px",
    right:"20px"
  },
  modalImagebox:{
    position:'relative' as const,
    display: "flex",
justifyContent: "center"
  },
  messageBoxContainer:{
    position:'relative' as const
  },
  accordianroot:{
    width: "331px",
float: "right" as const,
position: 'fixed' as const,
  bottom: 16,
  right: 0,
  },
  accordianHeadImage:{
    width: "40px",
     height: "40px",
      marginRight: "10px",
      borderRadius: "60%",
      border: "2px solid #04CB00"
  },
  messageHeading:{
   flexGrow: 1,
   fontSize: "24px",
fontWeight: 400,
lineHeight: "32px",
color:"#1F497D",
fontFamily: "Arial"
  },
  messageHeading1:{
    flexGrow: 1,
    fontSize: "24px",
 fontWeight: 400,
 lineHeight: "32px",
 color:"#1F497D",
 marginLeft:'9px',
 fontFamily: "Arial"
  },
  accordianDetails:{
    padding: "16px 0px",
    borderTop: "1px solid #F4F4F4",
    maxHeight: "68vh"
  },
  messageTypo7: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#9C9C9C",
    lineHeight: "22px",
    overflow: "hidden",
textOverflow: "ellipsis",
whiteSpace: "nowrap",
width: "100%",
fontFamily: "Arial"
  },
  messagebox1:{
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    padding: "7px 0px 4px"
  },
  messagetypo5: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#17365D",
    lineHeight: "22px",
    fontFamily: "Arial"
  },
  messagegriditem7: {
    borderRadius: "50%",
    height: "39px",
    width: "39px"
  },
  messagetypo8: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#17365D",
    lineHeight: "18px",
    fontFamily: "Arial"
  },
  messageGriditem5: {
    height: "calc(78%)",
    overflowY: "auto"
  },
  msgExpandIcon:{
    color:"#1F497D"
  },
  msgSearchImage:{
    width:'39px',
    height:'39px',
    marginRight:'5px'
  },
  messagetypo1:{
    fontWeight: 400,
    fontSize: "16px",
    color: "#1F497D",
    lineHeight: "22px",
    fontFamily: "Arial"
  },
  searchMsgInnerPopup: {
    padding: '8px 10px',
    borderRadius: '20px',
    borderBottom: "1px solid #F4F4F4",

  },
  searchMsgPopupOuter: {
    position: "absolute" as const,
    background: "#ffffff",
    borderRadius: 16,
    zIndex: 99,
    boxShadow: "0px 2px 8px 0px #00000014",
    width: '85%'
  },
  msgHeadSearchImg:{
    width: "16.04px",
    height: "16.04px",
    marginRight: "4px"
  },
  msgadornment:{
    marginRight:'0px'
  },
  gridMsgcontainer2: {
    padding: "17px 29px 17px 26px",
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3"
  },
  newMsgMessagegriditem1:{
    height: "20vh"
  },
  msgSelectedUserImg: {
    borderRadius: "60%",
    height: "39px",
    width: "39px",
  },
  messageBoxexistUser:{
    paddingLeft:'38px',
    paddingRight:'10px',
    height:"200px",
    overflowY:"auto" as const
  },
  searchBox:{
    padding:'10px 40px'
  },
mr4:{
  marginRight:'4px'
},
uploadFileImage:{
  width:'40px', //56
  height:'40px', //56px
  borderRadius:"8px"
},
fileName:{
  fontFamily: "Arial",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  color:"#17365D",
  wordBreak:'break-word' as const
        },
        fileSize:{
          fontFamily: "Arial",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  color:"#9C9C9C"
        },
        dialogIconSize: {
          width: '24px',
          height: '24px',
          cursor: "pointer",
          paddingTop:'20px',
          paddingRight:'25px'
        },
        contentBox:{
          padding:'12px 40px 24px 40px'
        },
        contentBoxReport: {
          padding:'10px 40px 50px'
        },
        dialogActionStyles: {
          borderTop: "1px solid #F4F4F4",
          padding:'23px 40px 24px 40px'
        },
        newUserInfo:{
          margin:"4px 38px 0px 38px"
        },
        emptyBox:{
          height: "calc(100vh - 453px)",
          overflowY: "auto" as const
        },
        newUserImage:{
          width:'39px',
          height:'39px'
        },
        griditemNewUser: {
          borderBottom: "1px solid #F4F4F4",
          padding:"22px 10px 8px",
        },
        newUserRoot:{
          marginLeft:'40px',
          marginRight:'40px'
        },
        mr10:{
          marginRight:'10px'
        },
        existUserText:{
          marginTop:"0px",
          marginBottom:'0px'
        },
        existUserlistItem:{
          padding:'8px 10px'
        },
        existUserImgRoot:{
          minWidth: "39px",
          marginRight: "12px"
        },
        mrl50:{
   marginLeft:'50px'
        },
    
        disabledIconSize: {
          width: '24px',
          height: '24px',
          pointerEvents: "none",
          cursor: "not-allowed"
        },
        emptyStarDive: {
          width: '28px',
          height: '28px',
        },
        emptySelectUser: {
          height: "98px",
          width: "98px",
        },
        noMessageText: {
          fontFamily: "Arial",
          fontWeight: 700,
          fontSize: "24px",
          color: "#17365D"
        },
        subscribeText: {
          fontFamily: "Arial",
          fontWeight: 400,
          fontSize: "16px",
          color: "#9C9C9C"
        },
        subscribeButton: {
          backgroundColor: "#1F497D",
          color: "#FFFFFF",
          textTransform: "none" as "none",
          fontFamily: "Arial",
          fontWeight: 700,
          fontSize: "16px",
          padding: "8px 24px",
          borderRadius: "8px",
          marginTop: "36px"
        },
        messagetime:{
          fontWeight: 400,
          fontSize: "12px",
          color: "#17365D",
          lineHeight: "18px" ,
          fontFamily: "Arial"
        },
        radioFormControll:{
        marginLeft:'0px',
        marginRight:'0px'
        },
        radioText:{
          marginLeft:'8px'
        },
        fileType: {
          fontFamily: "Arial",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "22px",
          color: "#fff",
          wordBreak: "break-word" as const,
          backgroundColor: '#17365D',
          padding: "10px 5px",
          marginRight: "10px"
        },
        favouriteInfo:{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "5px"
        }
        
};

// Customizable Area End           
