import { Box, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { birthDateIcon, bottomRight, EditIcon, locationIcon, mailIcon, phoneIcon } from "../../src/assets";
import { UserDetails } from "../RecruiterProfileController.web";
import { getPhoneNumber } from "../../../../../packages/components/src/Utils.web";

interface PersonalInfoProps {
    userDetails: UserDetails;
    handleUpdateProfile: () => void;
    handleUpdateBio: () => void
}

export default class PersonalDetails extends Component<PersonalInfoProps> {
    constructor(props: PersonalInfoProps) {
        super(props);
    }
    render() {
      const fields = ["full_name", "photo", "city", "country", "full_phone_number", "bio", "personal_email_id", "work_email_id"]
      let count = 0;
      fields.forEach((item:string) => {
        if(this.props.userDetails[item as keyof UserDetails] !== "" && this.props.userDetails[item as keyof UserDetails] !== null) {
          count = count + 1;
        }
      })
        return (
            <Box style={{ ...webstyle.item2, position: 'relative' }} id="pageContainer">
            <Box style={webstyle.personalDetailsTop2}>
              <Typography >
                <span style={webstyle.blockHeading12}>
                  Personal Details
                </span>
                <span style={webstyle.personalDetailsPercent2}>
                  {' '}<span style={{ fontWeight: '400', marginLeft: '10px', color: 'grey' }}>{count}</span>/8
                </span>
              </Typography>
              <Typography data-test-id="editPersonalDetails" style={webstyle.editIconStyle2} onClick={this.props.handleUpdateProfile}>
                <img alt="Edit icon" src={EditIcon} style={{ ...webstyle.editIcon2, width: "15px", height: "15px" }} />
                Edit here
              </Typography>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }} className="personalIconsWrapper">
              <Typography style={{ ...webstyle.editIconStyle2, ...webstyle.personalDetailItem2 }}>
                <img src={phoneIcon} style={{ ...webstyle.editIcon2 }} data-test-id="phone-number-text"/>
                {getPhoneNumber(this.props.userDetails.full_phone_number)}
              </Typography>
              <Typography style={{ ...webstyle.editIconStyle2, ...webstyle.personalDetailItem2 }}>
                <img src={locationIcon} style={{ ...webstyle.editIcon2 }} />
                {this.props.userDetails.city ?? '-'}
              </Typography>
              <Typography style={{ ...webstyle.editIconStyle2, ...webstyle.personalDetailItem2 }}>
                <img src={mailIcon} style={{ ...webstyle.editIcon2 }} />
                work:  {this.props.userDetails.work_email_id ?? '-'}
              </Typography>
              <Typography style={{ ...webstyle.editIconStyle2, ...webstyle.personalDetailItem2 }}>
                <img src={mailIcon} style={{ ...webstyle.editIcon2 }} />
                personal:  {this.props.userDetails.personal_email_id ?? '-'}
              </Typography>
            </Box>
            <hr style={{ borderTop: '0', borderRight: '0', borderBottom: '1px solid #EEECE1', borderLeft: '0' }} />
            <Box style={webstyle.personalDetailsTop3}>
                <span style={webstyle.blockHeading12}>
                  Bio
                </span>
              <Typography data-test-id="editBio" style={webstyle.editIconStyle2} onClick={this.props.handleUpdateBio}>
                <img src={EditIcon} style={{ ...webstyle.editIcon2, width: "15px", height: "15px" }} />
                Edit here
              </Typography>
            </Box>
            <Typography style={{ ...webstyle.upcomingPlan2, color: '#9C9C9C', wordBreak: "break-word" }}>
              {this.props.userDetails.bio || 'You can add your upcoming plans for recruiters to see your upcoming skills and plans.'}
            </Typography>
            <img src={bottomRight} alt="icons" style={{
              position: "absolute",
              bottom: "0px",
              right: "0px"
            }} />
          </Box> 
        );
    }
}

const webstyle = {
    item2: {
        background: "#ffffff",
        borderRadius: '20px',
        padding: '20px',
        paddingBottom: "10px",
        minHeight: '160px'
    },
    personalDetailsTop2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'fit-content',
        marginBottom: '15px'
    },
    personalDetailsTop3: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 'fit-content',
      marginBottom:"10px"
  },
    blockHeading12: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#17365D'
    },
    personalDetailsPercent2: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '18px',
        paragraph: '8px',
        color: '#17365D'
    },
    editIconStyle2: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#1F497D',
        wordBreak: "break-word" as 'break-word' | undefined,
      },
    personalDetailItem2: {
        marginBottom: '15px',
        cursor: 'default'
    },
    editIcon2: {
        marginRight: '10px', width: '16px', height: "16px"
    },
    upcomingPlan2: {
      fontWeight: 400,
      fontFamily: 'Arial',
      lineHeight: '18px',
      // marginBottom: '8px',
        fontSize: '14px',
        paddingRight: "20px"
    },
}