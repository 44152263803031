import { Button, Fade, ListItemIcon, Menu, MenuItem, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { menuIcon, tickIcon } from '../assets';

interface MenuItemProps {
  label: string;
  onClick: (value: any) => void;
  hideItem?: boolean;
  showTickIcon?: boolean;
  disabled?: boolean
}

export interface Props {
  buttonLabel?: string;
  buttonIcon?: string;
  menuItems: MenuItemProps[];
  btnStyle?: React.CSSProperties;
  hideSelectedIcon?: boolean;
  menuWidth?: string;
  menuPadding?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '16px',
      border: '1px solid #CBD5E1',
      "& .MuiMenu-paper": {
        "box-shadow": "0px 4px 8px 0px #00000008 !important",
        "border-radius": "16px"

      },

      "& .MuiMenu-list": {
        width: (props: Props) => props.menuWidth || (props.buttonIcon ? '159px' : '192px'),
        borderRadius: '16px',
        border: '1px solid #CBD5E1',
        padding: (props: Props) => props.menuPadding || '4px',
        background: '#FFFFFF',
        height: "100%"
      },
      "& .MuiMenuItem-root": {
        color: '#17365D',
        fontSize: '14px',
        fontFamily: 'Arial',
        borderRadius: '16px',
        height: '36px',
        textTransform: 'capitalize',
        '&.Mui-selected': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          fontWeight: 700,
        },
      },
    },
    btn: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '14px',
      color: '#1F497D',
      fontFamily: "Arial"
    },
    iconStyle: {
      cursor: 'pointer',
      padding: '5px'
    }
  }
  ))

const CustomMenu: React.FC<Props> = ({ buttonLabel, menuItems, btnStyle, buttonIcon, hideSelectedIcon, menuWidth, menuPadding }) => {
  const classes = useStyles({
    buttonIcon,
    menuItems: [],
    menuWidth,
    menuPadding
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: MenuItemProps, index: number) => {
    setSelectedIndex(index);
    item.onClick(item.label)
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {buttonIcon ?
        <img
          src={buttonIcon}
          onClick={handleClick}
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className={classes.iconStyle}
        />
        : <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={classes.btn}
          style={btnStyle}
          endIcon={<img src={menuIcon} />}
        >
          {buttonLabel}
        </Button>
      }
      <Menu
        id="fade-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className={classes.root}
      >
        {menuItems.map((item, index) => {
          return (!item?.hideItem && <MenuItem
            disabled={item?.disabled}
            selected={item?.showTickIcon}
            key={item.label}
            onClick={() => handleMenuItemClick(item, index)}>
            {item.label}
            {(selectedIndex === index && !hideSelectedIcon) || (item?.showTickIcon) && (
              <ListItemIcon style={{ minWidth: "auto" }}>
                <img src={tickIcon} style={{ width: "16px", height: "12px" }} />
              </ListItemIcon>
            )} 
          </MenuItem>)
        }
        )}
      </Menu>
    </div>
  );
};

export default CustomMenu;
