Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getAPIMethod = "GET";
exports.DeleteAPIMethod = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfrecruitersdashboard";
exports.labelBodyText = "cfrecruitersdashboard Body";

exports.btnExampleTitle = "CLICK ME";

// Api endpoints
exports.profilesEndPoint = "bx_block_profile/profiles";
exports.candidateListEndPoint = "bx_block_profile/profiles/get_candidates_list";
exports.talentPipelineCandidateEndPoint = "bx_block_cfrecruitersdashboard/talent_pipelines";
exports.skillsListingApi = "/bx_block_content_management/content_managements/get_skills";
exports.inviteToApplyJobApi = "bx_block_cfrecruitersdashboard/talent_pipelines/invite_to_apply_job"
exports.getAllCreatedJobs = "/bx_block_joblisting/jobs/recruiter_job_listing"
exports.checkForSubscriptionAPI = "bx_block_subscriptions/subscriptions/user_subscription_details"
exports.userProfileAPI = 'bx_block_profile/profiles/'
// Slider values
exports.maxSliderValue = 100
exports.minSliderValue = 1

// Customizable Area End