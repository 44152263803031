import React from 'react';
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { emptyLocation, locGrey } from '../assets';
import { Job } from '../CatalogueController1';
import { TruncateWithTooltip } from '../../../../../packages/components/src/Utils.web';
import BootstrapTooltip from './BootstrapTooltip';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "margin": "0px",
            "width": "100%",
            "borderRadius": "10px",
            "borderTopLeftRadius": "0px",
            "borderTopRightRadius": "0px",
            "background": "#FFFFFF",
            "position": "relative",
            "height": "56px",
            "display": "flex",
            "alignItems": "flex-end",
            "marginBottom": "20px",
            "minWidth": "258px",
            "cursor": "pointer"
        },
        rootDeactivate: {
            "margin": "0px",
            "width": "100%",
            "borderRadius": "10px",
            "borderTopLeftRadius": "0px",
            "borderTopRightRadius": "0px",
            "background": "#f4f4f4",
            "position": "relative",
            "height": "56px",
            "display": "flex",
            "alignItems": "flex-end",
            "marginBottom": "20px",
            "minWidth": "258px",
            "border": "1px solid #D9D9D9",
            "cursor": "pointer"
        },
        leftTag: {
            "borderRadius": "0px 18.182px 18.182px 0px",
            "padding": "0px 17px",
            "position": "absolute",
            "top": "0px",
            "left": "0px",
        },
        notSelectedTag: {
            'background': "#F3F4F9",
            'color': "#1F497D"
        },
        disabledTag: {
            'background': "#D9D9D9",
            'color': "#9C9C9C"
        },
        selectedTag: {
            'background': "#6D97C1",
            'color': "#FFFFFF"
        },
        rightTag: {
            "borderRadius": "18.18px 0px 0px 18.18px",
            "padding": "0px 17px",
            "position": "absolute",
            "top": "0px",
            "right": "0px"
        },
        textOne: {
            "color": "#17365D",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "22px",
            "paddingBottom": "8px",
            "paddingLeft": "16px",
            "display": "flex",
            "alignItems": "center"
        },
        txtLeftTag: {
            "textAlign": "right",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
        },
        txtRightTag: {
            "textAlign": "right",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px"
        },
        textOneDisabled: {
            "color": "#9C9C9C",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "22px",
            "paddingBottom": "8px",
            "paddingLeft": "16px",
            "display": "flex",
            "alignItems": "center"
        }
    })
);

const stylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      "color": "white",
      "fontFamily": "Arial",
      "fontSize": "8px",
      "fontStyle": "normal",
      "fontWeight": "lighter",
      "border": "1px solid #F4F4F4"
    },
    tooltip: {
      backgroundColor: "white",
      "border": "1px solid #F4F4F4",
      "color": "#17365D",
    },
    popper: {
        top: "-20px !important",
        left: "-5px !important"
    }
  }));
  

type Props = {
    jobFeedList: Job,
    jobDeatilsId: string | number
}

const JobFeedComponent = (Props: Props) => {
    const { jobFeedList, jobDeatilsId } = Props;
    const classes = useStyles();
    const tooltipClasses = stylesBootstrap();
    const isJobClosed = jobFeedList.attributes.status === "close";
    const isJobSelected = jobDeatilsId === jobFeedList.id;


    const getBorderStyle = (isJobSelected: boolean, isJobClosed: boolean) => {
        if (isJobSelected) {
            return "1px solid #1F497D";
        } else if (isJobClosed) {
            return '1px solid #D9D9D9';
        } else {
            return "1px solid #F4F4F4";
        }
    };

    const getTextOneClass = (isJobClosed: boolean) => {
        return isJobClosed ? classes.textOneDisabled : classes.textOne;
    };

    const getTagClass = (isJobSelected: boolean, isJobClosed: boolean) => {
        if (isJobClosed) {
            return classes.disabledTag;
        } else if (isJobSelected) {
            return classes.selectedTag;
        } else {
            return classes.notSelectedTag;
        }
    };


    const borderStyle = getBorderStyle(isJobSelected, isJobClosed);
    const textOneClass = getTextOneClass(isJobClosed);
    const tagClass = getTagClass(isJobSelected, isJobClosed);


    return (
        <Grid container className={isJobClosed ? classes.rootDeactivate : classes.root} style={{ border: borderStyle }}>
            <Tooltip classes={tooltipClasses} title={jobFeedList.attributes.company_name.length > 15 ? jobFeedList.attributes.company_name : ""}>
                <Grid item className={textOneClass} sm={6}>{TruncateWithTooltip(jobFeedList.attributes.company_name, 15)}</Grid>
            </Tooltip>
            <Tooltip title={jobFeedList.attributes.city} classes={tooltipClasses}>
                <Grid item className={textOneClass} sm={6}><img src={isJobClosed ? locGrey : emptyLocation} alt="location" />&nbsp;{jobFeedList.attributes.city.length >=14 ? jobFeedList.attributes.city.substring(0, 14) + '...' :jobFeedList.attributes.city}</Grid>
            </Tooltip>
            <div className={`${classes.leftTag} ${tagClass}`}>
                <BootstrapTooltip title={jobFeedList.attributes.job_title}>
                <Typography className={classes.txtLeftTag}>
                    {TruncateWithTooltip(jobFeedList.attributes.job_title, 25)}
                </Typography>
                </BootstrapTooltip>
            </div>
            <div className={`${classes.rightTag} ${tagClass}`}>
                <Typography className={classes.txtRightTag}>
                    {jobFeedList.attributes.created_at}
                </Typography>
            </div>
        </Grid>
    );

}

export default JobFeedComponent