import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTimer } from "react-timer-hook";

interface MyTimerProps {
  expiryTimestamp: Date;
  handleShowVerifyEmail: any;
  handleOTPTimer: any;
}

interface MyTimerHandlarProps {
  handleShowVerifyEmail: any;
  handleOTPTimer: any;
}

function MyTimer({
  expiryTimestamp,
  handleShowVerifyEmail,
  handleOTPTimer,
}: MyTimerProps) {
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => handleOTPTimer(false),
  });
  const isTimeUp = seconds === 0 && minutes === 0;
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography
          style={{
            color: "#1F497D",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {minutes}:{seconds} left
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            color: "#1F497D",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
          }}
        >
          Didn’t receive OTP?{" "}
          <span
            onClick={() => {
              if (isTimeUp) {
                handleShowVerifyEmail();
                handleOTPTimer(true);
                const time = new Date();
                time.setSeconds(time.getSeconds() + 180);
                restart(time);
              }
            }}
            data-test-id={"btnTimerOTP"}
            style={{
              color: "#1F497D",
              fontSize: "0.9rem",
              fontStyle: "normal",
              fontWeight: 600,
              cursor: !isTimeUp ? "" : "pointer",
              opacity: !isTimeUp ? 0.5 : 1,
            }}
          >
            Resend
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function OtpTimer({
  handleShowVerifyEmail,
  handleOTPTimer,
}: MyTimerHandlarProps) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);
  return (
    <MyTimer
      expiryTimestamp={time}
      handleShowVerifyEmail={handleShowVerifyEmail}
      handleOTPTimer={handleOTPTimer}
    />
  );
}
